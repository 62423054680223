import { useDenyPolicyRequestMutationKey } from "@src/api/issuance/useDenyPolicyRequest";
import { useMemo } from "react";
import { useMutationState } from "@tanstack/react-query";
import type { MutationState } from "@tanstack/react-query";
import type { PolicyRequest } from "@src/api/issuance";

type UseDenyPolicyRequestMutationState = (
  policyRequestId: PolicyRequest["id"]
) => MutationState<PolicyRequest> | undefined;

export const useDenyPolicyRequestMutationState: UseDenyPolicyRequestMutationState =
  policyRequestId => {
    const mutationKey = useDenyPolicyRequestMutationKey({ policyRequestId });

    const denyPolicyRequestMutations = useMutationState<MutationState<PolicyRequest>>({
      filters: {
        exact: true,
        mutationKey,
        status: "success",
      },
    });

    const denyPolicyRequestMutation = useMemo(
      () => denyPolicyRequestMutations.at(-1),
      [denyPolicyRequestMutations]
    );

    return denyPolicyRequestMutation;
  };
