import { StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

const CIRCLE_BORDER_WIDTH = 3;
const CIRCLE_DIAMETER = 14;

const PendingStateIndicatorDesktop = () => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();

  const absolutePosition = CIRCLE_BORDER_WIDTH * 2 * -1;

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly indicator: ViewStyle;
  }>({
    container: {
      position: "absolute",
      right: absolutePosition,
      top: absolutePosition,
    },
    indicator: {
      backgroundColor: theme.colors.brand.oceanBlue,
      borderColor: theme.colors.background.white,
      borderRadius: CIRCLE_DIAMETER / 2,
      borderStyle: "solid",
      borderWidth: 3,
      height: CIRCLE_DIAMETER,
      width: CIRCLE_DIAMETER,
    },
  });

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementName: "PendingStateIndicator",
      })}
    >
      <View style={styles.indicator} />
    </View>
  );
};

export { PendingStateIndicatorDesktop };
