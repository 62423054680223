import { msg } from "@lingui/macro";
import { useAlerts, useConfig, useLogout, useMeritAuth0 } from "@src/hooks";
import { useLingui } from "@lingui/react";
import UserInactivity from "react-native-user-inactivity";
import type { FCWithChildren } from "@src/types/component";

const AutoLogout: FCWithChildren = ({ children }) => {
  const config = useConfig();
  const logout = useLogout();
  const { isAuthenticated } = useMeritAuth0();
  const { sendAlert } = useAlerts();
  const { _ } = useLingui();

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <UserInactivity
      onAction={(isActive: boolean) => {
        if (!isActive) {
          logout();
          sendAlert({
            id: "AutoLogout-inactivity",
            text1: _(msg`Logged Out`),
            text2: _(msg`You have been logged out because of inactivity.`),
            type: "info",
          });
        }
      }}
      timeForInactivity={config.autoLogout.inactivity.timeout}
    >
      <>{children}</>
    </UserInactivity>
  );
};

export { AutoLogout };
