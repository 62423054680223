import { getIssuedOn } from "./getIssuedOn";
import { getOrgName } from "./getOrgName";
import { getRecipientFullName } from "./getRecipientFullName";
import type { AccountMerit, Merit, TransformedFields } from "../types";
import type { AdminMerit } from "../types/AdminMerit";
import type { NormalMerit } from "../types/NormalMerit";

type PartialMerit =
  | PickPartial<AccountMerit, "transformedFields">
  | PickPartial<AdminMerit, "transformedFields">
  | PickPartial<NormalMerit, "transformedFields">;

export const withTransformedFields = (merit: PartialMerit): Merit => {
  const transformedFields: TransformedFields = {
    issuedOn: getIssuedOn(merit),
    orgName: getOrgName(merit),
    recipientFullName: getRecipientFullName(merit),
  };

  return {
    ...merit,
    transformedFields,
  };
};
