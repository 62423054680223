import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Heading, useTheme } from "@merit/frontend-components";
import { Trans } from "@lingui/macro";

const LoadingScreen = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    heading: {
      marginBottom: theme.spacing.xxl,
    },
  });

  return (
    <View style={styles.container}>
      <Heading level="1" style={styles.heading}>
        <Trans>Loading…</Trans>
      </Heading>
      <ActivityIndicator />
    </View>
  );
};

export { LoadingScreen };
