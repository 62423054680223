import { NotificationSkeleton as DesktopNotificationSkeleton } from "./NotificationSkeleton.desktop";
import { NotificationSkeleton as MobileNotificationSkeleton } from "./NotificationSkeleton.mobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";

export const NotificationSkeleton: FC = () => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopNotificationSkeleton />;
  }

  return <MobileNotificationSkeleton />;
};
