import { StyleSheet, View } from "react-native";
import { useSkeleton } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";
import Animated from "react-native-reanimated";
import type { VFC } from "react";

const ICON_SIZE = 24;

export const NotificationSkeleton: VFC = () => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    actionContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    actionText: {
      flex: 1,
      marginRight: theme.spacing.m,
    },
    container: {
      flex: 1,
      justifyContent: "space-between",
      padding: theme.spacing.l,
    },
    counterSkeleton: {
      alignSelf: "flex-end",
      borderRadius: ICON_SIZE / 2,
      height: ICON_SIZE,
      width: ICON_SIZE,
    },
    flexRow: {
      alignItems: "center",
      flexDirection: "row",
    },
    fullFlex: {
      flex: 1,
    },
    iconSkeleton: {
      height: ICON_SIZE,
      width: ICON_SIZE,
    },
    marginRight: {
      marginRight: theme.spacing.m,
    },
    textSkeleton: {
      borderRadius: theme.fontSizes.s.lineHeight / 2,
      height: theme.fontSizes.s.lineHeight,
      width: "60%",
    },
  });

  const skeletonStyles = useSkeleton();

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.textSkeleton, skeletonStyles]} />
      <Animated.View style={[styles.textSkeleton, skeletonStyles]} />
      <View style={styles.actionContainer}>
        <View style={[styles.flexRow, styles.fullFlex]}>
          <Animated.View style={[styles.iconSkeleton, styles.marginRight, skeletonStyles]} />
          <Animated.View style={[styles.textSkeleton, styles.actionText, skeletonStyles]} />
        </View>
        <View style={styles.fullFlex}>
          <Animated.View style={[styles.counterSkeleton, skeletonStyles]} />
        </View>
      </View>
    </View>
  );
};
