/* eslint-disable functional/immutable-data */

import { DdSdkReactNative, DdSdkReactNativeConfiguration } from "expo-datadog";

type InitDatadogOptions = {
  readonly applicationId: string;
  readonly clientToken: string;
  readonly environment: string;
  readonly serviceName: string;
  readonly firstPartyHosts?: readonly string[];
  readonly resourceTracingSamplingRate?: number;
};

type InitDatadog = (options: InitDatadogOptions) => Promise<void>;

export const initDatadog: InitDatadog = async options => {
  const config = new DdSdkReactNativeConfiguration(
    options.clientToken,
    options.environment,
    options.applicationId,
    true, // track user interactions such as tapping on a button. You can use the 'accessibilityLabel' element property to give the tap action a name, otherwise the element type is reported.
    true, // track XHR resources.
    true // track errors.
  );

  config.serviceName = options.serviceName;
  config.nativeCrashReportEnabled = true;

  if (options.firstPartyHosts !== undefined) {
    config.firstPartyHosts = [...options.firstPartyHosts]; // convert to mutable array
  }

  if (options.resourceTracingSamplingRate !== undefined) {
    config.resourceTracingSamplingRate = options.resourceTracingSamplingRate;
  }

  await DdSdkReactNative.initialize(config);
};
