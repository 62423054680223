import { BULLET_POINT } from "@src/utils/constants/unicode";
import { Body, useTheme } from "@merit/frontend-components";
import { Plural, Trans } from "@lingui/macro";
import { StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import { useMemo } from "react";
import { useMerits } from "@src/api/issuance";
import type { FC } from "react";
import type { Merit } from "@src/api/issuance";

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "TemplateFieldErrorsListItem";

type TemplateFieldErrorsListItemProps = {
  readonly templateId: NonNullable<Merit["templateId"]>;
  readonly templateFieldIds: readonly NonNullable<
    ArrayElement<Merit["fields"]>["templateFieldID"]
  >[];
};

export const TemplateFieldErrorsListItem: FC<TemplateFieldErrorsListItemProps> = ({
  templateFieldIds,
  templateId,
}) => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const { data: merits } = useMerits();

  const merit = useMemo(() => merits?.find(m => m.templateId === templateId), [merits, templateId]);
  const meritName = merit?.name;

  const styles = StyleSheet.create({
    bold: {
      fontWeight: theme.fontWeights.semiBold,
    },
    fieldContainer: {
      paddingLeft: theme.spacing.m,
    },
  });

  return (
    <View>
      <Body {...getTestProps({ elementId: meritName, elementName }, { componentName: "reason" })}>
        <Plural
          one={
            <Trans>
              {BULLET_POINT} A field on your <Body style={styles.bold}>{meritName}</Body> merit
              failed verification
            </Trans>
          }
          other={
            <Trans>
              {BULLET_POINT} Some fields on your <Body style={styles.bold}>{meritName}</Body> merit
              failed verification
            </Trans>
          }
          value={templateFieldIds.length}
        />
      </Body>
      <View style={styles.fieldContainer}>
        {templateFieldIds.map(templateFieldId => {
          const field = merit?.fields?.find(f => f.templateFieldID === templateFieldId);
          const fieldName = field?.name;

          return (
            <Body key={templateFieldId}>
              <Trans context="validation">
                {BULLET_POINT} <Body style={styles.bold}>{fieldName}</Body> field failed
              </Trans>
            </Body>
          );
        })}
      </View>
    </View>
  );
};
