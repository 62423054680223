import { StyleSheet, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

export const Spacer = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.text.default,
      height: theme.fontSizes.s.fontSize,
      marginHorizontal: theme.spacing.l,
      width: 1,
    },
  });

  return <View style={styles.container} />;
};
