import { ContainerFieldsList } from "../ContainerFieldsList";
import { useMemo } from "react";
import type { Folio } from "@src/api/issuance";

type Props = {
  readonly folio: Folio;
};

export const FolioTabDetails = ({ folio }: Props) => {
  const fields = useMemo(
    () => folio.fields?.filter(field => field.fieldKind?.fieldType !== "Blob") ?? [],
    [folio]
  );

  return <ContainerFieldsList fields={fields} />;
};
