import { MeritListItem as DesktopMeritListItem } from "./MeritListItem.desktop";
import { MeritListItem as MobileMeritListItem } from "./MeritListItem.mobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";
import type { MeritListItemProps } from "./types";

export const MeritListItem: FC<MeritListItemProps> = props => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopMeritListItem {...props} />;
  }

  return <MobileMeritListItem {...props} />;
};
