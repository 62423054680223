import { useAccountMerit } from "@src/api/issuance";

const FIRST_NAME_KEY = "First Name";
const LAST_NAME_KEY = "Last Name";
const CHOSEN_NAME_KEY = "Chosen Name";
const ALTERNATE_EMAILS_KEY = "Alternate Emails";
const useSettings = () => {
  const { data: accountMerit, status } = useAccountMerit();

  if (status !== "success") {
    return undefined;
  }

  const legalName = `${accountMerit.fieldMap[FIRST_NAME_KEY]} ${accountMerit.fieldMap[LAST_NAME_KEY]}`;
  const displayName =
    accountMerit.fieldMap[CHOSEN_NAME_KEY] ?? accountMerit.fieldMap[FIRST_NAME_KEY];

  const { alternates = [], primary = accountMerit.fieldMap.Email } =
    accountMerit.fieldMap[ALTERNATE_EMAILS_KEY] ?? {};

  const additionalEmails = alternates.map(email => ({
    email,
    isPrimary: false,
  }));

  const emails = [{ email: primary, isPrimary: true }, ...additionalEmails];

  return { displayName, emails, legalName };
};

export { useSettings };
