import { Body } from "@merit/frontend-components";
import { StyleSheet } from "react-native";
import type { BodyProps } from "@merit/frontend-components";
import type { TextStyle } from "react-native";

const styles = StyleSheet.create<{
  readonly wrapper: TextStyle;
}>({
  wrapper: {
    flex: 1,
  },
});

const TruncatedBody = ({ children, ...rest }: BodyProps) => (
  <Body ellipsizeMode="tail" numberOfLines={1} style={styles.wrapper} {...rest}>
    {children}
  </Body>
);

export { TruncatedBody };
