import "core-js/actual/array/to-reversed";
import { Card, NotificationSkeleton, Stack } from "@src/components";
import { MAX_NOTIFICATIONS_BEFORE_HIDING } from "./constants";
import { NotificationEmpty } from "../NotificationEmpty";
import { NotificationMerit } from "../NotificationMerit";
import { NotificationPolicyRequest } from "../NotificationPolicyRequest";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import { useGetTestProps } from "@src/hooks";
import { useNotificationsController } from "./useNotificationsController";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

const ELEVATION = 5;
const CARD_HEIGHT = 160;

export const Notifications = () => {
  const { width } = useWindowDimensions();
  const { dismissNotification, isFetching, notifications } = useNotificationsController();

  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly backgroundCard1: ViewStyle;
    readonly backgroundCard2: ViewStyle;
    readonly backgroundCardCommon: ViewStyle;
    readonly card: ViewStyle;
    readonly marginBottom: ViewStyle;
  }>({
    backgroundCard1: {
      opacity: 0.6,
      transform: [
        // without this, the "peek" of bg cards on wider screens start to go out of bounds
        // rather than derive the appropriate formula, just opted to do an approximation FYI
        { rotateZ: `${Math.atan(CARD_HEIGHT / width / 8)}rad` },
        { translateX: theme.spacing.xs },
      ],
      ...theme.elevations[`depth${ELEVATION}` as const],
    },
    backgroundCard2: {
      opacity: 0.8,
      transform: [
        { rotateZ: `-${Math.atan(CARD_HEIGHT / width / 10)}rad` },
        { translateX: -theme.spacing.xs },
      ],
      ...theme.elevations[`depth${ELEVATION}` as const],
    },
    backgroundCardCommon: {
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    card: {
      height: CARD_HEIGHT,
    },
    marginBottom: {
      marginBottom: theme.spacing.m,
    },
  });

  const getTestProps = useGetTestProps();

  if (notifications.length > MAX_NOTIFICATIONS_BEFORE_HIDING) {
    return null;
  }

  if (isFetching && notifications.length === 0) {
    return (
      <View
        style={styles.marginBottom}
        {...getTestProps({
          elementName: "Notifications",
        })}
      >
        <Card style={[styles.backgroundCardCommon, styles.backgroundCard1]} />
        <Card style={[styles.backgroundCardCommon, styles.backgroundCard2]} />
        <Card elevation={ELEVATION} style={styles.card}>
          <NotificationSkeleton />
        </Card>
      </View>
    );
  }

  return (
    <View
      style={styles.marginBottom}
      {...getTestProps({
        elementName: "Notifications",
      })}
    >
      <Card style={[styles.backgroundCardCommon, styles.backgroundCard1]} />
      <Card style={[styles.backgroundCardCommon, styles.backgroundCard2]} />
      <Stack
        elementId="pendingMeritNotifications"
        items={notifications.toReversed()}
        keyExtractor={notification => notification.id}
        onDismiss={notification => {
          dismissNotification(notification.id);
        }}
        renderEmpty={() => (
          <Card elevation={ELEVATION} style={styles.card}>
            <NotificationEmpty />
          </Card>
        )}
        renderItem={(notification, index) => {
          if (notification.kind === "MeritNotification") {
            return (
              <Card style={styles.card}>
                <NotificationMerit counterValue={index + 1} merit={notification.data} />
              </Card>
            );
          }

          return (
            <Card style={styles.card}>
              <NotificationPolicyRequest
                counterValue={index + 1}
                policyRequest={notification.data}
              />
            </Card>
          );
        }}
      />
    </View>
  );
};
