/* eslint-disable lingui/no-unlocalized-strings */

import KansasKeepLogo from "@src/assets/images/kansas-keep-logo.png";
import OhioAceLogo from "@src/assets/images/ohio-ace-logo.png";

export const APPS = {
  kansasKeep: {
    orgName: "Kansas Education Enrichment Program",
    parentGuardian: {
      id: "kansasKeepParentGuardian",
      logo: KansasKeepLogo,
      meritName: "Parent/Guardian Approval",
      name: "KEEP",
      url: "https://app.keep.ks.gov",
    },
    serviceProvider: {
      id: "kansasKeepServiceProvider",
      logo: KansasKeepLogo,
      logoText: "SP",
      meritName: "QESP Access",
      name: "KEEP SP",
      url: "https://app.keep.ks.gov/service-provider",
    },
  },
  ohioAce: {
    orgName: "Ohio Department of Education",
    parentGuardian: {
      id: "ohioAceParentGuardian",
      logo: OhioAceLogo,
      meritName: "Parent/Guardian Approval",
      name: "ACE",
      url: "https://app.aceohio.org",
    },
    serviceProvider: {
      id: "ohioAceServiceProvider",
      logo: OhioAceLogo,
      logoText: "SP",
      meritName: "QESP Access",
      name: "ACE SP",
      url: "https://app.aceohio.org/service-provider",
    },
  },
};
