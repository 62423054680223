import type { Configuration } from "@src/configuration";
import type { Container } from "@merit/issuance-client";
import type { ContainerKind } from "../types";

export const getContainerKind = (container: Container, config: Configuration): ContainerKind => {
  if (container.templateId === config.remote?.accountMeritTemplateID) {
    return "accountMerit";
  }

  if (container.templateId === config.remote?.adminMeritTemplateID) {
    return "adminMerit";
  }

  return "normalMerit";
};
