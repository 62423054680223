import { unloadAbortController } from "@src/utils";
import { useAppApi } from "./useAppApi";
import { useEffect } from "react";
import { useLogoutOnError } from "@src/hooks";
import { useQuery } from "@tanstack/react-query";

type QueryKey = readonly ["appConfiguration"];

export const useAppConfigurationQueryKey = (): QueryKey => ["appConfiguration"];

export const useAppConfiguration = () => {
  const { api, isReady } = useAppApi();
  const queryKey = useAppConfigurationQueryKey();
  const { onError } = useLogoutOnError();

  const query = useQuery({
    enabled: isReady,
    queryFn: () => api.getConfiguration({ signal: unloadAbortController.signal }),
    queryKey,
  });

  useEffect(() => {
    if (query.isError) {
      onError(query.error);
    }
  }, [query.isError, query.error, onError]);

  return query;
};
