import { Body, Heading, useTheme } from "@merit/frontend-components";
import { LanguageSelect } from "@src/components";
import { StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import type { TextStyle, ViewStyle } from "react-native";

export const Preferences = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly languageSelectContainer: ViewStyle;
    readonly preferencesHeading: TextStyle;
    readonly row: ViewStyle;
  }>({
    languageSelectContainer: {
      flex: 1,
      maxWidth: 200, // arbitrary
    },
    preferencesHeading: {
      ...theme.fontSizes.l,
    },
    row: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });

  const getTestProps = useGetTestProps();

  return (
    <View
      {...getTestProps({
        elementName: "Preferences",
      })}
    >
      <Heading level="1" style={styles.preferencesHeading}>
        <Trans>Preferences</Trans>
      </Heading>
      <View style={styles.row}>
        <Body>
          <Trans>Language</Trans>
        </Body>
        <LanguageSelect style={styles.languageSelectContainer} />
      </View>
    </View>
  );
};
