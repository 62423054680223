import { Body, Link, useTheme } from "@merit/frontend-components";
import { Dialog } from "@src/components/Dialog";
import { StyleSheet } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { useDeleteMFAEnrollments } from "@src/api/person-experience-backend";
import { useLingui } from "@lingui/react";
import { useRoute } from "@src/hooks";
import { useState } from "react";
import type { TextStyle } from "react-native";

type MFAResetProps = {
  readonly onSuccessMFAReset?: () => void;
};

export const MFAReset = ({ onSuccessMFAReset }: MFAResetProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly bodyLine1: TextStyle;
  }>({
    bodyLine1: {
      marginBottom: theme.spacing.s,
    },
  });

  const route = useRoute();
  const deleteEnrollments = useDeleteMFAEnrollments();
  const { _ } = useLingui();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  return (
    <>
      <Body
        style={styles.bodyLine1}
        testProps={{
          elementName: "MFAResetBodyLine1",
          screenName: route.name,
        }}
      >
        <Trans>Your account is enrolled in multi-factor authentication.</Trans>
      </Body>
      <Body
        testProps={{
          elementName: "MFAResetBodyLine2",
          screenName: route.name,
        }}
      >
        <Trans>
          Unenroll from multi-factor authentication. This will remove MFA from your account.
        </Trans>{" "}
        <Link
          onPress={() => {
            if (deleteEnrollments.isPending) {
              return;
            }
            setIsConfirmationVisible(true);
          }}
          testProps={{
            elementName: "MFAResetButton",
            screenName: route.name,
          }}
        >
          {deleteEnrollments.isPending ? (
            <Trans>Loading…</Trans>
          ) : (
            <Trans comment="MFA = multi-factor authentication">Remove MFA</Trans>
          )}
        </Link>
      </Body>
      {isConfirmationVisible && (
        <Dialog
          actionButtonProps={{
            onPress: async () => {
              try {
                await deleteEnrollments.mutateAsync();
                onSuccessMFAReset?.();
              } finally {
                // error toast handled by the mutateAsync, just close the modal
                setIsConfirmationVisible(false);
              }
            },
            text: _(msg({ context: "action", message: "Unenroll" })),
            type: "destructive",
          }}
          cancelButtonProps={{
            text: _(msg({ context: "dismiss dialog", message: "Cancel" })),
          }}
          content={
            <Body>
              <Trans>Are you sure you want to unenroll from Multi-factor Authentication?</Trans>
            </Body>
          }
          onClose={() => {
            setIsConfirmationVisible(false);
          }}
          title={_(msg`Unenroll from Multi-factor Authentication`)}
        />
      )}
    </>
  );
};
