import { useWindowDimensions } from "react-native";

export type LayoutType = "desktop" | "mobile";

type UseLayoutType = (mobileBreakpoint?: number) => LayoutType;

export const useLayoutType: UseLayoutType = (mobileBreakpoint = 1025) => {
  const { width } = useWindowDimensions();

  return width > mobileBreakpoint ? "desktop" : "mobile";
};
