import { Configuration as ConfigurationCls, ContainersApi } from "@merit/issuance-client";
import { getLocalConfig } from "@src/utils";
import { useMeritAuth0 } from "@src/hooks";

const config = getLocalConfig();

type UseContainersAPIReturn = {
  readonly api: ContainersApi;
  readonly isReady: boolean;
};

const useContainersApi = (): UseContainersAPIReturn => {
  const { accessToken } = useMeritAuth0();

  return {
    api: new ContainersApi(
      new ConfigurationCls({
        ...config.api.issuance,
        headers: {
          ...config.api.issuance.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ),
    isReady: accessToken !== "",
  };
};

export { useContainersApi };
