import { EmailList } from "../EmailList";
import { EntityMerge } from "../EntityMerge";
import { Header } from "@src/components";
import { Heading, useTheme } from "@merit/frontend-components";
import { MultiFactorAuthentication } from "../MultiFactorAuthentication";
import { Preferences } from "../Preferences";
import { ProfileImage } from "../ProfileImage";
import { StyleSheet, View } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { useFeatureFlags, useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useSettings } from "./hooks";
import type { SettingsProps } from "./types";
import type { TextStyle, ViewStyle } from "react-native";

const SettingsDesktop = ({}: SettingsProps) => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const useSettingsValues = useSettings();
  const { _ } = useLingui();
  const featureFlags = useFeatureFlags();

  if (useSettingsValues === undefined) {
    return null;
  }

  const { displayName, emails, legalName } = useSettingsValues;

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly headerContainer: ViewStyle;
    readonly titleContainerStyle: ViewStyle;
    readonly sectionSpacer: ViewStyle;
    readonly profileImageHeading: TextStyle;
  }>({
    container: {
      flex: 1,
      maxWidth: 800, // TODO: we don't actually want to do this
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.xl,
    },
    headerContainer: {
      backgroundColor: theme.colors.background.white,
    },
    profileImageHeading: {
      ...theme.fontSizes.s,
      marginBottom: theme.spacing.s,
    },
    sectionSpacer: {
      marginBottom: theme.spacing.xxl,
    },
    titleContainerStyle: {
      alignItems: "flex-start",
    },
  });

  return (
    <View
      {...getTestProps({
        elementName: "Settings",
      })}
    >
      <Header
        containerStyle={styles.headerContainer}
        title={_(msg({ context: "title", message: "Settings" }))}
        titleContainerStyle={styles.titleContainerStyle}
      />
      <View style={styles.container}>
        <Heading level="1" style={styles.profileImageHeading}>
          <Trans context="title">Profile image</Trans>
        </Heading>
        <ProfileImage displayName={displayName} legalName={legalName} />
        <EmailList emails={emails} />
        <View style={styles.sectionSpacer} />
        <EntityMerge />
        {featureFlags.showMemberAppSettingsMFA === true && (
          <>
            <View style={styles.sectionSpacer} />
            <MultiFactorAuthentication />
          </>
        )}
        {
          // TODO: if more prefs are added, this feature flag needs to move
          featureFlags.memberAppShowLanguageSelection === true && (
            <>
              <View style={styles.sectionSpacer} />
              <Preferences />
            </>
          )
        }
      </View>
    </View>
  );
};

export { SettingsDesktop };
