import { Body, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { useMemo } from "react";
import { useMerit } from "@src/api/issuance";
import type { Container } from "@merit/issuance-client";
import type { FC } from "react";
import type { ViewStyle } from "react-native";

const MAX_LENGTH = 300;

type MeritTabDescriptionProps = {
  readonly meritId: Container["id"];
};

export const MeritTabDescription: FC<MeritTabDescriptionProps> = ({ meritId }) => {
  const merit = useMerit(meritId);
  const { theme } = useTheme();

  const description = useMemo(() => {
    if (merit?.description === undefined) {
      return "";
    }

    if (merit.description.length <= MAX_LENGTH) {
      return merit.description;
    }

    return `${merit.description.slice(0, MAX_LENGTH)}...`;
  }, [merit?.description]);

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.m,
    },
  });

  return (
    <View style={styles.container}>
      <Body>{description}</Body>
    </View>
  );
};
