import { Dismissable, Swipeable } from "@src/components";
import { Platform, StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import type { CommonProps } from "../types";
import type { PropsWithChildren } from "react";
import type { ViewStyle } from "react-native";

type BaseProps<T> = {
  readonly keyExtractor: (item: T, index: number) => string;
  readonly onDismiss: (item: T, index: number) => void;
  readonly renderEmpty: () => JSX.Element;
  readonly renderItem: (item: T, index: number) => JSX.Element;
};

type UndefinedProps<T> = BaseProps<T> & {
  readonly items: undefined;
  readonly renderUndefined: () => JSX.Element;
};

type DefinedProps<T> = BaseProps<T> & {
  readonly items: readonly T[];
  readonly renderUndefined?: () => JSX.Element;
};

type CombinedProps<T> = DefinedProps<T> | UndefinedProps<T>;

export type StackProps<T> = CombinedProps<T> & CommonProps;

export const Stack = <T,>({
  elementId,
  items,
  keyExtractor,
  onDismiss,
  renderEmpty,
  renderItem,
  renderUndefined,
}: PropsWithChildren<StackProps<T>>) => {
  const getTestProps = useGetTestProps();

  const styles = StyleSheet.create<{
    readonly item: ViewStyle;
  }>({
    item: {
      bottom: 0,
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
  });

  if (items === undefined) {
    return renderUndefined();
  }

  const renderDismissableItem = (item: T, idx: number) => (
    <Dismissable
      key={keyExtractor(item, idx)}
      onDismiss={() => {
        onDismiss(item, idx);
      }}
      style={styles.item}
    >
      {renderItem(item, idx)}
    </Dismissable>
  );

  const renderSwipeableItem = (item: T, idx: number) => (
    <Swipeable
      addSkewAlongXAxis
      key={keyExtractor(item, idx)}
      onSwipe={() => {
        onDismiss(item, idx);
      }}
      style={styles.item}
    >
      {renderItem(item, idx)}
    </Swipeable>
  );

  return (
    <View {...getTestProps({ elementId, elementName: "Stack" })}>
      {renderEmpty()}
      {items.map((item, idx) =>
        Platform.OS === "web" ? renderDismissableItem(item, idx) : renderSwipeableItem(item, idx)
      )}
    </View>
  );
};
