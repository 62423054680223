import { StyleSheet } from "react-native";
import { useGetTestProps, useSkeleton } from "@src/hooks";
import Animated from "react-native-reanimated";
import type { CommonProps } from "../types";
import type { VFC } from "react";
import type { ViewProps } from "react-native";

const ICON_SIZE = 24;

export type IconSkeletonProps = CommonProps & Pick<ViewProps, "style">;

export const IconSkeleton: VFC<IconSkeletonProps> = ({ elementId, style }) => {
  const getTestProps = useGetTestProps();
  const skeletonStyles = useSkeleton();

  const styles = StyleSheet.create({
    iconSkeleton: {
      height: ICON_SIZE,
      width: ICON_SIZE,
    },
  });

  return (
    <Animated.View
      style={[styles.iconSkeleton, skeletonStyles, style]}
      {...getTestProps({
        elementId,
        elementName: "IconSkeleton",
      })}
    />
  );
};
