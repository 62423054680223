import { Badge } from "@merit/frontend-components";
import { msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useRoute } from "@react-navigation/native";
import type { BadgeProps } from "@merit/frontend-components";
import type { Container } from "@merit/issuance-client";
import type { I18n } from "@lingui/core";

type MeritStatusBadgeProps = {
  readonly merit?: Container;
};

type ColorText = {
  readonly color: BadgeProps["color"];
  readonly text: string;
};

const getColorText = (i18n: I18n, state: string): ColorText | undefined => {
  const STATUS_TO_COLOR_TEXT_MAP: Record<string, ColorText> = {
    accepted: {
      color: "jade",
      text: t(i18n)({ context: "state", message: "Active" }),
    },
    pending: {
      color: "citrine",
      text: t(i18n)({ context: "state", message: "Pending" }),
    },
    unissued: {
      color: "coral",
      text: t(i18n)({ context: "state", message: "Inactive" }),
    },
  };

  return STATUS_TO_COLOR_TEXT_MAP[state];
};

const MeritStatusBadge = ({ merit }: MeritStatusBadgeProps) => {
  const route = useRoute();
  const { _, i18n } = useLingui();

  // special handling of active/inactive Status (separate from State)
  if (merit?.active === false) {
    return (
      <Badge
        color="coral"
        testProps={{
          elementId: merit.id,
          elementName: "MeritStatusBadge",
          screenName: route.name,
        }}
        text={_(msg({ context: "state", message: "Inactive" }))}
      />
    );
  }

  if (merit?.state?.name === undefined) {
    return null;
  }

  const colorText = getColorText(i18n, merit.state.name);
  if (colorText === undefined) {
    return null;
  }

  const { color, text } = colorText;

  return (
    <Badge
      color={color}
      testProps={{
        elementId: merit.id,
        elementName: "MeritStatusBadge",
        screenName: route.name,
      }}
      text={text}
    />
  );
};

export { MeritStatusBadge };
