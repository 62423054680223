import { PolicyRequestDetailsDesktop } from "./PolicyRequestDetailsDesktop";
import { PolicyRequestDetailsMobile } from "./PolicyRequestDetailsMobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";

export const PolicyRequestDetails: FC = () => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <PolicyRequestDetailsDesktop />;
  }

  return <PolicyRequestDetailsMobile />;
};
