import { ProfileImage } from "./ProfileImage";
import { ProfileImageDesktop } from "./ProfileImageDesktop";
import { useLayoutType } from "@src/hooks";
import type { ProfileImageProps } from "./types";

const LayoutProfileImage = ({ ...props }: ProfileImageProps) => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <ProfileImageDesktop {...props} />;
  }

  return <ProfileImage {...props} />;
};

export { LayoutProfileImage as ProfileImage };
