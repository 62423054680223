// Copyright 2023 Merit International Inc. All Rights Reserved.

import { create } from "zustand";
import type { AlertProps } from "@merit/frontend-components";

export type AlertState = {
  readonly alertList: readonly AlertProps[];
  readonly pushAlert: (alert: AlertProps) => void;
  readonly deleteAlert: (id: string) => void;
};

const DISPLAY_TIME = 4000;

export const useAlertStore = create<AlertState>(set => ({
  alertList: [],
  deleteAlert: (id: string) => {
    set(state => ({
      alertList: state.alertList.filter(alert => alert.id !== id),
    }));
  },
  pushAlert: (alert: AlertProps) => {
    set(state => {
      // Deduplicate on alert.id
      if (state.alertList.find(a => a.id === alert.id) !== undefined) {
        return {};
      }

      // Auto hides the alert based on duration
      setTimeout(() => {
        state.deleteAlert(alert.id);
      }, DISPLAY_TIME);

      return { alertList: [...state.alertList, alert] };
    });
  },
}));
