import { Body, useTheme } from "@merit/frontend-components";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { openExternalLink } from "@src/utils";
import { useGetTestProps } from "@src/hooks";
import type { AppDetails } from "./types";
import type { FC } from "react";

export type ConnectedAppButtonProps = AppDetails;

export const ConnectedAppButton: FC<ConnectedAppButtonProps> = ({
  id,
  logo,
  logoText,
  name,
  url,
}) => {
  const { theme } = useTheme();
  const getTestProps = useGetTestProps();

  const styles = StyleSheet.create({
    logo: {
      height: logoText === undefined ? 30 : 28,
      width: logoText === undefined ? 30 : 28,
    },
    logoContainer: {
      alignItems: "center",
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      height: 48,
      justifyContent: "center",
      marginBottom: theme.spacing.s,
      width: 48,
    },
    logoText: {
      fontSize: 10,
      lineHeight: 16,
    },
    orgName: {
      fontWeight: theme.fontWeights.semiBold,
      textAlign: "center",
    },
    pressable: {
      alignItems: "center",
      flexDirection: "column",
      width: 60,
    },
  });

  const handlePress = () => {
    openExternalLink(url);
  };

  return (
    <Pressable
      onPress={handlePress}
      style={styles.pressable}
      {...getTestProps({
        elementId: `pressable-${id}`,
        elementName: "ConnectedAppButton",
      })}
    >
      <View style={styles.logoContainer}>
        <Image resizeMode="contain" source={logo} style={styles.logo} />
        {logoText === undefined ? null : (
          <Body
            style={styles.logoText}
            {...getTestProps({
              elementId: `logoText-${id}`,
              elementName: "ConnectedAppButton",
            })}
          >
            {logoText}
          </Body>
        )}
      </View>
      <Body
        style={styles.orgName}
        {...getTestProps({
          elementId: `appName-${id}`,
          elementName: "ConnectedAppButton",
        })}
      >
        {name}
      </Body>
    </Pressable>
  );
};
