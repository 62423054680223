import { ActivityIndicator, StyleSheet, View } from "react-native";
import { EmptyState } from "./EmptyState";
import { FolioTabDetails } from "./FolioTabDetails";
import { FolioTabFilesAndMedia } from "./FolioTabFilesAndMedia";
import { Log } from "@src/utils";
import { MeritSummary } from "../MeritDetails/MeritSummary";
import { Tabs } from "../Tabs";
import { msg } from "@lingui/macro";
import { useAdminMeritOrgAccountFolioDetails } from "./hooks";
import { useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMemo } from "react";
import { useTheme } from "@merit/frontend-components";
import type { ExtendedContainer, Merit } from "@src/api/issuance";
import type { FieldMap } from "@src/api/issuance/types";
import type { ViewStyle } from "react-native";

type FolioDetailsProps = {
  readonly adminMeritId: Merit["id"];
};

const ORG_NAME_KEY = "Organization Name";
const ORG_LEGAL_NAME_KEY = "Org Legal Name";

export const AdminMeritOrgAccountFolioDetails = ({ adminMeritId }: FolioDetailsProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly tabsSectionContainer: ViewStyle;
    readonly topSectionContainer: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      paddingTop: theme.spacing.xl,
    },
    tabsSectionContainer: {
      flex: 1,
    },
    topSectionContainer: {
      marginHorizontal: theme.spacing.l,
    },
  });

  const getTestProps = useGetTestProps();
  const { folio, showEmptyState, showLoadingState } = useAdminMeritOrgAccountFolioDetails({
    adminMeritId,
  });
  const { _ } = useLingui();

  const extendedFolio = useMemo<ExtendedContainer>(() => {
    const fieldMap: FieldMap = {};

    // eslint-disable-next-line functional/no-loop-statement
    for (const field of folio?.fields ?? []) {
      // eslint-disable-next-line functional/immutable-data, @typescript-eslint/non-nullable-type-assertion-style
      fieldMap[field.name as string] = field.value as string;
    }

    const transformedFields = {
      issuedOn:
        folio?.createdAt === undefined
          ? _(msg`Unknown issue date`)
          : Intl.DateTimeFormat("default", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }).format(new Date(folio.createdAt)),
      orgName: fieldMap[ORG_NAME_KEY],
      recipientFullName: fieldMap[ORG_LEGAL_NAME_KEY],
    };

    return {
      ...folio,
      fieldMap,
      transformedFields,
    } as ExtendedContainer;
  }, [_, folio]);

  if (showLoadingState) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  if (showEmptyState || folio?.id === undefined /** 2nd condition to appease TS */) {
    Log.error(`Failed to fetch or find associated folio for admin merit with ID: ${adminMeritId}`);

    return (
      <View style={styles.container}>
        <EmptyState />
      </View>
    );
  }

  return (
    <View
      style={styles.container}
      {...getTestProps({ elementId: folio.id, elementName: "FolioDetails" })}
    >
      <View style={styles.topSectionContainer}>
        {/* TODO: Make a FolioSummary */}
        <MeritSummary merit={extendedFolio as Merit} />
      </View>
      <View style={styles.tabsSectionContainer}>
        <Tabs
          tabs={[
            {
              content: <FolioTabDetails folio={folio} />,
              id: "details",
              title: _(msg`DETAILS`),
            },
            {
              content: <FolioTabFilesAndMedia folio={folio} />,
              id: "filesAndMedia",
              title: _(msg`FILES & MEDIA`),
            },
          ]}
        />
      </View>
    </View>
  );
};
