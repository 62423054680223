import { Body, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { useRoute } from "@react-navigation/native";
import type { ReactNode, VFC } from "react";

export type HeaderProps = {
  readonly height: number;
  readonly icon: ReactNode;
  readonly minimized?: boolean;
  readonly text: string;
};

export const Header: VFC<HeaderProps> = ({ height, icon, minimized = false, text }) => {
  const route = useRoute();
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      flexDirection: "row",
      height,
      justifyContent: minimized ? "center" : undefined,
      padding: theme.spacing.m,
    },
    iconContainer: {
      marginRight: minimized ? undefined : theme.spacing.m,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>{icon}</View>
      {minimized ? null : (
        <Body
          numberOfLines={1}
          testProps={{
            elementName: "Header",
            screenName: route.name,
          }}
        >
          {text}
        </Body>
      )}
    </View>
  );
};
