import { merge } from "lodash";

export function findAndMerge<T extends object>(
  arr: readonly T[],
  fn: (item: T) => boolean,
  obj: Partial<T>
): readonly T[] | undefined {
  const idx = arr.findIndex(fn);
  if (idx === -1) {
    return undefined;
  }

  const item = arr[idx];
  const mergedItem = merge({}, item, obj);

  const newArr = [...arr.slice(0, idx), mergedItem, ...arr.slice(idx + 1)];

  return newArr;
}
