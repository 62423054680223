import type { DdLogs } from "expo-datadog";

/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable require-await */
/* eslint-disable no-console */
/* eslint-disable sort-keys-fix/sort-keys-fix */

export const ConsoleLogger: PublicInterface<typeof DdLogs> = {
  debug: async (message, ...rest) => {
    const context = rest.at(-1) as object | undefined;
    if (context !== undefined) {
      console.debug(message, JSON.stringify(context));

      return;
    }

    console.debug(message);
  },
  info: async (message, ...rest) => {
    const context = rest.at(-1) as object | undefined;
    if (context !== undefined) {
      console.info(message, JSON.stringify(context));

      return;
    }

    console.info(message);
  },
  warn: async (message, ...rest) => {
    const context = rest.at(-1) as object | undefined;
    if (context !== undefined) {
      console.warn(message, JSON.stringify(context));

      return;
    }

    console.warn(message);
  },
  error: async (message, ...rest) => {
    const context = rest.at(-1) as object | undefined;
    if (context !== undefined) {
      console.error(message, JSON.stringify(context));

      return;
    }

    console.error(message);
  },
  registerLogEventMapper: () => {
    /* noop */
  },
  unregisterLogEventMapper: () => {
    /* noop */
  },
};
