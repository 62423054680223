import { AdminMeritFieldMapSchema } from "../schemas/AdminMeritFieldMapSchema";
import { Log } from "@src/utils";
import type { AdminMerit } from "../types/AdminMerit";

export const toAdminMerit = (
  merit: PickPartial<AdminMerit, "transformedFields">
): PickPartial<AdminMerit, "transformedFields"> => {
  const { fieldMap, ...rest } = merit;
  const result = AdminMeritFieldMapSchema.safeParse(fieldMap);

  if (!result.success) {
    Log.error("Admin merit fields are missing or invalid", {
      containerId: merit.id,
      error: result.error.toString(),
    });

    throw new Error("Admin merit fields missing or invalid");
  }

  return {
    ...rest,
    fieldMap: result.data,
  };
};
