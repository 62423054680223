import { Heading } from "@merit/frontend-components";
import { StyleSheet } from "react-native";
import type { FC } from "react";
import type { HeadingProps } from "@merit/frontend-components";
import type { TextStyle } from "react-native";

const styles = StyleSheet.create<{
  readonly wrapper: TextStyle;
}>({
  wrapper: {
    flex: 1,
  },
});

export const TruncatedHeading: FC<HeadingProps> = ({ children, ...rest }) => (
  <Heading ellipsizeMode="tail" numberOfLines={1} style={styles.wrapper} {...rest}>
    {children}
  </Heading>
);
