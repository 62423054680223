import { Body, useTheme } from "@merit/frontend-components";
import { Icon as PEIcon } from "@src/components";
import { StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import type { CommonProps } from "../types";
import type { VFC } from "react";

const DEFAULT_MESSAGE = "Whoops! Something went wrong";
export type ErrorProps = CommonProps & {
  readonly message?: string;
};

export const Error: VFC<ErrorProps> = ({ elementId, message = DEFAULT_MESSAGE }) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      flexDirection: "row",
      padding: theme.spacing.m,
    },
    iconContainer: {
      marginRight: theme.spacing.m,
    },
  });

  const getTestProps = useGetTestProps();

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementId,
        elementName: "Error",
      })}
    >
      <View style={styles.iconContainer}>
        <PEIcon
          color={theme.colors.icon.alert.critical}
          name="warning"
          size={theme.fontSizes.l.fontSize}
        />
      </View>
      <Body>{message}</Body>
    </View>
  );
};
