// Browser mock for the React Navigation view tracking datadog plugin, see also webpack.config.js
//
// The browser-rum package uses the history API to do view tracking, so we don't need to hook into
// React Navigation

/* eslint-disable @typescript-eslint/no-empty-function */

export const DdRumReactNavigationTracking = {
  startTrackingViews: () => {},
};
