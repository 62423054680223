import { MeritListItemLayout } from "../MeritListItemLayout";
import { OrgLogo, PendingStateIndicator, TruncatedBody } from "@src/components";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useGetTestProps, useRoute } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";
import type { FC } from "react";
import type { MeritListItemProps } from "./types";

export const MeritListItem: FC<MeritListItemProps> = ({ merit, onPress }) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    issuerOrg: {
      flexGrow: 2,
    },
    meritName: {
      flex: 1,
      fontWeight: theme.fontWeights.semiBold,
      ...theme.fontSizes.m,
    },
    subduedText: {
      color: theme.colors.text.subdued,
    },
  });

  const getTestProps = useGetTestProps();
  const route = useRoute();

  return (
    <TouchableOpacity
      onPress={onPress}
      {...getTestProps({
        elementId: merit.id,
        elementName: "MeritListItem",
      })}
    >
      <MeritListItemLayout
        issuedAt={
          <TruncatedBody
            style={[styles.subduedText, styles.issuerOrg]}
            testProps={{
              elementId: "createdAt",
              elementName: "MeritListItem",
              screenName: route.name,
            }}
          >
            {merit.createdAt === undefined
              ? null
              : Intl.DateTimeFormat("default", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }).format(new Date(merit.createdAt))}
          </TruncatedBody>
        }
        issuerOrgName={
          <TruncatedBody
            style={[styles.subduedText, styles.issuerOrg]}
            testProps={{
              elementId: "issuingOrgName",
              elementName: "MeritListItem",
              screenName: route.name,
            }}
          >
            {merit.transformedFields.orgName}
          </TruncatedBody>
        }
        meritName={
          <TruncatedBody
            size="l"
            style={styles.meritName}
            testProps={{
              elementId: "meritName",
              elementName: "MeritListItem",
              screenName: route.name,
            }}
          >
            {merit.name}
          </TruncatedBody>
        }
        orgImage={
          <>
            <OrgLogo merit={merit} />
            {merit.state?.name === "pending" ? <PendingStateIndicator /> : null}
          </>
        }
      />
    </TouchableOpacity>
  );
};
