import { Log } from "@src/utils";
import { useMemberApi } from "./useMemberApi";
import { useMutation } from "@tanstack/react-query";
import type { UpdateAccountMeritRequest } from "@merit/person-experience-backend-client";

export const useUpdateAccountMerit = () => {
  const { api: memberApi } = useMemberApi();

  return useMutation({
    mutationFn: (properties: UpdateAccountMeritRequest) =>
      memberApi.updateAccountMerit({ properties }),
    onError: (error, properties) => {
      Log.error("Error updating account merit", { error, properties });
    },
  });
};
