import { HEADER_HEIGHT } from "@src/utils/constants/sizes";
import { Heading, TextInput, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import { useEffect, useRef } from "react";
import { useGetTestProps } from "@src/hooks";
import { useIsFocused } from "@react-navigation/native";
import { useLingui } from "@lingui/react";
import type { VFC } from "react";

export type SideBarContentHeaderProps = {
  readonly title: string;
  readonly onChangeText?: (text: string) => Promise<void> | void;
  readonly onFocus?: () => Promise<void> | void;
};

export const SideBarContentHeader: VFC<SideBarContentHeaderProps> = ({
  onChangeText,
  onFocus,
  title,
}) => {
  const getTestProps = useGetTestProps();
  const inputRef = useRef(null);
  const isFocused = useIsFocused();
  const { theme } = useTheme();
  const { _ } = useLingui();

  useEffect(() => {
    if (isFocused) {
      // @ts-expect-error can't get this inputRef type right
      inputRef.current?.focus();
    }
  }, [isFocused]);

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flexDirection: "row",
      height: HEADER_HEIGHT,
      justifyContent: "space-between",
    },
    headerItem: {
      flex: 1,
      paddingHorizontal: theme.spacing.xxl,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.headerItem}>
        <Heading
          bold
          level="2"
          {...getTestProps({
            elementId: "heading",
            elementName: "SideBarContentHeader",
          })}
        >
          {title}
        </Heading>
      </View>
      <View style={styles.headerItem}>
        <TextInput
          autoFocus
          leftIcon="searchSmallSubdued"
          onChangeText={onChangeText}
          onFocus={onFocus}
          placeholder={_(msg({ context: "placeholder", message: "Search" }))}
          ref={inputRef}
          {...getTestProps({
            elementId: "searchInput",
            elementName: "SideBarContentHeader",
          })}
        />
      </View>
      <View style={styles.headerItem} />
    </View>
  );
};
