/* eslint-disable functional/immutable-data */
/* eslint-disable no-param-reassign */
/* eslint-disable sort-keys-fix/sort-keys-fix */

import { create } from "zustand";
import { z } from "zod";
import produce from "immer";
import type { Container } from "@merit/issuance-client";

type MeritId = Container["id"];

export const CreatePersonaFormDataSchema = z.object({
  meritIds: z.set(z.string().uuid()).nonempty(),
  name: z.string(),
});

type CreatePersonaFormData = z.infer<typeof CreatePersonaFormDataSchema>;

export type CreatePersonaFormDataState = PickPartial<CreatePersonaFormData, "name"> & {
  readonly addMerit: (meritId: MeritId) => void;
  readonly removeMerit: (meritId: MeritId) => void;
  readonly setName: (name: string) => void;
  readonly clear: () => void;
};

export const useCreatePersonaFormDataStore = create<CreatePersonaFormDataState>(set => ({
  meritIds: new Set(),
  name: undefined,

  addMerit: meritId => {
    set(
      produce(state => {
        state.meritIds.add(meritId);
      })
    );
  },

  removeMerit: meritId => {
    set(
      produce(state => {
        state.meritIds.delete(meritId);
      })
    );
  },

  setName: (name: string) => {
    set(
      produce(state => {
        state.name = name;
      })
    );
  },

  clear: () => {
    set(
      produce(state => {
        state.meritIds.clear();
        state.name = undefined;
      })
    );
  },
}));
