import { Body, Button, useTheme } from "@merit/frontend-components";
import { Error, KeyboardAvoidingView } from "@src/components";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import { OnboardingForm } from "@src/components/OnboardingForm";
import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context";
import { Trans, msg } from "@lingui/macro";
import { useAccountMerit } from "@src/api/issuance";
import { useAlerts, useLayoutType, useLogout } from "@src/hooks";
import { useEffect, useState } from "react";
import { useLingui } from "@lingui/react";
import OnboardingCards from "@src/assets/images/onboarding-cards.png";
import type { ImageStyle, ViewStyle } from "react-native";

const OnboardingRegistration = () => {
  const { bottom, top } = useSafeAreaInsets();
  const { theme } = useTheme();
  const [scrollViewContentHeight, setScrollViewContentHeight] = useState<number | undefined>();
  const defaultStyles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly contentContainer: ViewStyle;
    readonly scrollViewContentContainer: ViewStyle;
    readonly scrollView: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      justifyContent: "center",
    },
    contentContainer: {
      alignItems: "center",
      ...(scrollViewContentHeight === undefined ? { maxHeight: scrollViewContentHeight } : {}),
    },
    scrollView: {
      maxWidth: 720,
      width: "100%",
    },
    scrollViewContentContainer: {
      alignItems: "stretch",
      maxWidth: 720,
      paddingBottom: 48 + bottom,
      paddingHorizontal: 32,
      paddingTop: 31 + top,
    },
  });
  const desktopStyles = StyleSheet.create<{
    readonly cardsContainer: ViewStyle;
    readonly cardsImage: ImageStyle;
    readonly container: ViewStyle;
    readonly formContainer: ViewStyle;
    readonly panelLeft: ViewStyle;
    readonly panelRight: ViewStyle;
  }>({
    cardsContainer: {
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    cardsImage: {
      height: 595,
      width: 501,
    },
    container: {
      alignItems: "stretch",
      flex: 1,
      flexDirection: "row",
    },
    formContainer: {
      width: 416,
    },
    panelLeft: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flex: 1,
      justifyContent: "center",
      maxWidth: 720,
      paddingVertical: theme.spacing.xxl,
    },
    panelRight: {
      alignItems: "stretch",
      backgroundColor: theme.colors.brand.midnight, // TODO (PE-572): look into https://docs.expo.dev/versions/latest/sdk/linear-gradient/
      flex: 1,
    },
  });

  const { data: accountMerit, isError } = useAccountMerit();
  const { sendAlert } = useAlerts();
  const { refetch: refetchAccountMerit } = useAccountMerit();
  const logout = useLogout();
  const layoutType = useLayoutType();
  const { _ } = useLingui();

  const [shouldPollAcctMeritUpdates, setShouldPollAcctMeritUpdates] = useState(false);

  useEffect(() => {
    if (!shouldPollAcctMeritUpdates) {
      return () => {
        /** noop, just here to satisfy consistent returns in this useEffect */
      };
    }

    // RootStackNavigator will handle routing to logged in experience using the
    // useOnboardingEffect() hook
    const intervalId = setInterval(() => {
      refetchAccountMerit();
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [refetchAccountMerit, shouldPollAcctMeritUpdates]);

  const onSubmitSuccess = () => {
    sendAlert({
      id: "OnboardingRegistration-accepting-merit-success",
      text1: _(
        msg`Logging you in.  Any changes made to your name may take up to 1 min to display.`
      ),
      type: "success",
    });
    setShouldPollAcctMeritUpdates(true);
  };

  const onSubmitFailure = (submitError: Error) => {
    sendAlert({
      id: "OnboardingRegistration-accepting-merit-error",
      text1: _(msg`Error accepting your merit`),
      text2: String(submitError),
      type: "error",
    });
  };

  if (isError) {
    return (
      <SafeAreaView style={defaultStyles.container}>
        <Error />
        <Button
          onPress={() => {
            logout();
          }}
          text={_(msg`Logout`)}
        />
      </SafeAreaView>
    );
  }

  if (accountMerit?.state?.name === "rejected") {
    return (
      <SafeAreaView style={defaultStyles.container}>
        <Body>
          <Trans>Looks like your account has been closed.</Trans>
        </Body>
        <Button
          onPress={() => {
            logout();
          }}
          text={_(msg`Logout`)}
        />
      </SafeAreaView>
    );
  }

  if (layoutType === "mobile") {
    return (
      <KeyboardAvoidingView style={defaultStyles.container}>
        <View style={defaultStyles.contentContainer}>
          <ScrollView
            contentContainerStyle={defaultStyles.scrollViewContentContainer}
            style={defaultStyles.scrollView}
          >
            <View
              onLayout={evt => {
                setScrollViewContentHeight(evt.nativeEvent.layout.height);
              }}
            >
              <OnboardingForm onSubmitFailure={onSubmitFailure} onSubmitSuccess={onSubmitSuccess} />
            </View>
          </ScrollView>
        </View>
      </KeyboardAvoidingView>
    );
  }

  return (
    <View style={desktopStyles.container}>
      <View style={desktopStyles.panelLeft}>
        <View style={desktopStyles.formContainer}>
          <OnboardingForm onSubmitFailure={onSubmitFailure} onSubmitSuccess={onSubmitSuccess} />
        </View>
      </View>
      <View style={desktopStyles.panelRight}>
        <View style={desktopStyles.cardsContainer}>
          <Image source={OnboardingCards} style={desktopStyles.cardsImage} />
        </View>
      </View>
    </View>
  );
};

export { OnboardingRegistration };
