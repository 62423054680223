import { IconSkeleton, TextSkeleton } from "@src/components";
import { MeritListItemLayout } from "@src/layouts";

export const MeritListItemSkeleton = () => (
  <MeritListItemLayout
    leftIcon={<IconSkeleton />}
    leftSubtext={<TextSkeleton />}
    rightIcon={<IconSkeleton />}
    rightSubtext={<TextSkeleton />}
    title={<TextSkeleton />}
  />
);
