/* eslint-disable lingui/no-unlocalized-strings */
/* eslint-disable @typescript-eslint/no-use-before-define */

// This is a limited version of the formulas spec. It limits the depth of the formula tree and
// limits the types of formulas that can exist at each layer. This _should_ cover all of the
// use-cases and it allows us to more easily get the template and field ids accessed in a formula.
//
// Ideally, I would have liked to build a schema to validate the entire object, but it was
// difficult.

import { z } from "zod";

export const ConjunctionSchema = z.object({
  conjunction: z.array(z.any()),
  formulaType: z.literal("Conjunction"),
});

export const DisjunctionSchema = z.object({
  disjunction: z.array(z.any()),
  formulaType: z.literal("Disjunction"),
});

export const FieldLevelCompoundAtomSchema = z.object({
  formulaType: z.literal("CompoundAtom"),
  predicate: z.string(),
  target: z.string().uuid(),
});

export const TemplateLevelCompoundAtomWithFieldSchema = z.object({
  arguments: z.tuple([z.literal("1"), z.string().uuid()]),
  formula: z.union([ConjunctionSchema, FieldLevelCompoundAtomSchema, DisjunctionSchema]),
  formulaType: z.literal("CompoundAtom"),
  predicate: z.literal("ReceivedXContainersFromTemplates"),
});

export const TemplateLevelCompoundAtomSchema = z.object({
  arguments: z.tuple([z.literal("1"), z.string().uuid()]),
  formulaType: z.literal("CompoundAtom"),
  predicate: z.literal("ReceivedXContainersFromTemplates"),
});

const FormulaSchema = z.union([
  ConjunctionSchema,
  DisjunctionSchema,
  TemplateLevelCompoundAtomWithFieldSchema,
  TemplateLevelCompoundAtomSchema,
]);

const ResponseFormulasSchema = z.object({
  own: FormulaSchema,
});

export const PolicySchema = z.object({
  formula: ResponseFormulasSchema,
  id: z.string().uuid(),
});
