import { Log, getCoppaAcknowledgedFromContainerFields, hasState } from "@src/utils";
import { PatchContainerStateEnum } from "@merit/issuance-client";
import { isActive } from "@src/utils/merits";
import { isAdminMerit, useAccountMerit, useMerits } from "@src/api/issuance";
import { useEffect, useMemo } from "react";
import { useOnboardingStore } from "@src/stores";

const noop = () => {
  // noop
};

export const useOnboardingEffect = (): null => {
  const { data: accountMerit } = useAccountMerit();
  const onboardingStep = useOnboardingStore(state => state.onboardingStep);
  const setOnboardingStep = useOnboardingStore(state => state.setOnboardingStep);

  const { data: merits } = useMerits();

  const pendingMerits = useMemo(
    () => merits?.filter(hasState(["pending"])).filter(isActive(true)) ?? [],
    [merits]
  );

  const pendingAdminMerits = useMemo(() => pendingMerits.filter(isAdminMerit), [pendingMerits]);

  useEffect(() => {
    if (accountMerit === undefined) {
      return noop;
    }

    const isCoppaAcknowledged = getCoppaAcknowledgedFromContainerFields(accountMerit.fields ?? []);
    const isAccountMeritAccepted = accountMerit.state?.name === PatchContainerStateEnum.Accepted;

    const isRegistrationComplete = isCoppaAcknowledged && isAccountMeritAccepted;

    if (!isRegistrationComplete) {
      setOnboardingStep("REGISTRATION");

      return noop;
    }

    if (onboardingStep !== "COMPLETE") {
      setOnboardingStep("ONBOARDING_MERITS");

      return noop;
    }

    // onboardingStep is already COMPLETE, but check if we need to send
    // them back into the onboarding flow for admin merits
    if (pendingAdminMerits.length > 0) {
      setOnboardingStep("ONBOARDING_MERITS");
      Log.info("User has already completed onboarding and is forced back into it to accept");

      return noop;
    }

    return noop;
  }, [accountMerit, onboardingStep, pendingAdminMerits.length, setOnboardingStep]);

  return null;
};
