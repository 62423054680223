import { PolicyRequestDetailsNormal } from "./PolicyRequestDetailsNormal";
import { PolicyRequestDetailsSkeleton } from "./PolicyRequestDetailsSkeleton";
import { getPolicyIds } from "../utils";
import { usePolicy } from "@src/api/issuance";
import type { FC } from "react";
import type { PolicyRequest } from "@src/api/issuance";

type PartialPolicyRequest = DeepPick<PolicyRequest, "owner.name" | "policy">;

type PolicyRequestDetailsProps = {
  readonly policyRequest?: PartialPolicyRequest;
};

export const PolicyRequestDetails: FC<PolicyRequestDetailsProps> = ({ policyRequest }) => {
  const policyQuery = usePolicy({ policyId: policyRequest?.policy });

  if (policyRequest === undefined || policyQuery.isPending || policyQuery.isError) {
    return <PolicyRequestDetailsSkeleton />;
  }

  const policyIds = getPolicyIds(policyQuery.data);

  return <PolicyRequestDetailsNormal orgName={policyRequest.owner.name} policyIds={policyIds} />;
};
