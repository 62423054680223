import { Configuration as ConfigurationCls, EntityMergesApi } from "@merit/issuance-client";
import { getLocalConfig } from "@src/utils";
import { useMeritAuth0 } from "@src/hooks";

const config = getLocalConfig();

type UseEntityMergesAPIReturn = {
  readonly api: EntityMergesApi;
  readonly isReady: boolean;
};

const useEntityMergesApi = (): UseEntityMergesAPIReturn => {
  const { accessToken } = useMeritAuth0();

  return {
    api: new EntityMergesApi(
      new ConfigurationCls({
        ...config.api.issuance,
        headers: {
          ...config.api.issuance.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ),
    isReady: accessToken !== "",
  };
};

export { useEntityMergesApi };
