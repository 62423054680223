import { Copyright } from "./Copyright";
import { Links } from "./Links";
import { Spacer } from "./Spacer";
import { StyleSheet, View } from "react-native";
import { useLayoutType } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

export const Footer = () => {
  const { theme } = useTheme();
  const desktopStyles = StyleSheet.create<{
    readonly container: ViewStyle;
  }>({
    container: {
      alignItems: "center",
      flexDirection: "row",
    },
  });
  const mobileStyles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly copyright: ViewStyle;
  }>({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      marginTop: theme.spacing.xs,
    },
    copyright: {
      marginBottom: theme.spacing.s,
    },
  });

  const layoutType = useLayoutType();

  if (layoutType === "mobile") {
    return (
      <View style={mobileStyles.container}>
        <Copyright style={mobileStyles.copyright} />
        <Links />
      </View>
    );
  }

  return (
    <View style={desktopStyles.container}>
      <Copyright />
      <Spacer />
      <Links />
    </View>
  );
};
