/* eslint-disable functional/prefer-readonly-type */
/* eslint-disable functional/immutable-data */
/* eslint-disable functional/no-loop-statement */
/* eslint-disable no-continue */

import {
  ConjunctionSchema,
  DisjunctionSchema,
  FieldLevelCompoundAtomSchema,
  TemplateLevelCompoundAtomSchema,
  TemplateLevelCompoundAtomWithFieldSchema,
} from "@src/api/issuance/schemas/PolicySchema";
import { Log } from "@src/utils";
import type { Policy } from "@src/api/issuance";

export type PolicyIds = {
  readonly templateIds: readonly string[];
  readonly templateFieldIdsByTemplateId: Record<string, readonly string[]>;
};

type GetPolicyIds = (policy: Policy) => PolicyIds;

// Extract a list of templateIds accessed by the policy and a map between templateIds and associated
// templateFieldIds accessed by the policy
//
// We need to extract a map between templateIds and a list of templateFieldIds; however, a single
// node in the tree does not contain both templateId and templateFieldId. The structure is such that
// nodes that contain templateFieldIds are always children of nodes that contain templateIds. In
// order construct the map, we traverse the tree using a depth-first search and keep a reference to
// the most recent templateId we have seen. Then when we see a templateFieldId we associate it with
// the most recent templateId.

export const getPolicyIds: GetPolicyIds = policy => {
  // eslint-disable-next-line @typescript-eslint/init-declarations, functional/no-let
  let currentTemplateId: string | undefined;
  const stack: unknown[] = [];
  const templateFieldIdsByTemplateId: Record<string, string[]> = {};
  const templateIds: string[] = [];

  stack.push(policy.formula.own);

  while (stack.length > 0) {
    const currentFormula = stack.pop();

    const conjunctionResult = ConjunctionSchema.safeParse(currentFormula);
    if (conjunctionResult.success) {
      stack.push(...conjunctionResult.data.conjunction);
      continue;
    }

    const disjunctionResult = DisjunctionSchema.safeParse(currentFormula);
    if (disjunctionResult.success) {
      stack.push(...disjunctionResult.data.disjunction);
      continue;
    }

    const templateLevelCompoundAtomWithFieldResult =
      TemplateLevelCompoundAtomWithFieldSchema.safeParse(currentFormula);
    if (templateLevelCompoundAtomWithFieldResult.success) {
      currentTemplateId = templateLevelCompoundAtomWithFieldResult.data.arguments[1];
      stack.push(templateLevelCompoundAtomWithFieldResult.data.formula);
      continue;
    }

    const templateLevelCompoundAtomResult =
      TemplateLevelCompoundAtomSchema.safeParse(currentFormula);
    if (templateLevelCompoundAtomResult.success) {
      templateIds.push(templateLevelCompoundAtomResult.data.arguments[1]);
      continue;
    }

    const fieldLevelCompoundAtomResult = FieldLevelCompoundAtomSchema.safeParse(currentFormula);
    if (fieldLevelCompoundAtomResult.success) {
      if (currentTemplateId === undefined) {
        Log.error("Arrived at templateField node before seeing template node", {
          formula: currentFormula,
          policyId: policy.id,
        });
        continue;
      }

      if (templateFieldIdsByTemplateId[currentTemplateId] === undefined) {
        templateFieldIdsByTemplateId[currentTemplateId] = [];
      }

      // The conditional directly above ensures that the object is not undefined
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      templateFieldIdsByTemplateId[currentTemplateId]!.push(
        fieldLevelCompoundAtomResult.data.target
      );

      continue;
    }

    Log.error("Could not parse policy formula node", {
      formula: currentFormula,
      policyId: policy.id,
    });
  }

  return { templateFieldIdsByTemplateId, templateIds };
};
