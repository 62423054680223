import { FilesAndMediaListItemLayout } from "@src/layouts";
import { IconSkeleton, TextSkeleton } from "@src/components/Skeleton";
import { StyleSheet } from "react-native";
import type { FC } from "react";
import type { ViewStyle } from "react-native";

export const FilesAndMediaListItemSkeleton: FC = () => {
  const styles = StyleSheet.create<{ readonly textSkeleton: ViewStyle }>({
    textSkeleton: {
      marginBottom: 0,
    },
  });

  return (
    <FilesAndMediaListItemLayout
      hideArrow
      icon={<IconSkeleton />}
      name={<TextSkeleton style={styles.textSkeleton} />}
    />
  );
};
