import { StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";
import Modal from "react-native-modal";
import type { PropsWithChildren } from "react";
import type { StyleProp, ViewStyle } from "react-native";

type BottomSlideUpModalProps = {
  readonly isSwipeDismissable?: boolean;
  readonly isVisible?: boolean;
  readonly onClose: () => void;
  readonly appearanceType: "card" | "drawer";
  readonly contentContainerStyle?: StyleProp<ViewStyle>;
};

export const BottomSlideUpModal = ({
  appearanceType,
  children,
  contentContainerStyle,
  isSwipeDismissable = true,
  isVisible = true,
  onClose,
}: PropsWithChildren<BottomSlideUpModalProps>) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly contentContainerCardType: ViewStyle;
    readonly contentContainerDrawerType: ViewStyle;
    readonly drawerHandle: ViewStyle;
    readonly drawerHandleContainer: ViewStyle;
    readonly modalContainer: ViewStyle;
  }>({
    contentContainerCardType: {
      backgroundColor: theme.colors.background.white,
      borderRadius: theme.borderRadii.l,
    },
    contentContainerDrawerType: {
      backgroundColor: theme.colors.background.white,
    },
    drawerHandle: {
      alignSelf: "center",
      backgroundColor: theme.colors.background.white,
      borderRadius: 6,
      height: 6,
      width: 48,
    },
    drawerHandleContainer: {
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing.m,
    },
    modalContainer: {
      justifyContent: "flex-end",
      margin: 0,
    },
  });

  const getTestProps = useGetTestProps();

  return (
    <Modal
      isVisible={isVisible}
      onBackButtonPress={onClose}
      onSwipeComplete={onClose}
      style={styles.modalContainer}
      swipeDirection={isSwipeDismissable ? "down" : []}
      {...getTestProps({
        elementName: "BottomSlideUpModal",
      })}
    >
      {appearanceType === "drawer" && isSwipeDismissable ? (
        <View style={styles.drawerHandleContainer}>
          <View style={styles.drawerHandle} />
        </View>
      ) : null}
      <View
        style={[
          appearanceType === "drawer"
            ? styles.contentContainerDrawerType
            : styles.contentContainerCardType,
          contentContainerStyle,
        ]}
        {...getTestProps({
          elementId: "content",
          elementName: "BottomSlideUpModal",
        })}
      >
        {children}
      </View>
    </Modal>
  );
};
