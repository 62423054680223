import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMutation } from "@tanstack/react-query";
import { usePersonPreferencesApi } from "./usePersonPreferencesApi";
import type { PersonprefsApiUpsertPersonPreferencesOperationRequest } from "@merit/person-experience-backend-client";

export const useUpsertPersonPreferences = () => {
  const { api: personPreferencesApi } = usePersonPreferencesApi();
  const { sendAlert } = useAlerts();
  const { _ } = useLingui();

  return useMutation({
    mutationFn: (req: PersonprefsApiUpsertPersonPreferencesOperationRequest) =>
      personPreferencesApi.upsertPersonPreferences(req),
    onError: err => {
      Log.error(`Error upserting person preferences`, { error: err });
      sendAlert({
        id: "upsertPersonPreferences-error",
        text1: _(msg`Error updating your preferences`),
        text2: _(msg`Something went wrong`),
        type: "error",
      });
    },
  });
};
