import { MeritsScreen as DesktopMeritsScreen } from "./MeritsScreen.desktop";
import { MeritsScreen as MobileMeritsScreen } from "./MeritsScreen.mobile";
import { useLayoutType } from "@src/hooks";

const MeritsScreen = () => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopMeritsScreen />;
  }

  return <MobileMeritsScreen />;
};

export { MeritsScreen };
