import { MainHeader } from "@src/components";
import { SearchableMeritsList } from "./SearchableMeritsList";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

const MeritsScreen = () => {
  const { _ } = useLingui();

  return (
    <>
      <MainHeader title={_(msg({ context: "title", message: "Merits" }))} />
      <SearchableMeritsList />
    </>
  );
};

export { MeritsScreen };
