import { Button, useTheme } from "@merit/frontend-components";
import { Log } from "@src/utils";
import { StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import { useAlerts, useGetTestProps } from "@src/hooks";
import { useApprovePolicyRequest, useDenyPolicyRequest, usePolicy } from "@src/api/issuance";
import { useLingui } from "@lingui/react";
import type { FC } from "react";
import type { PolicyRequest } from "@src/api/issuance";
import type { ViewStyle } from "react-native";

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "PolicyRequestActions";

type PolicyRequestActionsProps = {
  readonly policyRequest?: Pick<PolicyRequest, "id" | "policy">;
};

export const PolicyRequestActions: FC<PolicyRequestActionsProps> = ({ policyRequest }) => {
  const { _ } = useLingui();
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const { sendAlert } = useAlerts();

  const policyQuery = usePolicy({ policyId: policyRequest?.policy });

  const approvePolicyRequestMutation = useApprovePolicyRequest(
    { policyRequestId: policyRequest?.id },
    { showSuccessToast: false }
  );
  const denyPolicyRequestMutation = useDenyPolicyRequest(
    { policyRequestId: policyRequest?.id },
    { showSuccessToast: false }
  );

  const styles = StyleSheet.create<{
    readonly actionsContainer: ViewStyle;
    readonly approveButton: ViewStyle;
    readonly container: ViewStyle;
    readonly denyButton: ViewStyle;
  }>({
    actionsContainer: {
      flex: 1,
      flexDirection: "row",
      gap: theme.spacing.l,
      justifyContent: "flex-end",
      maxWidth: 350,
    },
    approveButton: {
      flex: 9,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: theme.spacing.xxl,
    },
    denyButton: {
      flex: 4,
    },
  });

  const handlePressApprove = () => {
    // This should not happen. Buttons are disabled when policyRequest is undefined
    if (policyRequest === undefined) {
      sendAlert({
        id: "PolicyRequestActions-ApproveError",
        text1: _(msg`Something went wrong while trying to approve your verification`),
        type: "error",
      });
      Log.error("Failed to approve PolicyRequest. PolicyRequest is undefined");

      return;
    }

    approvePolicyRequestMutation.mutate({ policyId: policyRequest.policy });
  };

  const handlePressDeny = () => {
    // This should not happen. Buttons are disabled when policyRequest is undefined
    if (policyRequest === undefined) {
      sendAlert({
        id: "PolicyRequestActions-DenyError",
        text1: _(msg`Something went wrong while trying to deny your verification`),
        type: "error",
      });
      Log.error("Failed to deny PolicyRequest. PolicyRequest is undefined");

      return;
    }

    denyPolicyRequestMutation.mutate({ policyId: policyRequest.policy });
  };

  const disabled =
    policyRequest === undefined ||
    policyQuery.isPending ||
    policyQuery.isError ||
    approvePolicyRequestMutation.isPending ||
    denyPolicyRequestMutation.isPending;

  return (
    <View style={styles.container}>
      <View style={styles.actionsContainer}>
        <View style={styles.denyButton}>
          <Button
            disabled={disabled}
            onPress={handlePressDeny}
            text={_(msg`Deny`)}
            type="destructive"
            {...getTestProps({ elementName }, { componentName: "denyButton" })}
          />
        </View>
        <View style={styles.approveButton}>
          <Button
            disabled={disabled}
            onPress={handlePressApprove}
            text={_(msg`Approve`)}
            type="primary"
            {...getTestProps({ elementName }, { componentName: "approveButton" })}
          />
        </View>
      </View>
    </View>
  );
};
