import { AccountMeritFieldMapSchema } from "../schemas";
import { Log } from "@src/utils";
import type { AccountMerit } from "../types";

export const toAccountMerit = (
  merit: PickPartial<AccountMerit, "transformedFields">
): PickPartial<AccountMerit, "transformedFields"> => {
  const { fieldMap: _, ...rest } = merit;
  const result = AccountMeritFieldMapSchema.safeParse(merit.fieldMap);

  if (!result.success) {
    Log.error("Account merit fields are missing or invalid", {
      containerId: merit.id,
      error: result.error.toString(),
    });

    throw new Error("Account merit fields missing or invalid");
  }

  return {
    ...rest,
    // @ts-expect-error `Alternate Emails` doesn't match type FieldMap. Not sure why TS wants it
    // to
    fieldMap: result.data,
  };
};
