/* eslint-disable functional/no-loop-statement */
/* eslint-disable functional/immutable-data */
/* eslint-disable no-continue */

import { Log } from "@src/utils";
import type { ExtendedContainer, FieldMap } from "../types";

type WithFieldMap = (
  container: PickPartial<ExtendedContainer, "fieldMap" | "transformedFields">
) => PickPartial<ExtendedContainer, "transformedFields">;

export const withFieldMap: WithFieldMap = container => {
  const fieldMap: FieldMap = {};

  for (const field of container.fields ?? []) {
    if (field.name === undefined) {
      Log.warn("Container field is missing name attribute", { containerId: container.id });

      continue;
    }

    fieldMap[field.name] = field.value;
  }

  return {
    ...container,
    fieldMap,
  };
};
