import { Icon, useTheme } from "@merit/frontend-components";
import { Pressable, StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import type { CommonProps } from "../types";
import type { FCWithChildren } from "@src/types/component";
import type { StyleProp, ViewStyle } from "react-native";

type DismissableProps = CommonProps & {
  readonly onDismiss: () => void;
  readonly style?: StyleProp<ViewStyle>;
};

export const Dismissable: FCWithChildren<DismissableProps> = ({
  children,
  elementId,
  onDismiss,
  style,
}) => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly dismissButton: ViewStyle;
  }>({
    dismissButton: {
      padding: theme.spacing.m, // for a larger pressable area
      position: "absolute",
      right: 0,
      top: 0,
    },
  });

  return (
    <View
      style={style}
      {...getTestProps({
        elementId,
        elementName: "Dismissable",
      })}
    >
      <View>{children}</View>
      <Pressable
        onPress={onDismiss}
        style={styles.dismissButton}
        {...getTestProps(
          {
            elementId,
            elementName: "Dismissable",
          },
          { componentName: "CloseButton" }
        )}
      >
        <Icon name="closeSmallDefault" />
      </Pressable>
    </View>
  );
};
