import { StyleSheet } from "react-native";
import { useSkeleton } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";
import Animated from "react-native-reanimated";
import type { ViewStyle } from "react-native";

export const GreetingSkeleton = () => {
  const { theme } = useTheme();
  const containerHeight = theme.fontSizes.l.lineHeight;
  const skeletonHeight = containerHeight / 1.5; // make actual skeleton shorter than lineHeight so it doesn't feel too cramped
  const marginVertical = (containerHeight - skeletonHeight) / 2;
  const styles = StyleSheet.create<{
    readonly textSkeleton: ViewStyle;
  }>({
    textSkeleton: {
      borderRadius: skeletonHeight / 2,
      height: skeletonHeight,
      marginVertical,
      width: "60%",
    },
  });

  const skeletonStyles = useSkeleton(theme.colors.surface.depressed);

  return <Animated.View style={[styles.textSkeleton, skeletonStyles]} />;
};
