import { NotificationMerit as DesktopNotificationMerit } from "./NotificationMerit.desktop";
import { NotificationMerit as MobileNotificationMerit } from "./NotificationMerit.mobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";
import type { NotificationMeritProps } from "./types";

export const NotificationMerit: FC<NotificationMeritProps> = props => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopNotificationMerit {...props} />;
  }

  return <MobileNotificationMerit {...props} />;
};
