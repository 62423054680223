import { OnboardingMeritsLayoutDesktop } from "./OnboardingMeritsLayoutDesktop";
import { OnboardingMeritsLayoutMobile } from "./OnboardingMeritsLayoutMobile";
import { useLayoutType } from "@src/hooks";
import type { OnboardingMeritsLayoutProps } from "./types";

const OnboardingMeritsLayout = (props: OnboardingMeritsLayoutProps) => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <OnboardingMeritsLayoutDesktop {...props} />;
  }

  return <OnboardingMeritsLayoutMobile {...props} />;
};

export { OnboardingMeritsLayout };
