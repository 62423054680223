import { ConnectedAppsDesktop } from "./ConnectedAppsDesktop";
import { ConnectedAppsMobile } from "./ConnectedAppsMobile";
import { useLayoutType } from "@src/hooks";

export const ConnectedApps = () => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <ConnectedAppsDesktop />;
  }

  return <ConnectedAppsMobile />;
};
