import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts, useMeritAuth0 } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePolicyRequestsApi } from "./usePolicyRequestsApi";
import { useRequestedPolicyRequestsQueryKey } from "./useRequestedPolicyRequests";
// TODO@tyler: If I'm gunna use this type, I need to verify it with the schema
import { PolicyRequestSchema } from "./schemas";
import { useNotificationsController } from "@src/screens/Home/components/Notifications";
import type { PolicyRequest } from "./types";

export const useApprovePolicyRequestMutationKey = (args: Arguments) => [
  "approvePolicyRequest",
  args,
];

type Arguments = {
  readonly policyRequestId: PolicyRequest["id"] | undefined;
};

type Options = {
  readonly showErrorToast: boolean;
  readonly showMutatingToast: boolean;
  readonly showSuccessToast: boolean;
};

const defaultOptions: Options = {
  showErrorToast: true,
  showMutatingToast: true,
  showSuccessToast: true,
};

export const useApprovePolicyRequest = (
  args: Arguments,
  options: Partial<Options> = defaultOptions
) => {
  const { sendAlert } = useAlerts();
  const queryClient = useQueryClient();
  const queryKey = useRequestedPolicyRequestsQueryKey();
  const { _ } = useLingui();
  const { api } = usePolicyRequestsApi();
  const { user } = useMeritAuth0();
  const mutationKey = useApprovePolicyRequestMutationKey(args);
  const { refreshNotifications } = useNotificationsController();

  return useMutation({
    mutationFn: async ({ policyId }: { readonly policyId: PolicyRequest["policy"] }) => {
      if (user?.entityID === undefined) {
        Log.error("entityId is undefined");
        throw new Error("entityId is undefined");
      }

      const response = await api.patchPolicyRequest({
        policyID: policyId,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        properties: { status: "Approved" },
        targetID: user.entityID,
      });

      const result = PolicyRequestSchema.safeParse(response);
      if (!result.success) {
        Log.error("PolicyRequest doesn't match schema", { err: result.error.errors });
        throw new Error("PolicyRequest doesn't match schema");
      }

      return result.data;
    },
    mutationKey,
    onError: (err, { policyId }) => {
      Log.error(`Error approving policyRequest`, { err, policyId });
      if (options.showErrorToast === true) {
        sendAlert({
          id: "useApprovePolicyRequest-Error",
          text1: _(msg`Something went wrong while trying to approve your verification request`),
          type: "error",
        });
      }
    },
    onMutate: () => {
      if (options.showMutatingToast === true) {
        sendAlert({
          id: "useApprovePolicyRequest-Accepting",
          text1: _(msg`Approving…`),
          type: "info",
        });
      }
    },
    onSuccess: () => {
      if (options.showSuccessToast === true) {
        sendAlert({
          id: `useApprovePolicyRequest-Success`,
          text1: _(msg`You successfully approved your verification request`),
          type: "success",
        });
      }

      queryClient.invalidateQueries({ queryKey });
      refreshNotifications();
    },
  });
};
