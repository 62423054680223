// Deduplicates items in an array given a predicate
//
// Usage:
//
// ```
// const arr = [{id: 1}, {id: 2}, {id: 1}]
// const deduplicated = arr.filter(deduplicate(item => item.id)) // [{id: 1}, {id: 2}]
// ```

type Hashable = number | string | symbol;

type GetKey<T> = (item: T) => Hashable;

type Filter<T> = (item: T, index: number, array: readonly T[]) => boolean;

export function deduplicate<T>(getKey: GetKey<T>): Filter<T> {
  const keys = new Set();

  return item => {
    if (keys.has(getKey(item))) {
      return false;
    }

    keys.add(getKey(item));

    return true;
  };
}
