import { StyleSheet, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { ReactNode, VFC } from "react";

export type MeritListItemLayoutProps = {
  readonly leftIcon: ReactNode;
  readonly title: ReactNode;
  readonly leftSubtext: ReactNode;
  readonly rightSubtext?: ReactNode;
  readonly rightIcon: ReactNode;
};

export const MeritListItemLayout: VFC<MeritListItemLayoutProps> = ({
  leftIcon,
  leftSubtext,
  rightIcon,
  rightSubtext,
  title,
}) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    attributesContainer: {
      flex: 1,
      justifyContent: "space-between",
      marginRight: theme.spacing.m,
    },
    container: {
      flex: 1,
      flexDirection: "row",
      padding: theme.spacing.m,
    },
    leftIconContainer: {
      justifyContent: "center",
      marginRight: theme.spacing.m,
    },
    rightIconContainer: {
      justifyContent: "center",
    },
    rightSubtextContainer: {
      flexDirection: "column-reverse",
      marginRight: theme.spacing.m,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.leftIconContainer}>{leftIcon}</View>
      <View style={styles.attributesContainer}>
        {title}
        {leftSubtext}
      </View>
      <View style={styles.rightSubtextContainer}>{rightSubtext}</View>
      <View style={styles.rightIconContainer}>{rightIcon}</View>
    </View>
  );
};
