import { Body, Icon, useTheme } from "@merit/frontend-components";
import { Dropdown } from "react-native-element-dropdown";
import { StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import type { ComponentProps } from "react";

const FONT_FAMILY = "ProximaNova";

export type SelectInputProps<T> = ComponentProps<typeof Dropdown<T>> & {
  readonly label?: string;
};

export function SelectInput<T>({ label, ...rest }: SelectInputProps<T>): JSX.Element {
  const { theme } = useTheme();
  const getTestProps = useGetTestProps();

  const styles = StyleSheet.create({
    dropdownContainer: {
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
    },
    item: {
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.s,
    },
    label: {
      marginBottom: theme.spacing.xs,
    },
    placeholder: {
      color: theme.colors.text.subdued,
      // fontSize and lineHeight for <Body />
      fontSize: 14,
      lineHeight: 20,
      paddingHorizontal: theme.spacing.xs,
    },
    selectContainer: {
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      padding: theme.spacing.s,
    },
    selectedText: {
      // fontSize and lineHeight for <Body />
      fontSize: 14,
      lineHeight: 20,
      paddingHorizontal: theme.spacing.xs,
    },
  });

  return (
    <View>
      {label === undefined ? null : (
        <Body
          style={styles.label}
          {...getTestProps({ elementId: "label", elementName: "SelectInput" })}
        >
          {label}
        </Body>
      )}
      <Dropdown
        {...rest}
        activeColor={theme.colors.brand.turquiose}
        containerStyle={styles.dropdownContainer}
        fontFamily={FONT_FAMILY}
        placeholderStyle={styles.placeholder}
        renderItem={item => (
          <Body
            style={styles.item}
            {...getTestProps({
              elementId: `option-${String(item[rest.valueField])}`,
              elementName: "SelectInput",
            })}
          >
            {String(item[rest.labelField])}
          </Body>
        )}
        renderRightIcon={() => (
          <Icon
            name="chevronDownSmallDefault"
            {...getTestProps({
              elementId: `rightIcon`,
              elementName: "SelectInput",
            })}
          />
        )}
        selectedTextStyle={styles.selectedText}
        style={styles.selectContainer}
      />
    </View>
  );
}
