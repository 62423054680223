import { Linking } from "react-native";

// We do not know explicitly why, but in order for `Linking.openURL` to work in firefox
// we need to resolve a promise. It may have something to do with running the code on the UI
// thread. https://github.com/facebook/react-native/issues/33006 Could be related as well.
const openExternalLink = async (url: string) => {
  await Promise.resolve();
  await Linking.openURL(url);
};

export { openExternalLink };
