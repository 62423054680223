import { Body } from "@merit/frontend-components";
import type { StyleProp, ViewStyle } from "react-native";

type CopyrightProps = {
  readonly style?: StyleProp<ViewStyle>;
};

const MERIT_COPYRIGHT_TEXT = "Merit International © 2024";
export const Copyright = ({ style }: CopyrightProps) => (
  <Body size="s" style={style}>
    {MERIT_COPYRIGHT_TEXT}
  </Body>
);
