import { useFonts } from "expo-font";
// TODO (PE-92): the import path should not have to include cjs or esm
import IcoMoon from "@src/assets/fonts/IcoMoon.ttf";
import ProximaNova from "@merit/frontend-components/dist/fonts/ProximaNova.otf";
import ProximaNovaSemiBold from "@merit/frontend-components/dist/fonts/ProximaNovaSemiBold.otf";

const usePersonExperienceFonts = () => {
  const [loaded, error] = useFonts({
    ProximaNova,
    ProximaNovaSemiBold,
    "The-Icon-of": IcoMoon,
  });

  return [loaded, error];
};

export { usePersonExperienceFonts };
