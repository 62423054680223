import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMFAApi } from "./useMFAApi";
import { useMutation } from "@tanstack/react-query";

export const useCreateMFAEnrollmentTicket = () => {
  const { api } = useMFAApi();
  const { sendAlert } = useAlerts();
  const { _ } = useLingui();

  return useMutation({
    mutationFn: () => api.createMFAEnrollmentTicket(),
    onError: err => {
      Log.error(`Error creating MFA enrollment ticket`, { error: err });
      sendAlert({
        id: "createMFAEnrollmentTicket-error",
        text1: _(
          msg({
            comment: "MFA = multi-factor authentication",
            message: "Error enrolling in MFA",
          })
        ),
        text2: _(msg`Something went wrong`),
        type: "error",
      });
    },
  });
};
