import { Log, unloadAbortController } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts, useLogoutOnError, useMeritAuth0 } from "@src/hooks";
import { useEffect } from "react";
import { useLingui } from "@lingui/react";
import { useQuery } from "@tanstack/react-query";
import { useSignedEntityApi } from "./useSignedEntityApi";
import type { MeritUserInfo } from "@src/types/user";

type QueryKey = readonly ["personalSignedToken", { readonly userId?: MeritUserInfo["entityID"] }];

export const usePersonalSignedTokenQueryKey = (): QueryKey => {
  const { user } = useMeritAuth0();

  return ["personalSignedToken", { userId: user?.entityID }];
};

export const usePersonalSignedToken = () => {
  const { api: signedEntityApi, isReady } = useSignedEntityApi();
  const queryKey = usePersonalSignedTokenQueryKey();
  const { onError } = useLogoutOnError();
  const { sendAlert } = useAlerts();
  const { _ } = useLingui();

  const query = useQuery({
    enabled: isReady,
    queryFn: () => signedEntityApi.getSignedEntityID({}, { signal: unloadAbortController.signal }),
    queryKey,
  });

  useEffect(() => {
    if (query.isError) {
      Log.error("Failed to fetch signed entity ID", { error: query.error });
      sendAlert({
        id: "usePersonalSignedToken-Failed",
        text1: _(msg`Something went wrong while fetching your personal QR code`),
        type: "error",
      });

      onError(query.error);
    }
  }, [query.isError, query.error, onError, sendAlert, _]);

  return query;
};
