import { Image, ScrollView, StyleSheet, View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { SafeAreaView } from "react-native-safe-area-context";
import { useTheme } from "@merit/frontend-components";
import OnboardingMeritsCard from "@src/assets/images/onboarding-merits.png";
import type { OnboardingMeritsLayoutProps } from "./types";

const OnboardingMeritsLayoutDesktop = ({
  body,
  footer,
  heading,
  pageHeaderLeft,
  pageHeaderRight,
}: OnboardingMeritsLayoutProps) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    bodyContainer: {
      flex: 1,
      padding: theme.spacing.xxl,
    },
    contentContainer: {
      alignSelf: "center",
      flex: 1,
      maxWidth: 800,
      padding: theme.spacing.xxl,
      width: 700,
    },
    pageContainer: {
      backgroundColor: theme.colors.background.default,
      flex: 1,
      flexDirection: "row",
      height: "100%",
    },
    pageHeadingContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    scrollContainer: {
      flex: 1,
      flexBasis: 1024,
    },
    sideContainer: {
      alignItems: "center",
      backgroundColor: "#0B1D37", // TODO: Use neutral900 when we expose it in theme
      flex: 1,
      flexBasis: 400,
      flexShrink: 0,
      justifyContent: "center",
    },
    sideImage: {
      // Alternative approach to doing flex: 1, width: 100% - theme.spacing.xxl
      bottom: theme.spacing.xxl * 2,
      left: theme.spacing.xxl,
      position: "absolute",
      right: theme.spacing.xxl,
      top: theme.spacing.xxl * 2,
    },
  });

  return (
    <SafeAreaView style={styles.pageContainer}>
      <View style={styles.scrollContainer}>
        <ScrollView>
          <View style={styles.contentContainer}>
            <View style={styles.pageHeadingContainer}>
              <View>{pageHeaderLeft}</View>
              <View>{pageHeaderRight}</View>
            </View>
            {heading}
            <View style={styles.bodyContainer}>{body}</View>
          </View>
        </ScrollView>
        {footer}
      </View>
      <LinearGradient
        colors={[theme.colors.brand.oceanBlue, "transparent"]}
        locations={[0.2277, 0.8843]}
        style={styles.sideContainer}
      >
        <Image resizeMode="center" source={OnboardingMeritsCard} style={styles.sideImage} />
      </LinearGradient>
    </SafeAreaView>
  );
};

export { OnboardingMeritsLayoutDesktop };
