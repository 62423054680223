import { ScrollView, StyleSheet, View } from "react-native";
import { forwardRef } from "react";
import { useListController } from "./useListController";
import type { ForwardedRef } from "react";
import type { ListElement, ListProps } from "./types";

// Redecalare forwardRef
// See https://fettblog.eu/typescript-react-generic-forward-refs/#option-3%3A-augment-forwardref
declare module "react" {
  // This is not a shadow. It's a module declaration.
  // eslint-disable-next-line @typescript-eslint/no-shadow
  function forwardRef<T, P = object>(
    render: (props: P, ref: Ref<T>) => ReactElement | null
  ): (props: P & RefAttributes<T>) => ReactElement | null;
}

const ListInner = <T,>(props: ListProps<T>, ref: ForwardedRef<ListElement>) => {
  const { data, keyExtractor, onStateChange, renderItem } = props;

  const { getKey, handleItemLayout, handleScroll, handleScrollViewLayout, scrollViewRef } =
    useListController({ data, keyExtractor, onStateChange, ref });

  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
  });

  return (
    <View style={styles.container}>
      <ScrollView
        horizontal
        onLayout={handleScrollViewLayout}
        onScroll={handleScroll}
        ref={scrollViewRef}
        scrollEnabled={false}
        scrollEventThrottle={0}
      >
        {data.map((item, index, arr) => {
          const key = getKey(item, index, arr);

          return (
            <View
              key={key}
              onLayout={event => {
                handleItemLayout(event, index);
              }}
            >
              {renderItem(item, index)}
            </View>
          );
        })}
      </ScrollView>
    </View>
  );
};

export const List = forwardRef(ListInner);
