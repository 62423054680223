import { Platform, KeyboardAvoidingView as RNKeyboardAvoidingView } from "react-native";
import type { FC, PropsWithChildren } from "react";
import type { KeyboardAvoidingViewProps } from "react-native";

// Android needs "height", otherwise contents are squished. iOS needs "padding", or else the layout
// "resets" when typing
export const behavior = Platform.OS === "android" ? "height" : "padding";

export const KeyboardAvoidingView: FC<PropsWithChildren<KeyboardAvoidingViewProps>> = ({
  children,
  ...rest
}) => (
  <RNKeyboardAvoidingView behavior={behavior} {...rest}>
    {children}
  </RNKeyboardAvoidingView>
);
