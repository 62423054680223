import { Body, Icon } from "@merit/frontend-components";
import { FilesAndMediaListItemLayout } from "@src/layouts";
import { Platform, Pressable } from "react-native";
import { openBrowserAsync } from "expo-web-browser";
import { openExternalLink } from "@src/utils";
import { useEffect, useState } from "react";
import { useGetTestProps } from "@src/hooks";
import type { ContainerField } from "@merit/issuance-client";

type MeritBlobRowProps = {
  readonly containerField: ContainerField;
};

export const FilesAndMediaBlobFieldListItem = ({ containerField }: MeritBlobRowProps) => {
  const getTestProps = useGetTestProps();

  const [isUrlValid, setIsUrlValid] = useState(false);

  useEffect(() => {
    const { value: url } = containerField;
    setIsUrlValid((url?.length ?? 0) > 0);
  }, [containerField]);

  const onPress = () => {
    if (containerField.value === undefined || !isUrlValid) {
      return;
    }
    // TODO (PE-417): need product confirmation on these open file behaviors
    if (Platform.OS === "web") {
      openExternalLink(containerField.value);
    } else {
      openBrowserAsync(containerField.value); // opens in a webview modal
    }
  };

  return (
    <Pressable
      onPress={onPress}
      {...getTestProps(
        {
          elementId: containerField.templateFieldID,
          elementName: "FilesAndMediaBlobFieldListItem",
        },
        {
          componentName: "OpenButton",
        }
      )}
    >
      <FilesAndMediaListItemLayout
        hideArrow={!isUrlValid}
        icon={<Icon name="documentMediumDefault" />}
        name={
          <Body>
            {containerField.name ?? `Attachment ${containerField.templateFieldID ?? "unknown"}`}
          </Body>
        }
      />
    </Pressable>
  );
};
