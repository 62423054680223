import { Home, MeritsScreen } from "@src/screens";
import { Icon } from "@merit/frontend-components";
import { Pressable, StyleSheet } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { msg } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import type { ImageStyle } from "react-native";
import type { ScreenParamList } from "../types";

export type BottomTabsParamList = Pick<ScreenParamList, "Home" | "Merits">;

const BottomTabs = createBottomTabNavigator<BottomTabsParamList>();
const BOTTOM_NAV_ICON_SIZE = 24;

export const BottomTabsNavigator = () => {
  const styles = StyleSheet.create<{
    readonly icon: ImageStyle;
  }>({
    icon: {
      height: BOTTOM_NAV_ICON_SIZE,
      width: BOTTOM_NAV_ICON_SIZE,
    },
  });
  const getTestProps = useGetTestProps();
  const { _ } = useLingui();

  return (
    <BottomTabs.Navigator
      initialRouteName="Home"
      screenOptions={{ headerShown: false, tabBarHideOnKeyboard: true }}
    >
      <BottomTabs.Screen
        component={Home}
        name="Home"
        options={({ navigation }) => ({
          tabBarButton: props => (
            <Pressable
              {...props}
              {...getTestProps({
                elementId: "home",
                elementName: "BottomTab",
              })}
            />
          ),
          tabBarIcon: () => (
            <Icon
              name={Boolean(navigation.isFocused()) ? "homeLargeActive" : "homeLargeDefault"}
              style={styles.icon}
            />
          ),
          tabBarLabel: _(msg({ context: "navigation item", message: "Home" })),
        })}
      />
      <BottomTabs.Screen
        component={MeritsScreen}
        name="Merits"
        options={({ navigation }) => ({
          tabBarButton: props => (
            <Pressable
              {...props}
              {...getTestProps({
                elementId: "merits",
                elementName: "BottomTab",
              })}
            />
          ),
          tabBarIcon: () => (
            <Icon
              name={Boolean(navigation.isFocused()) ? "idCardLargeActive" : "idCardLargeDefault"}
              style={styles.icon}
            />
          ),
          tabBarLabel: _(msg({ context: "navigation item", message: "Merits" })),
        })}
      />
    </BottomTabs.Navigator>
  );
};
