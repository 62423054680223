import { StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

const PendingStateIndicator = () => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly indicator: ViewStyle;
  }>({
    container: {
      marginRight: theme.spacing.s,
    },
    indicator: {
      backgroundColor: theme.colors.brand.oceanBlue,
      borderRadius: theme.spacing.m / 2,
      height: theme.spacing.m,
      width: theme.spacing.m,
    },
  });

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementName: "PendingStateIndicator",
      })}
    >
      <View style={styles.indicator} />
    </View>
  );
};

export { PendingStateIndicator };
