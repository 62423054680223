/* eslint-disable functional/no-loop-statement */
/* eslint-disable functional/immutable-data */
/* eslint-disable no-continue */
/* eslint-disable no-underscore-dangle */

import { useEffect } from "react";
import { useMerits } from "@src/api/issuance";
import { useTemplateIdsByTemplateFieldIdStore } from "../stores";
import type { TemplateIdsByTemplateFieldId } from "../types";

type UseTemplateIdsByTemplateFieldIdController = () => {
  readonly templateIdsByTemplateFieldId: TemplateIdsByTemplateFieldId;
};

export const useTemplateIdsByTemplateFieldIdController: UseTemplateIdsByTemplateFieldIdController =
  () => {
    const { data: merits } = useMerits();
    const setTemplateIdsByTemplateFieldId = useTemplateIdsByTemplateFieldIdStore(
      store => store.setTemplateIdsByTemplateFieldId
    );
    const templateIdsByTemplateFieldId = useTemplateIdsByTemplateFieldIdStore(
      store => store.templateIdsByTemplateFieldId
    );

    useEffect(() => {
      const _templateIdsByTemplateFieldId: TemplateIdsByTemplateFieldId = {};

      for (const merit of merits ?? []) {
        if (merit.templateId === undefined) {
          continue;
        }

        for (const field of merit.fields ?? []) {
          if (field.templateFieldID === undefined) {
            continue;
          }

          _templateIdsByTemplateFieldId[field.templateFieldID] = merit.templateId;
        }
      }

      setTemplateIdsByTemplateFieldId(_templateIdsByTemplateFieldId);
    }, [merits, setTemplateIdsByTemplateFieldId]);

    return { templateIdsByTemplateFieldId };
  };
