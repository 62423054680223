import { Body, Icon } from "@merit/frontend-components";
import { FilesAndMediaListItemLayout } from "@src/layouts";
import { Pressable } from "react-native";
import { openExternalLink } from "@src/utils";
import { useGetTestProps } from "@src/hooks";
import type { ExportedCredential } from "@merit/person-experience-backend-client";
import type { FC } from "react";

type CredentialListItemProps = {
  readonly credential: ExportedCredential;
};

export const CredentialListItem: FC<CredentialListItemProps> = ({ credential }) => {
  const getTestProps = useGetTestProps();

  const onPress = () => {
    openExternalLink(credential.url);
  };

  return (
    <Pressable
      onPress={onPress}
      {...getTestProps(
        {
          elementName: "CredentialListItem",
        },
        {
          componentName: "OpenButton",
        }
      )}
    >
      <FilesAndMediaListItemLayout
        icon={<Icon name="documentMediumDefault" />}
        name={<Body>{credential.name}</Body>}
      />
    </Pressable>
  );
};
