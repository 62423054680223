import { useLayoutType } from "./useLayoutType";
import { useNavigation as useRNNavigation } from "@react-navigation/native";
import type { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import type { DrawerNavigationProp } from "@react-navigation/drawer";
import type { ScreenParamList } from "@src/navigation";
import type { StackNavigationProp } from "@react-navigation/stack";

type NavigationProp = BottomTabNavigationProp<ScreenParamList> &
  DrawerNavigationProp<ScreenParamList> &
  StackNavigationProp<ScreenParamList>;

export const useNavigation = () => {
  const navigation = useRNNavigation<NavigationProp>();
  const layoutType = useLayoutType();

  const goHome = () => {
    if (layoutType === "desktop") {
      navigation.navigate("SideBarNavigator");

      return;
    }

    navigation.navigate("DrawerNavigator");
  };

  return {
    ...navigation,
    goBackOrGoHome: () => {
      if (navigation.canGoBack()) {
        navigation.goBack();

        return;
      }

      goHome();
    },
    goHome,
  };
};
