// Copyright 2024 Merit International Inc. All Rights Reserved.
import { PERSISTENT_STORE_NAMES } from "./common";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";

type SupportedLanguageCode = string;

type PreferencesState = {
  readonly languageCode: SupportedLanguageCode | undefined;
  readonly setLanguageCode: (languageCode: SupportedLanguageCode) => void;
  readonly isLanguageSetFromIdToken: boolean;
  readonly setIsLanguageSetFromIdToken: (isLanguageSetFromIdToken: boolean) => void;
};

export const usePreferencesStore = create<PreferencesState>()(
  persist(
    set => ({
      isLanguageSetFromIdToken: false,
      languageCode: undefined,
      setIsLanguageSetFromIdToken: (isLanguageSetFromIdToken: boolean) => {
        set(_ => ({
          isLanguageSetFromIdToken,
        }));
      },
      setLanguageCode: (languageCode: SupportedLanguageCode) => {
        set(_ => ({
          languageCode,
        }));
      },
    }),
    {
      name: PERSISTENT_STORE_NAMES.preferences,
      storage: createJSONStorage(() => AsyncStorage),
    }
  )
);
