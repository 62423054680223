import { GreetingSkeleton } from "@src/components";
import { Heading, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { getChosenNameFromContainerFields } from "@src/utils";
import { useAccountMerit } from "@src/api/issuance";
import { useMeritAuth0, useRoute } from "@src/hooks";

export const Greeting = () => {
  const route = useRoute();
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    marginBottom: {
      marginBottom: theme.spacing.m,
    },
  });

  const { data: accountMerit } = useAccountMerit();
  const { user } = useMeritAuth0();

  const fullName =
    accountMerit?.fields === undefined
      ? user?.nickname
      : getChosenNameFromContainerFields(accountMerit.fields);

  return (
    <View style={styles.marginBottom}>
      {accountMerit === undefined ? (
        <GreetingSkeleton />
      ) : (
        <Heading
          level="1"
          numberOfLines={1}
          testProps={{ elementId: "greeting", elementName: "Greeting", screenName: route.name }}
        >
          <Trans>Hi, {fullName}</Trans>
        </Heading>
      )}
    </View>
  );
};
