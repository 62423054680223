import { NotificationEmpty as DesktopNotificationEmpty } from "./NotificationEmpty.desktop";
import { NotificationEmpty as MobileNotificationEmpty } from "./NotificationEmpty.mobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";

export const NotificationEmpty: FC = () => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopNotificationEmpty />;
  }

  return <MobileNotificationEmpty />;
};
