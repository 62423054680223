import { BULLET_POINT } from "@src/utils/constants/unicode";
import { Body, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import { useMerits } from "@src/api/issuance";
import type { FC } from "react";
import type { Merit } from "@src/api/issuance";
import type { TextStyle } from "react-native";

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "TemplateFieldsListItem";

type TemplateFieldsListItemProps = {
  readonly templateId: Merit["templateId"];
  readonly templateFieldIds: readonly ArrayElement<Merit["fields"]>["templateFieldID"][];
};

export const TemplateFieldsListItem: FC<TemplateFieldsListItemProps> = ({
  templateFieldIds,
  templateId,
}) => {
  const meritsQuery = useMerits();
  const { theme } = useTheme();
  const getTestProps = useGetTestProps();

  const styles = StyleSheet.create<{
    readonly bold: TextStyle;
    readonly templateFieldText: TextStyle;
  }>({
    bold: {
      fontWeight: theme.fontWeights.semiBold,
    },
    templateFieldText: {
      marginLeft: theme.spacing.m,
    },
  });

  const merit = meritsQuery.data?.find(m => m.templateId === templateId);
  if (merit === undefined) {
    return null;
  }

  const templateName = merit.name;

  return (
    <View>
      <Body
        {...getTestProps(
          { elementId: templateId, elementName },
          { componentName: "templateNameBody" }
        )}
      >
        <Trans>
          {BULLET_POINT} View the following fields on the{" "}
          <Body style={styles.bold}>{templateName}</Body> merit
        </Trans>
      </Body>
      {templateFieldIds.map(templateFieldId => {
        const field = merit.fields?.find(f => f.templateFieldID === templateFieldId);

        if (field?.name === undefined) {
          return null;
        }

        return (
          <Body
            {...getTestProps(
              { elementId: field.templateFieldID, elementName },
              { componentName: "fieldNameBody" }
            )}
            key={templateFieldId}
            style={styles.templateFieldText}
          >
            {BULLET_POINT} {field.name}
          </Body>
        );
      })}
    </View>
  );
};
