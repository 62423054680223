import { DEFAULT_LOCALE, SUPPORTED_LANGS } from "./languages";
import { LocaleMatcher } from "@phensley/locale-matcher";
import { getLanguageCode } from "./getLanguageCode";

// the default language should be first
const resolvedLocales = [DEFAULT_LOCALE, ...SUPPORTED_LANGS.filter(x => x !== DEFAULT_LOCALE)];

// LocaleMatcher takes a comma/space delimited list of language tags
// docs: https://phensley.github.io/cldr-engine/docs/en/api-localematcheroptions.html#example
const matcher = new LocaleMatcher(resolvedLocales.join(", "));

/**
 * For a given language tag, get the closest language tag we support.
 *
 * For the most part, language tags with addl info such as script (Latn) or region (US, FR)
 * get matched to one of our supported languages. However, if a match doesn't exist, the
 * default locale with the max distance of 100 is returned. This is the case observed for
 * zh-Hant (zh = Chinese, Hant = Traditional Chinese script) or zh-TW (TW = Taiwan region),
 * so we will do a secondary check against just the language code itself.
 *
 * The LocaleMatcher when initialized with our language codes also appends script and
 * default region information to the tags. To keep the returned tags simple, only the
 * languageCode is returned.
 */
export const getSupportedLanguageTag = (languageTagString: string): string => {
  const { distance: distFull, locale: localeFull } = matcher.match(languageTagString);
  if (distFull < 100) {
    return localeFull.tag.language();
  }

  const languageCode = getLanguageCode(languageTagString);
  const { locale } = matcher.match(languageCode);

  // this will either be the default locale or a matching one
  return locale.tag.language();
};
