import {
  Configuration as ConfigurationCls,
  MemberApi,
} from "@merit/person-experience-backend-client";
import { getLocalConfig } from "@src/utils";
import { useMeritAuth0 } from "@src/hooks";

const config = getLocalConfig();

const useMemberApi = () => {
  const { accessToken } = useMeritAuth0();

  return {
    api: new MemberApi(
      new ConfigurationCls({
        ...config.api.personExperienceBackend,
        headers: {
          ...config.api.personExperienceBackend.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ),
    isReady: accessToken !== "",
  };
};

export { useMemberApi };
