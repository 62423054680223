import { Body, Icon, useTheme } from "@merit/frontend-components";
import { Image, StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import type { ImageStyle, TextStyle, ViewStyle } from "react-native";
import type { ProfileImageProps } from "./types";

const ProfileImageDesktop = ({ displayName, image, legalName }: ProfileImageProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly image: ImageStyle;
    readonly imageContainer: ViewStyle;
    readonly namesContainer: ViewStyle;
    readonly legalName: TextStyle;
  }>({
    container: {
      flexDirection: "row",
    },
    image: {
      height: "100%",
      width: "100%",
    },
    imageContainer: {
      height: 64,
      marginRight: theme.spacing.xxl,
      width: 64,
    },
    legalName: {
      ...theme.fontSizes.l,
      marginBottom: theme.spacing.s,
    },
    namesContainer: {
      flex: 1,
      justifyContent: "center",
    },
  });

  const getTestProps = useGetTestProps();

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementName: "ProfileImage",
      })}
    >
      <View style={styles.imageContainer}>
        {image === undefined ? (
          <Icon
            name="avatarCircleLarge"
            style={styles.image}
            {...getTestProps({
              elementId: "profileIcon",
              elementName: "ProfileImage",
            })}
          />
        ) : (
          <Image
            source={image}
            style={styles.image}
            {...getTestProps({
              elementId: "profileIcon",
              elementName: "ProfileImage",
            })}
          />
        )}
      </View>
      <View style={styles.namesContainer}>
        <Body
          numberOfLines={1}
          style={styles.legalName}
          {...getTestProps({
            elementId: "legalName",
            elementName: "ProfileImage",
          })}
        >
          {legalName}
        </Body>
        <Body
          numberOfLines={1}
          {...getTestProps({
            elementId: "displayName",
            elementName: "ProfileImage",
          })}
        >
          {displayName}
        </Body>
      </View>
    </View>
  );
};

export { ProfileImageDesktop };
