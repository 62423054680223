import { ScrollView, StyleSheet, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { FC, ReactNode } from "react";

type PolicyRequestDetailsLayoutProps = {
  readonly header: ReactNode;
  readonly body: ReactNode;
  readonly footer: ReactNode;
};

export const PolicyRequestDetailsLayout: FC<PolicyRequestDetailsLayoutProps> = ({
  body,
  footer,
  header,
}) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    body: {
      flex: 1,
      gap: theme.spacing.m,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      gap: theme.spacing.m,
      padding: theme.spacing.l,
    },
    header: {
      flexDirection: "row",
      gap: theme.spacing.m,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.header}>{header}</View>
      <ScrollView contentContainerStyle={styles.body}>{body}</ScrollView>
      <View>{footer}</View>
    </View>
  );
};
