import { DdRumReactNavigationTracking } from "@datadog/mobile-react-navigation";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";
import { RootStackNavigator } from "@src/navigation";
import { useLayoutType, useLinking } from "@src/hooks";
import { useRef } from "react";
import { useTheme } from "@merit/frontend-components";
import merge from "lodash.merge";
import type { Theme } from "@react-navigation/native";

export const Router = () => {
  const { theme } = useTheme();
  const layoutType = useLayoutType();
  const navigationTheme: Theme = merge({}, DefaultTheme, {
    colors: {
      background:
        layoutType === "desktop" ? theme.colors.background.white : theme.colors.background.default,
    },
  });

  const ref = useRef(null);
  const linking = useLinking();

  return (
    <NavigationContainer
      // @ts-expect-error - I think it doesn't like that it's a union
      linking={linking}
      onReady={() => {
        DdRumReactNavigationTracking.startTrackingViews(
          ref.current,
          (_, trackedName) => trackedName
        );
      }}
      ref={ref}
      theme={navigationTheme}
    >
      <RootStackNavigator />
    </NavigationContainer>
  );
};
