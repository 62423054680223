import { Log } from "@src/utils";
import { MeritFieldMapSchema } from "../schemas";
import type { ExtendedContainer, Merit } from "../types";

export const isMerit = (
  container: Pick<ExtendedContainer, "fieldMap" | "id">
): container is Merit => {
  const result = MeritFieldMapSchema.safeParse(container.fieldMap);

  if (!result.success) {
    Log.error("Merit fields are missing or invalid", {
      containerId: container.id,
      error: result.error.toString(),
    });

    return false;
  }

  return true;
};
