// A focus-aware status bar is needed for tab and drawer navigation because all components are
// rendered at the same time
//
// https://reactnavigation.org/docs/status-bar#tabs-and-drawer

import { StatusBar } from "react-native";
import { useIsFocused } from "@react-navigation/native";
import type { FC } from "react";
import type { StatusBarProps } from "react-native";

export const FocusAwareStatusBar: FC<StatusBarProps> = props => {
  const isFocused = useIsFocused();

  return isFocused ? <StatusBar {...props} /> : null;
};
