import { Body, Icon, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { getDurationStringFromRFC3339Duration, getFormattedString } from "@src/utils";
import { useGetTestProps, useRoute } from "@src/hooks";
import { useLingui } from "@lingui/react";
import type { Activity as CheckInDetailType } from "@src/api/issuance/types";
import type { ImageStyle, TextStyle, ViewStyle } from "react-native";

type CheckInProps = {
  readonly checkInDetail: CheckInDetailType;
};

export const CheckInDetail = ({ checkInDetail: activity }: CheckInProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly checkInContainer: ViewStyle;
    readonly checkInOutLabel: TextStyle;
    readonly checkInOutRow: ViewStyle;
    readonly checkInOutValue: TextStyle;
    readonly checkOutContainer: ViewStyle;
    readonly container: ViewStyle;
    readonly detailIconWrapper: ImageStyle;
    readonly detailRow: ViewStyle;
    readonly detailsContainer: ViewStyle;
  }>({
    checkInContainer: {
      flex: 1,
    },
    checkInOutLabel: {
      ...theme.fontSizes.s,
    },
    checkInOutRow: {
      alignItems: "center",
      flexDirection: "row",
      paddingVertical: theme.spacing.m,
    },
    checkInOutValue: {
      ...theme.fontSizes.m,
      fontWeight: theme.fontWeights.semiBold,
    },
    checkOutContainer: {
      borderLeftColor: theme.colors.border.shadow.subdued,
      borderLeftWidth: 1,
      flex: 1,
      paddingLeft: theme.spacing.xxl,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.m,
    },
    detailIconWrapper: {
      marginRight: theme.spacing.s,
    },
    detailRow: {
      flexDirection: "row",
      paddingVertical: theme.spacing.m,
    },
    detailsContainer: {
      marginBottom: theme.spacing.m,
    },
  });

  const getTestProps = useGetTestProps();
  const route = useRoute();
  const { _ } = useLingui();

  const INDETERMINABLE_LOCATION = _(msg`Unable to determine location`);

  const checkoutTimeString = (() => {
    const longDash = "\u2013"; // long dash –, see https://www.compart.com/en/unicode/U+2013
    try {
      return activity.checkoutTime === undefined || activity.checkoutTime === null
        ? longDash
        : getFormattedString(new Date(activity.checkoutTime), activity.timezone);
    } catch {
      return longDash;
    }
  })();

  const activityCheckinLocation = activity.checkinLocation;
  const activityCheckoutLocation = activity.checkoutLocation;
  const activityDuration = activity.duration;

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementId: activity.checkinTime, // TODO: use actual id for activity, if possible
        elementName: "Activity",
      })}
    >
      <View style={styles.checkInOutRow}>
        <View
          style={styles.checkInContainer}
          {...getTestProps({
            elementId: "checkIn",
            elementName: "Activity",
          })}
        >
          <Body style={styles.checkInOutLabel}>
            <Trans>Check-in Time</Trans>
          </Body>
          <Body
            style={styles.checkInOutValue}
            testProps={{
              elementId: "checkInTime",
              elementName: "Activity",
              screenName: route.name,
            }}
          >
            {getFormattedString(new Date(activity.checkinTime), activity.timezone)}
          </Body>
        </View>
        <View
          style={styles.checkOutContainer}
          {...getTestProps({
            elementId: "checkOut",
            elementName: "Activity",
          })}
        >
          <Body style={styles.checkInOutLabel}>
            <Trans>Check-out Time</Trans>
          </Body>
          <Body
            style={styles.checkInOutValue}
            testProps={{
              elementId: "checkOutTime",
              elementName: "Activity",
              screenName: route.name,
            }}
          >
            {checkoutTimeString}
          </Body>
        </View>
      </View>
      <View
        style={styles.detailsContainer}
        {...getTestProps({
          elementId: "details",
          elementName: "Activity",
        })}
      >
        {activityCheckinLocation !== undefined &&
          activityCheckinLocation !== null &&
          activityCheckinLocation !== INDETERMINABLE_LOCATION && (
            // TODO: confirm with data if indeterminable locations will have string "Unable to determine location"
            <View style={styles.detailRow}>
              <View style={styles.detailIconWrapper}>
                <Icon name="locationMediumAction" />
              </View>
              <Body
                testProps={{
                  elementId: "checkInLocation",
                  elementName: "Activity",
                  screenName: route.name,
                }}
              >
                <Trans>Check-in Location: {activityCheckinLocation}</Trans>
              </Body>
            </View>
          )}
        {activityCheckoutLocation !== undefined && activityCheckoutLocation !== null && (
          <View style={styles.detailRow}>
            <View style={styles.detailIconWrapper}>
              <Icon name="checkoutMediumAction" />
            </View>
            <Body
              testProps={{
                elementId: "checkOutLocation",
                elementName: "Activity",
                screenName: route.name,
              }}
            >
              <Trans>Check-out Location: {activityCheckoutLocation}</Trans>
            </Body>
          </View>
        )}
        {activityDuration !== undefined && activityDuration !== null && (
          <View style={styles.detailRow}>
            <View style={styles.detailIconWrapper}>
              <Icon name="clockMediumAction" />
            </View>
            <Body
              testProps={{
                elementId: "duration",
                elementName: "Activity",
                screenName: route.name,
              }}
            >
              <Trans>
                Duration:{" "}
                {getDurationStringFromRFC3339Duration(activityDuration) ?? activityDuration}
              </Trans>
            </Body>
          </View>
        )}
      </View>
    </View>
  );
};
