import { Log } from "@src/utils";
import { isAdminMerit, useMerit } from "@src/api/issuance";
import { useEffect } from "react";
import { useLogoutOnError, useMeritAuth0 } from "@src/hooks";
import { useMemberApi } from "./useMemberApi";
import { useQuery } from "@tanstack/react-query";
import type { AdminMerit } from "../issuance/types";
import type { Folio, Merit } from "@src/api/issuance";

type QueryKey = readonly [
  "accountFolioFromAdminMerit",
  { readonly adminMeritId: AdminMerit["id"]; readonly idToken: string }
];

export const useAccountFolioFromAdminMeritQueryKey = (adminMeritId: Merit["id"]): QueryKey => {
  const { idToken } = useMeritAuth0();

  return ["accountFolioFromAdminMerit", { adminMeritId, idToken }];
};

export const useAccountFolioFromAdminMerit = (adminMeritId: Merit["id"]) => {
  const { api: memberApi, isReady } = useMemberApi();
  const queryKey = useAccountFolioFromAdminMeritQueryKey(adminMeritId);
  const { onError } = useLogoutOnError();

  const merit = useMerit(adminMeritId);
  if (merit === undefined || !isAdminMerit(merit)) {
    Log.error("Container ID passed does not correspond with an admin merit", {
      containerId: adminMeritId,
    });
  }

  const query = useQuery({
    enabled: isReady,
    queryFn: async ctx => {
      const key = ctx.queryKey[1];

      const folio = await memberApi.getAccountFolioFromAdminMerit({
        adminMeritId: key.adminMeritId,
        xIDToken: key.idToken,
      });

      return folio as Folio;
    },
    queryKey,
  });

  useEffect(() => {
    if (query.isError) {
      onError(query.error);
    }
  }, [query.isError, query.error, onError]);

  return query;
};
