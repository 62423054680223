import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAlerts } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMFAApi } from "./useMFAApi";
import { useMutation } from "@tanstack/react-query";

export const useDeleteMFAEnrollments = () => {
  const { api } = useMFAApi();
  const { sendAlert } = useAlerts();
  const { _ } = useLingui();

  return useMutation({
    mutationFn: () => api.deleteMFAEnrollments(),
    onError: err => {
      Log.error(`Error deleting MFA enrollments`, { error: err });
      sendAlert({
        id: "deleteMFAEnrollments-error",
        text1: _(msg`Something went wrong`),
        text2: _(msg`Error removing multi-factor authentication`),
        type: "error",
      });
    },
    onSuccess: () => {
      sendAlert({
        id: "deleteMFAEnrollments-success",
        text1: _(msg`Success`),
        text2: _(msg`You successfully removed your account's multi-factor authentication`),
        type: "success",
      });
    },
  });
};
