import { createIconSetFromIcoMoon } from "react-native-vector-icons";
import Icons from "./icons.json";
import type { ComponentType } from "react";
import type { IconName } from "./IconName";
import type { IconProps, Icon as RNVIIcon } from "react-native-vector-icons/Icon";

// RNVIIcon extends a class component parameterized by IconProps and adds several methods. The
// following attempts to clone the type of RNVIIcon and replace the name property with a stricter
// type.
//
// Replace {name: string} with {name: IconName} in IconProps
// Create class component type with props `A`
// Pick non-component properties from RNVIIcon
// Merge class component and picked RNVIIcon
type A = Omit<IconProps, "name"> & { readonly name: IconName };
type B = ComponentType<A>;
type C = Pick<
  typeof RNVIIcon,
  | "getFontFamily"
  | "getImageSource"
  | "getImageSourceSync"
  | "getRawGlyphMap"
  | "hasIcon"
  | "loadFont"
>;
type IconWithTypedName = B & C;

export const Icon = createIconSetFromIcoMoon(Icons) as IconWithTypedName;
