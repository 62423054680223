/**
 * Simple function to get the language code from a given language tag.
 *
 * The language code from a tag is always the first set of characters before the first hyphen,
 * or the tag itself if no extensions are added. For example, these are all English language
 * code bearing tags: en, en-US, en-GB-oed, en-scouse.
 * More reading: https://en.wikipedia.org/wiki/IETF_language_tag#Syntax_of_language_tags
 */
export const getLanguageCode = (languageTagString: string): string => {
  const match = /^([A-Za-z]+)-?/iu.exec(languageTagString);
  const code = match?.[1];

  return code === undefined ? "und" : code;
};
