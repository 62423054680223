import { SafeAreaView } from "react-native-safe-area-context";
import { ScrollView, StyleSheet, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { OnboardingMeritsLayoutProps } from "./types";

const OnboardingMeritsLayoutMobile = ({
  body,
  footer,
  heading,
  pageHeaderLeft,
  pageHeaderRight,
}: OnboardingMeritsLayoutProps) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    bodyContainer: {
      flex: 1,
    },
    contentContainer: {
      flex: 1,
      padding: theme.spacing.l,
    },
    pageContainer: {
      backgroundColor: theme.colors.background.default,
      flex: 1,
    },
    pageHeadingContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: theme.spacing.l,
    },
    scrollContainer: {
      flex: 1,
    },
  });

  return (
    <SafeAreaView style={styles.pageContainer}>
      <View style={styles.scrollContainer}>
        <ScrollView>
          <View style={styles.contentContainer}>
            <View style={styles.pageHeadingContainer}>
              <View>{pageHeaderLeft}</View>
              <View>{pageHeaderRight}</View>
            </View>
            {heading}
            <View style={styles.bodyContainer}>{body}</View>
          </View>
        </ScrollView>
        {footer}
      </View>
    </SafeAreaView>
  );
};

export { OnboardingMeritsLayoutMobile };
