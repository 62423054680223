import { Heading, useTheme } from "@merit/frontend-components";
import { Pressable, StyleSheet, View } from "react-native";
import { useGetTestProps, useRoute } from "@src/hooks";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import type { HeadingProps } from "@merit/frontend-components";
import type { ReactElement } from "react";
import type { TextStyle, ViewStyle } from "react-native";

export type HeaderProps = {
  readonly containerStyle?: ViewStyle;
  readonly leftElement?: ReactElement | null;
  readonly rightElement?: ReactElement | null;
  // when provided, naively wraps the left/right components in a <Pressable />, may want to omit for custom components
  readonly leftOnPress?: () => void;
  readonly rightOnPress?: () => void;
  readonly title?: string;
  readonly titleBold?: boolean;
  readonly titleContainerStyle?: ViewStyle;
  readonly titleLevel?: HeadingProps["level"];
};

// need to have left/right components of header match width to properly center title
const GUTTER_ELEMENT_WIDTH = 32;

// Dear developer: please check if there's a specific Header implementation for your needs first
export const Header = ({
  containerStyle = {},
  leftElement,
  leftOnPress,
  rightElement,
  rightOnPress,
  title = "",
  titleBold = false,
  titleContainerStyle = {},
  titleLevel = "1",
}: HeaderProps) => {
  const getTestProps = useGetTestProps();
  const route = useRoute();
  const { top } = useSafeAreaInsets();

  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly leftContainer: ViewStyle;
    readonly rightContainer: ViewStyle;
    readonly titleContainer: ViewStyle;
    readonly titleText: TextStyle;
  }>({
    container: {
      flexDirection: "row",
      justifyContent: "space-between",
      padding: theme.spacing.l,
      paddingTop: theme.spacing.l + top,
      zIndex: 20,
    },
    leftContainer: {
      alignItems: "flex-start",
      justifyContent: "center",
      width: GUTTER_ELEMENT_WIDTH,
    },
    rightContainer: {
      alignItems: "flex-end",
      justifyContent: "center",
      width: GUTTER_ELEMENT_WIDTH,
    },
    titleContainer: { flex: 1 },
    titleText: { textAlign: "center" },
  });

  const nonPressableLeftElement = Boolean(leftElement) ? (
    <View style={styles.leftContainer}>{leftElement}</View>
  ) : null;

  return (
    <View style={[styles.container, containerStyle]} {...getTestProps({ elementName: "Header" })}>
      {leftOnPress !== undefined && Boolean(leftElement) ? (
        <Pressable
          onPress={leftOnPress}
          style={styles.leftContainer}
          {...getTestProps({
            elementId: "leftPressable",
            elementName: "Header",
          })}
        >
          {leftElement}
        </Pressable>
      ) : (
        nonPressableLeftElement
      )}
      <View style={[styles.titleContainer, titleContainerStyle]}>
        <Heading
          bold={titleBold}
          level={titleLevel}
          style={styles.titleText}
          testProps={{ elementId: "title", elementName: "Header", screenName: route.name }}
        >
          {title}
        </Heading>
      </View>
      {rightOnPress !== undefined && Boolean(rightElement) ? (
        <Pressable
          onPress={rightOnPress}
          style={styles.rightContainer}
          {...getTestProps({ elementId: "rightPressable", elementName: "Header" })}
        >
          {rightElement}
        </Pressable>
      ) : (
        <View style={styles.rightContainer}>{rightElement}</View>
      )}
    </View>
  );
};
