import { EmailList } from "./EmailList";
import { EmailListDesktop } from "./EmailListDesktop";
import { useLayoutType } from "@src/hooks";
import type { EmailListProps } from "./types";

const LayoutEmailList = ({ ...props }: EmailListProps) => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <EmailListDesktop {...props} />;
  }

  return <EmailList {...props} />;
};

export { LayoutEmailList as EmailList };
