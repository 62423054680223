// Return skeleton styles
//
// Example:
//
//    const MySkelton = () => {
//      const skeletonStyles = useSkeleton()
//
//      return <Animated.View style={skeletonStyles} />
//    }

/* eslint-disable functional/immutable-data */

// The RN Gesture Handler API requires modifying objects
//
// Example:
//
//    `x.value = startingValue + event.translationX`
//
// https://docs.swmansion.com/react-native-gesture-handler/docs/quickstart/quickstart

import { useAnimatedStyle, useSharedValue, withRepeat, withTiming } from "react-native-reanimated";
import { useEffect } from "react";
import { useTheme } from "@merit/frontend-components";
import type { ColorValue } from "react-native";

export const useSkeleton = (color?: ColorValue | undefined) => {
  const { theme } = useTheme();

  const opacity = useSharedValue(0.1);

  const animatedStyles = useAnimatedStyle(() => ({
    backgroundColor: color === undefined ? theme.colors.surface.pressed : color,
    opacity: opacity.value,
  }));

  useEffect(() => {
    opacity.value = withRepeat(withTiming(1, { duration: 1000 }), -1, true);
  }, [opacity]);

  return animatedStyles;
};
