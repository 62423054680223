import { useApprovePolicyRequestMutationKey } from "@src/api/issuance/useApprovePolicyRequest";
import { useMemo } from "react";
import { useMutationState } from "@tanstack/react-query";
import type { MutationState } from "@tanstack/react-query";
import type { PolicyRequest } from "@src/api/issuance";

type UseApprovePolicyRequestMutationState = (
  policyRequestId: PolicyRequest["id"]
) => MutationState<PolicyRequest> | undefined;

export const useApprovePolicyRequestMutationState: UseApprovePolicyRequestMutationState =
  policyRequestId => {
    const mutationKey = useApprovePolicyRequestMutationKey({ policyRequestId });

    const approvePolicyRequestMutations = useMutationState<MutationState<PolicyRequest>>({
      filters: {
        exact: true,
        mutationKey,
        status: "success",
      },
    });

    const approvePolicyRequestMutation = useMemo(
      () => approvePolicyRequestMutations.at(-1),
      [approvePolicyRequestMutations]
    );

    return approvePolicyRequestMutation;
  };
