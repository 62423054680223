import { ActivityIndicator, StyleSheet, View, useWindowDimensions } from "react-native";
import { Heading, useTheme } from "@merit/frontend-components";
import { Trans } from "@lingui/macro";
import { useAccountFolioFromAdminMerit } from "@src/api/person-experience-backend/useAccountFolioFromAdminMerit";
import { useEffect } from "react";
import type { Folio } from "@src/api/issuance/types";
import type { Merit } from "@src/api/issuance";
import type { TextStyle, ViewStyle } from "react-native";

export type OnboardingMeritsFolioLoadingProps = {
  readonly adminMeritId: Merit["id"];
  readonly onGetOrgAccountFolio: (
    id: Folio["id"],
    state: Folio["state"],
    adminMeritId: Merit["id"]
  ) => void;
  readonly onError: (error: unknown, adminMeritId: Merit["id"]) => void;
};

export const OnboardingMeritsFolioLoading = ({
  adminMeritId,
  onError,
  onGetOrgAccountFolio,
}: OnboardingMeritsFolioLoadingProps) => {
  const { height } = useWindowDimensions();
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly heading: TextStyle;
  }>({
    container: {
      alignItems: "center",
      alignSelf: "stretch",
      height: (height / 4) * 3, // not ideal, this serves as an approximation of vertical centering without flex: 1 due to a ScrollView ancestor
      justifyContent: "center",
      padding: theme.spacing.xxl,
    },
    heading: {
      lineHeight: 40,
      marginBottom: theme.spacing.l,
      maxWidth: 440,
      textAlign: "center",
    },
  });

  const { data, error, isError, isFetched } = useAccountFolioFromAdminMerit(adminMeritId);

  useEffect(() => {
    if (!isFetched) {
      return;
    }

    if (isError) {
      onError(error, adminMeritId);

      return;
    }

    // this if-block more to appease typescript at this point, used to be actual safety for the fetcher returning no data
    // nowadays though the getKey and fetcher funcs are written in a way that its guaranteed data xor error is defined after the first fetch
    if (data === undefined) {
      onError(
        // usage of the error message is not user facing, plus reasons stated above, so disable the lint rule here
        // eslint-disable-next-line lingui/no-unlocalized-strings
        new Error(`No data returned for account folio of admin merit id ${adminMeritId}`),
        adminMeritId
      );

      return;
    }

    onGetOrgAccountFolio(data.id, data.state, adminMeritId);
  }, [adminMeritId, data, error, isFetched, onError, onGetOrgAccountFolio, isError]);

  return (
    <View style={styles.container}>
      <Heading bold level="2" style={styles.heading}>
        <Trans>
          Stand by.{"\n"}
          We're loading your personal digital wallet where you can manage, store, and utilize your
          merits.
        </Trans>
      </Heading>
      <ActivityIndicator size="large" />
    </View>
  );
};
