import { Body, LineSeparator, TextInput, useTheme } from "@merit/frontend-components";
import { FlatList, RefreshControl, StyleSheet, View } from "react-native";
import { Log, byNameThenOrg, containsText, hasState } from "@src/utils";
import { MeritListItem } from "@src/components";
import { Skeleton } from "./Skeleton";
import { Trans, msg } from "@lingui/macro";
import { range } from "lodash";
import { useGetTestProps, useNavigation, useRoute } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMemo, useState } from "react";
import { useMerits } from "@src/api/issuance";
import type { ViewStyle } from "react-native";

export const SearchableMeritsList = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly flex: ViewStyle;
    readonly horizontalMargin: ViewStyle;
    readonly bottomMargin: ViewStyle;
    readonly searchMerits: ViewStyle;
  }>({
    bottomMargin: {
      marginBottom: theme.spacing.l,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      paddingTop: theme.spacing.l,
    },
    flex: { flex: 1 },
    horizontalMargin: {
      marginLeft: theme.spacing.xxl,
      marginRight: theme.spacing.xxl,
    },
    searchMerits: {
      backgroundColor: theme.colors.background.default,
      marginBottom: theme.spacing.l,
    },
  });

  const getTestProps = useGetTestProps();
  const navigation = useNavigation();
  const route = useRoute();
  const [searchText, setSearchText] = useState("");
  const { data: merits, error, isError, isRefetching, refetch } = useMerits();
  const { _ } = useLingui();

  const displayedMerits = useMemo(
    () =>
      merits
        ?.filter(hasState(["accepted", "pending"]))
        .filter(containsText(searchText))
        .sort(byNameThenOrg) ?? [],
    [merits, searchText]
  );

  const handleChange = (text: string) => {
    setSearchText(text);
  };

  if (isError) {
    Log.error(`Error loading merits: ${String(error)}`);

    return (
      <View style={[styles.flex, styles.container]}>
        <Body style={styles.horizontalMargin}>
          <Trans>Error loading your merits</Trans>
        </Body>
      </View>
    );
  }

  if (merits === undefined) {
    return (
      <View style={[styles.flex, styles.container]}>
        {range(5).map((item, index) => (
          <Skeleton key={item} showTopSeparator={index !== 0} />
        ))}
      </View>
    );
  }

  return (
    <View
      style={[styles.flex, styles.container]}
      {...getTestProps({
        elementName: "SearchableMeritsList",
      })}
    >
      <View style={[styles.horizontalMargin, styles.searchMerits]}>
        <TextInput
          leftIcon="searchSmallSubdued"
          onChangeText={handleChange}
          placeholder={_(msg({ context: "placeholder", message: "Search Merits" }))}
          testProps={{
            elementId: "searchMerits",
            elementName: "SearchableMeritsList",
            screenName: route.name,
          }}
        />
      </View>
      <LineSeparator style={styles.bottomMargin} />
      <View style={[styles.bottomMargin, styles.horizontalMargin]}>
        <Body>
          <Trans context="sort">A-Z</Trans>
        </Body>
      </View>
      <LineSeparator style={styles.bottomMargin} />
      <View style={[styles.horizontalMargin, styles.flex]}>
        <FlatList
          ItemSeparatorComponent={() => <LineSeparator />}
          data={displayedMerits}
          refreshControl={
            <RefreshControl
              onRefresh={() => {
                refetch();
              }}
              refreshing={isRefetching}
            />
          }
          renderItem={({ item }) => (
            <MeritListItem
              merit={item}
              onPress={() => {
                navigation.navigate("MeritDetails", { meritId: item.id });
              }}
            />
          )}
          {...getTestProps({
            elementId: "merits",
            elementName: "SearchableMeritsList",
          })}
        />
      </View>
    </View>
  );
};
