import { isAccountMerit } from "./utils";
import { isActive } from "@src/utils/merits";
import { useMemo } from "react";
import { useMerits } from "./useMerits";
import type { AccountMerit } from "./types";
import type { UseQueryResult } from "@tanstack/react-query";

export type UseAccountMerit = () => UseQueryResult<AccountMerit>;

// This should be fine. We're only updating data, and we verify whether the account merit exists in
// useMerits. So it should be consistent with errors there.
//
// @ts-expect-error something
export const useAccountMerit: UseAccountMerit = () => {
  const response = useMerits();

  const accountMerit = useMemo(
    () => response.data?.filter(isActive(true)).find(isAccountMerit),
    [response.data]
  );

  return {
    ...response,
    data: accountMerit,
  };
};
