/* eslint-disable @typescript-eslint/require-await, require-await */

import { Configuration as ConfigurationCls, PolicyRequestsApi } from "@merit/issuance-client";
import { getLocalConfig } from "@src/utils";
import { useFeatureFlags, useMeritAuth0 } from "@src/hooks";

const config = getLocalConfig();

type UsePolicyRequestsAPIReturn = {
  readonly api: PolicyRequestsApi;
  readonly isReady: boolean;
  readonly isMock: boolean;
};

const usePolicyRequestsApi = (): UsePolicyRequestsAPIReturn => {
  const { accessToken } = useMeritAuth0();
  const featureFlags = useFeatureFlags();

  if (featureFlags.showMemberAppVerifications !== true) {
    return {
      api: {
        getPolicyRequests: async () => [],
      } as unknown as PolicyRequestsApi,
      isMock: true,
      isReady: true,
    };
  }

  return {
    api: new PolicyRequestsApi(
      new ConfigurationCls({
        ...config.api.issuance,
        headers: {
          ...config.api.issuance.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ),
    isMock: false,
    isReady: accessToken !== "",
  };
};

export { usePolicyRequestsApi };
