/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable consistent-return */

import Constants from "expo-constants";
import type { Configuration } from "@src/configuration";

type GetLocalConfig = () => Configuration;

export const getLocalConfig: GetLocalConfig = () => Constants.expoConfig?.extra as Configuration;
