import { getContainerKind } from "./getContainerKind";
import type { Configuration } from "@src/configuration";
import type { Container } from "@merit/issuance-client";
import type { ExtendedContainer } from "../types/ExtendedContainer";

type WithContainerKind = (
  container: Container,
  config: Configuration
) => PickPartial<ExtendedContainer, "fieldMap" | "transformedFields">;

export const withContainerKind: WithContainerKind = (container, config) => ({
  ...container,
  kind: getContainerKind(container, config),
});
