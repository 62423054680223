import { Card } from "@src/components";
import { ConnectedAppButton } from "./ConnectedAppButton";
import { Heading, Icon, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { useConnectedApps } from "./useConnectedApps";
import { useGetTestProps } from "@src/hooks";

export const ConnectedAppsMobile = () => {
  const { apps } = useConnectedApps();
  const { theme } = useTheme();
  const getTestProps = useGetTestProps();

  const styles = StyleSheet.create({
    buttonContainer: {
      marginBottom: theme.spacing.s,
      marginRight: theme.spacing.xl,
    },
    buttonsContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
    container: {
      padding: theme.spacing.xl,
    },
    title: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: theme.spacing.m,
    },
  });

  if (apps.length === 0) {
    return null;
  }

  return (
    <Card elevation={4} style={styles.container}>
      <View style={styles.title}>
        <Heading
          bold
          level="2"
          {...getTestProps({
            elementId: "heading",
            elementName: "ConnectedApps",
          })}
        >
          <Trans context="title">Connected Apps</Trans>
        </Heading>
        <Icon name="programmingModuleLargeAction" />
      </View>
      <View style={styles.buttonsContainer}>
        {apps.map(app => (
          <View key={app.id} style={styles.buttonContainer}>
            <ConnectedAppButton {...app} />
          </View>
        ))}
      </View>
    </Card>
  );
};
