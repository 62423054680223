import { AdminMeritOrgAccountFolioDetails, Card } from "@src/components";
import { StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";

type OnboardingMeritsFolioBodyProps = {
  readonly adminMeritId: string;
};

const OnboardingMeritsFolioBody = ({ adminMeritId }: OnboardingMeritsFolioBodyProps) => {
  const getTestProps = useGetTestProps();
  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
    },
    detailsWrapper: {
      flex: 1,
    },
  });

  return (
    <View style={styles.container}>
      <Card
        elevation={3}
        style={styles.detailsWrapper}
        {...getTestProps({ elementId: "folioDetails", elementName: "OnboardingMeritsFolioBody" })}
      >
        <AdminMeritOrgAccountFolioDetails adminMeritId={adminMeritId} />
      </Card>
    </View>
  );
};

export { OnboardingMeritsFolioBody };
