import { toAccountMerit } from "./toAccountMerit";
import { toAdminMerit } from "./toAdminMerit";
import { toNormalMerit } from "./toNormalMerit";
import type { AccountMerit } from "../types/AccountMerit";
import type { AdminMerit } from "../types/AdminMerit";
import type { NormalMerit } from "../types/NormalMerit";

type PartialAccountMerit = PickPartial<AccountMerit, "transformedFields">;
type PartialAdminMerit = PickPartial<AdminMerit, "transformedFields">;
type PartialNormalMerit = PickPartial<NormalMerit, "transformedFields">;
type PartialMerit = PartialAccountMerit | PartialAdminMerit | PartialNormalMerit;

export function toMerit(merit: PartialAccountMerit): PartialAccountMerit;
export function toMerit(merit: PartialAdminMerit): PartialAdminMerit;
export function toMerit(merit: PartialNormalMerit): PartialNormalMerit;
export function toMerit(merit: PartialMerit): PartialMerit;
export function toMerit(merit: PartialMerit): PartialMerit {
  if (merit.kind === "accountMerit") {
    return toAccountMerit(merit);
  }

  if (merit.kind === "adminMerit") {
    return toAdminMerit(merit);
  }

  return toNormalMerit(merit);
}
