import { useCallback } from "react";
import { useLogout } from "./auth";
import type { ResponseError } from "@merit/issuance-client";

type UseLogoutOnError = () => {
  readonly onError: (error: unknown) => void;
};

export const useLogoutOnError: UseLogoutOnError = () => {
  const logout = useLogout();

  const onError = useCallback(
    (error: unknown) => {
      if ((error as Partial<ResponseError> | undefined)?.response?.status === 401) {
        logout();
      }
    },
    [logout]
  );

  return { onError };
};
