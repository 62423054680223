import { IconSkeleton, TextSkeleton } from "@src/components";
import { PolicyRequestDetailsLayout } from "./PolicyRequestDetailsLayout";
import { StyleSheet, View } from "react-native";
import { useTheme } from "@merit/frontend-components";
import range from "lodash.range";

export const PolicyRequestDetailsSkeleton = () => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    descriptionText: {
      width: "100%",
    },
    fieldNameText: {
      marginLeft: theme.spacing.xl,
      width: "50%",
    },
    headerText: {
      width: "80%",
    },
    orgIcon: {
      height: 40,
      width: 40,
    },
    templateNameText: {
      width: "50%",
    },
  });

  return (
    <PolicyRequestDetailsLayout
      body={
        <>
          <TextSkeleton style={styles.descriptionText} />
          <View>
            <TextSkeleton style={styles.templateNameText} />
            <TextSkeleton style={styles.templateNameText} />
            {range(2).map(item => (
              <TextSkeleton key={item} style={styles.fieldNameText} />
            ))}
          </View>
        </>
      }
      footer={<></>}
      header={
        <>
          <IconSkeleton style={styles.orgIcon} />
          <TextSkeleton style={styles.headerText} />
        </>
      }
    />
  );
};
