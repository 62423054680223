import { Body, Button, Heading, Icon, LineSeparator, useTheme } from "@merit/frontend-components";
import { HELP } from "@src/utils/constants/urls";
import { LoggedInDrawerItem } from "./LoggedInDrawerItem";
import { StyleSheet, View } from "react-native";
import { getPrimaryEmailFromContainerFields, openExternalLink } from "@src/utils";
import { msg } from "@lingui/macro";
import { nativeApplicationVersion } from "expo-application";
import { useAccountMerit } from "@src/api/issuance";
import { useFeatureFlags, useGetTestProps, useLogout, useNavigation, useRoute } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Constants from "expo-constants";
import type { DrawerContentComponentProps } from "@react-navigation/drawer";
import type { ImageStyle, ViewStyle } from "react-native";

const handleHelpOnPress = () => {
  openExternalLink(HELP);
};

export const LoggedInDrawerContent = ({}: DrawerContentComponentProps) => {
  const { data: accountMerit } = useAccountMerit();
  const getTestProps = useGetTestProps();
  const route = useRoute();
  const logout = useLogout();
  const { bottom: bottomSafeInset, top: topSafeInset } = useSafeAreaInsets();
  const { theme } = useTheme();
  const { navigate } = useNavigation();
  const featureFlags = useFeatureFlags();
  const { _ } = useLingui();

  // We're okay to return null here as they will not be logged in without an account merit
  if (accountMerit?.fields === undefined) {
    return null;
  }

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly bottomContainer: ViewStyle;
    readonly imageContainer: ViewStyle;
    readonly image: ImageStyle;
    readonly bottomSpace: ViewStyle;
  }>({
    bottomContainer: {
      marginBottom: Math.max(bottomSafeInset, theme.spacing.l),
      marginHorizontal: theme.spacing.xxl,
    },
    bottomSpace: {
      marginBottom: theme.spacing.l,
    },
    container: {
      flex: 1,
      paddingBottom: Math.max(bottomSafeInset, theme.spacing.l),
      paddingHorizontal: theme.spacing.xxl,
      paddingTop: Math.max(topSafeInset, theme.spacing.xxl),
    },
    image: {
      height: "100%",
      width: "100%",
    },
    imageContainer: {
      alignItems: "center",
      display: "flex",
      height: 104,
      marginBottom: theme.spacing.l,
      width: 104,
    },
  });

  const version = nativeApplicationVersion ?? Constants.expoConfig?.version ?? "";
  const versionText = version === "" ? "" : `v${version}`;

  // TODO: Profile image should display here once we have it
  return (
    <>
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <Icon
            name="avatarCircleLarge"
            style={styles.image}
            {...getTestProps({
              elementId: "profileIcon",
              elementName: "LoggedInDrawerContent",
            })}
          />
        </View>
        <Heading
          bold
          level="3"
          numberOfLines={2}
          testProps={{
            elementId: "fullName",
            elementName: "LoggedInDrawerContent",
            screenName: route.name,
          }}
        >
          {accountMerit.transformedFields.recipientFullName}
        </Heading>
        <Body
          style={styles.bottomSpace}
          testProps={{
            elementId: "primaryEmail",
            elementName: "LoggedInDrawerContent",
            screenName: route.name,
          }}
        >
          {getPrimaryEmailFromContainerFields(accountMerit.fields)}
        </Body>
        <LineSeparator />
        <LoggedInDrawerItem
          elementId="help"
          iconName="helpOutlinedMediumDefault"
          onPress={handleHelpOnPress}
          text={_(msg({ context: "navigation item", message: "Help" }))}
        />
        {featureFlags.showSettings === true ? (
          <>
            <LineSeparator />
            <LoggedInDrawerItem
              elementId="settings"
              iconName="gearOutlinedLarge"
              onPress={() => {
                navigate("Settings");
              }}
              text={_(msg({ context: "navigation item", message: "Settings" }))}
            />
          </>
        ) : null}
        <LineSeparator style={styles.bottomSpace} />
        <Body
          size="l"
          testProps={{
            elementId: "appVersion",
            elementName: "LoggedInDrawerContent",
            screenName: route.name,
          }}
        >
          {versionText}
        </Body>
      </View>
      <View style={styles.bottomContainer}>
        <Button
          onPress={logout}
          size="small"
          testProps={{
            elementId: "logout",
            elementName: "LoggedInDrawerContent",
            screenName: route.name,
          }}
          text={_(msg({ context: "action", message: "Log Out" }))}
          type="secondary"
        />
      </View>
    </>
  );
};
