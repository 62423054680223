import { Body, Icon, useTheme } from "@merit/frontend-components";
import { Pressable, StyleSheet, View } from "react-native";
import { offset, shift, useFloating } from "@floating-ui/react-native";
import { useGetTestProps, useRoute } from "@src/hooks";
import { useState } from "react";
import type { CommonProps } from "../types";
import type { ReactNode } from "react";
import type { TextStyle, ViewStyle } from "react-native";

type MenuItem = CommonProps & {
  readonly onPress: () => void;
  readonly icon?: ReactNode;
  readonly label: string;
  readonly disabled?: boolean;
  readonly type?: "default" | "destructive";
};

type DropdownMenuButtonProps = CommonProps & {
  readonly menuItems: readonly MenuItem[];
  readonly width?: number;
};

const MENU_ITEM_ICON_CONTAINER_SIZE = 20; // TODO: we should have a standard size for "regular" icons for consistency

export const DropdownMenuButton = ({
  elementId,
  menuItems,
  width = 200,
}: DropdownMenuButtonProps) => {
  const getTestProps = useGetTestProps();
  const route = useRoute();
  const { theme } = useTheme();

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const {
    refs: { floating, reference },
    x,
    y,
  } = useFloating({
    middleware: [offset(theme.spacing.m), shift()],
    placement: "bottom",
  });

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly floatingMenuContainer: ViewStyle;
    readonly menuItemBottomSpacer: ViewStyle;
    readonly menuItemContainer: ViewStyle;
    readonly menuItemIconContainer: ViewStyle;
    readonly menuItemLabel: ViewStyle;
    readonly menuItemLabelTypeDestructive: TextStyle;
    readonly menuItemLabelDisabled: TextStyle;
  }>({
    container: {
      backgroundColor: "transparent", // required on Android, there's a bug with the lib passing NaN otherwise
    },
    floatingMenuContainer: {
      ...theme.elevations.depth3,
      backgroundColor: theme.colors.background.white,
      left: x,
      opacity: isMenuVisible ? 1 : 0,
      padding: theme.spacing.l,
      position: "absolute",
      top: y,
      // TODO: dynamically calculate width based on elements?
      width,
    },
    menuItemBottomSpacer: {
      marginBottom: theme.spacing.l,
    },
    menuItemContainer: {
      alignItems: "center",
      flexDirection: "row",
    },
    menuItemIconContainer: {
      height: MENU_ITEM_ICON_CONTAINER_SIZE,
      marginRight: theme.spacing.s,
      width: MENU_ITEM_ICON_CONTAINER_SIZE,
    },
    menuItemLabel: {
      flex: 1,
    },
    menuItemLabelDisabled: {
      color: theme.colors.text.disabled,
    },
    menuItemLabelTypeDestructive: {
      color: theme.colors.text.alert.critical,
    },
  });

  return (
    <>
      <Pressable
        hitSlop={8}
        onPress={() => {
          setIsMenuVisible(prevValue => !prevValue);
        }}
        {...getTestProps({
          elementId,
          elementName: "DropdownMenuButton",
        })}
      >
        <View ref={reference} style={styles.container}>
          <Icon name="chevronDownCircleLargeDefault" />
        </View>
      </Pressable>
      <View ref={floating} style={styles.floatingMenuContainer}>
        {/* TODO: these rendered items should be their own component */}
        {menuItems.map((menuItem, index, arr) => (
          <Pressable
            disabled={menuItem.disabled}
            key={menuItem.label}
            onPress={() => {
              menuItem.onPress();
              setIsMenuVisible(false);
            }}
            style={[
              styles.menuItemContainer,
              index < arr.length - 1 ? styles.menuItemBottomSpacer : null,
            ]}
            {...getTestProps({
              elementId: menuItem.elementId,
              elementName: "DropdownMenuItem",
            })}
          >
            {menuItem.icon === undefined ? null : (
              <View style={styles.menuItemIconContainer}>{menuItem.icon}</View>
            )}
            <Body
              style={[
                styles.menuItemLabel,
                menuItem.type === "destructive" ? styles.menuItemLabelTypeDestructive : null,
                Boolean(menuItem.disabled) ? styles.menuItemLabelDisabled : null,
              ]}
              testProps={{
                elementId: "body",
                elementName: "DropdownMenuItem",
                screenName: route.name,
              }}
            >
              {menuItem.label}
            </Body>
          </Pressable>
        ))}
      </View>
    </>
  );
};
