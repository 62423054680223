import { Button, Modal, useTheme } from "@merit/frontend-components";
import { Constants } from "@src/utils";
import { StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import { useState } from "react";
import type { IconName } from "@merit/frontend-components";
import type { ReactNode } from "react";
import type { ViewStyle } from "react-native";

type DialogProps = {
  readonly title?: string;
  readonly titleIconName?: IconName;
  readonly onClose: () => Promise<void> | void;
  readonly content?: ReactNode;
  readonly cancelButtonProps?: {
    readonly text: string;
  };
  readonly actionButtonProps?: {
    readonly text: string;
    readonly onPress: () => Promise<void> | void;
    readonly type: "destructive" | "primary";
  };
};

export const Dialog = ({
  actionButtonProps,
  cancelButtonProps,
  content,
  onClose,
  title,
  titleIconName,
}: DialogProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly buttonsContainer: ViewStyle;
    readonly contentContainer: ViewStyle;
  }>({
    buttonsContainer: {
      flexDirection: "row",
      gap: theme.spacing.s,
      justifyContent: "flex-end",
    },
    contentContainer: {
      marginBottom: theme.spacing.l,
    },
  });

  const [isActionInFlight, setIsActionInFlight] = useState(false);
  const getTestProps = useGetTestProps();

  return (
    <Modal
      maxWidth={Constants.Sizes.MODAL}
      onClose={() => {
        onClose();
      }}
      title={title}
      titleIconName={titleIconName}
      width="100%"
      {...getTestProps({ elementName: "Dialog" })}
    >
      <View style={styles.contentContainer}>{content}</View>
      <View style={styles.buttonsContainer}>
        {cancelButtonProps !== undefined && (
          <Button
            onPress={onClose}
            type="secondary"
            {...cancelButtonProps}
            {...getTestProps({ elementId: "cancelButton", elementName: "Dialog" })}
          />
        )}
        {actionButtonProps !== undefined && (
          <Button
            {...actionButtonProps}
            disabled={isActionInFlight}
            onPress={async () => {
              // wrap the onPress so we can disable the button while request is in flight, if one exists
              setIsActionInFlight(true);
              await actionButtonProps.onPress();
              setIsActionInFlight(false);
            }}
            {...getTestProps({ elementId: "actionButton", elementName: "Dialog" })}
          />
        )}
      </View>
    </Modal>
  );
};
