import { Body, Heading, Icon, LineSeparator, useTheme } from "@merit/frontend-components";
import { Card } from "@src/components";
import { MeritsList } from "../MeritsList";
import { Pressable, StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { useGetTestProps, useNavigation, useRoute } from "@src/hooks";
import type { TextStyle, ViewStyle } from "react-native";
import type { VFC } from "react";

export const MyMerits: VFC = () => {
  const route = useRoute();
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly marginBottom: ViewStyle;
    readonly title: ViewStyle;
    readonly viewMoreText: TextStyle;
  }>({
    container: {
      marginBottom: theme.spacing.l,
      padding: theme.spacing.xl,
    },
    marginBottom: {
      marginBottom: theme.spacing.m,
    },
    title: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    viewMoreText: {
      fontWeight: theme.fontWeights.semiBold,
    },
  });

  const getTestProps = useGetTestProps();
  const navigation = useNavigation();

  const handleViewMorePress = () => {
    navigation.navigate("Merits");
  };

  return (
    <Card elementId="myMerits" elevation={4} style={styles.container}>
      <View style={[styles.title, styles.marginBottom]}>
        <Heading
          bold
          level="2"
          testProps={{ elementId: "heading", elementName: "MyMerits", screenName: route.name }}
        >
          <Trans context="title">My Merits</Trans>
        </Heading>
        <Icon name="meritOutlinedLargeAction" />
      </View>
      <LineSeparator />
      <MeritsList style={styles.marginBottom} />
      <Pressable
        onPress={handleViewMorePress}
        {...getTestProps({
          elementId: "viewMore",
          elementName: "MyMerits",
        })}
      >
        <Body color={theme.colors.text.link} style={styles.viewMoreText} uppercase>
          <Trans context="link">View All</Trans>
        </Body>
      </Pressable>
    </Card>
  );
};
