import { ListItem } from "./ListItem";
import type { ButtonConfig } from "./types";
import type { CommonProps } from "../types";
import type { VFC } from "react";

export type ButtonListItemProps = CommonProps & {
  readonly button: ButtonConfig;
  readonly minimized: boolean;
};

export const ButtonListItem: VFC<ButtonListItemProps> = ({ button, elementId, minimized }) => (
  <ListItem
    elementId={elementId}
    icon={button.icon}
    minimized={minimized}
    onPress={button.onPress}
    text={button.text}
  />
);
