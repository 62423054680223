import { IconSkeleton, TextSkeleton } from "@src/components";
import { MeritListItemLayout } from "../MeritListItemLayout";
import type { FC } from "react";

// unused
export const MeritListItemSkeleton: FC = () => (
  <MeritListItemLayout
    issuedAt={<TextSkeleton />}
    issuerOrgName={<TextSkeleton />}
    meritName={<TextSkeleton />}
    orgImage={<IconSkeleton />}
  />
);
