import { IconSkeleton, TextSkeleton } from "@src/components";
import { NotificationLayout } from "../NotificationLayout";
import { StyleSheet } from "react-native";
import { useTheme } from "@merit/frontend-components";
import type { FC } from "react";

export const NotificationSkeleton: FC = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    bodyStyle: {
      height: theme.spacing.xl,
      marginBottom: 0,
      width: 300,
    },
    headingStyle: {
      height: 18,
      marginBottom: 0,
    },
    iconStyle: {
      height: 32,
      width: 32,
    },
  });

  return (
    <NotificationLayout
      body={<TextSkeleton style={styles.bodyStyle} />}
      heading={<TextSkeleton style={styles.headingStyle} />}
      leftIcon={<IconSkeleton style={styles.iconStyle} />}
    />
  );
};
