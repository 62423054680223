import { Log } from "@src/utils";
import { useMemberApi } from "./useMemberApi";
import { useMutation } from "@tanstack/react-query";
import type { SendRejectMeritEmailRequest } from "@merit/person-experience-backend-client";

export const useSendRejectMeritEmail = () => {
  const { api: memberApi } = useMemberApi();

  return useMutation({
    mutationFn: (body: SendRejectMeritEmailRequest) => memberApi.sendRejectMeritEmail({ body }),
    onError: (error, body) => {
      Log.error("Error sending reject merit email", { body, error });
    },
  });
};
