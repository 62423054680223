import { MeritListItem } from "./MeritListItem";
import { MeritListItemDesktop } from "./MeritListItemDesktop";
import { useLayoutType } from "@src/hooks";
import type { Props } from "./types";

const LayoutMeritListItem = ({ ...props }: Props) => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <MeritListItemDesktop {...props} />;
  }

  return <MeritListItem {...props} />;
};

export { LayoutMeritListItem as MeritListItem };
