import { Helpers } from "@merit/frontend-utils";
import { Platform } from "react-native";
import { useRoute } from "./useRoute";
import Constants from "expo-constants";

export type PETestProps = Omit<Helpers.TestProps, "androidPrefix" | "screenName">;

/** Simple wrapper to encapsulate getting the `androidPrefix` and `screenName for `generateTestIdProps` */
export const useGetTestProps = () => {
  const route = useRoute();

  return (testProps?: PETestProps, options?: Helpers.GenerateTestIdPropsOptions) =>
    Helpers.generateTestIdProps(
      {
        ...(testProps ?? ({} as PETestProps)),
        androidPrefix:
          Platform.OS === "android"
            ? `${String(Constants.expoConfig?.android?.package)}:id/`
            : undefined,
        screenName: route.name,
      },
      options
    );
};
