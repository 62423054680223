import {
  Configuration as ConfigurationCls,
  PersonprefsApi,
} from "@merit/person-experience-backend-client";
import { getLocalConfig } from "@src/utils";
import { useMeritAuth0 } from "@src/hooks";

const config = getLocalConfig();

const usePersonPreferencesApi = () => {
  const { accessToken } = useMeritAuth0();

  return {
    api: new PersonprefsApi(
      new ConfigurationCls({
        ...config.api.personExperienceBackend,
        headers: {
          ...config.api.personExperienceBackend.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ),
    isReady: accessToken !== "",
  };
};

export { usePersonPreferencesApi };
