import { t } from "@lingui/macro";
import type { Merit } from "../types";

const ORG_NAME_KEY = "Org Name";
const ISSUING_ORG_NAME_KEY = "Issuing Org Name";

export const getOrgName = (merit: PickPartial<Merit, "transformedFields">): string =>
  (merit.kind === "adminMerit"
    ? merit.fieldMap[ORG_NAME_KEY]
    : merit.fieldMap[ISSUING_ORG_NAME_KEY]) ?? t`Unknown issuer`;
