import { PersonalQrCode as PersonalQrCodeComponent, PersonalQrCodeHeader } from "@src/components";
import { StyleSheet, View } from "react-native";
import { useGetTestProps, useNavigation } from "@src/hooks";
import { useRef } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTheme } from "@merit/frontend-components";
import type { PersonalQrCodeHandle } from "@src/components";
import type { ViewStyle } from "react-native";

export const PersonalQrCode = () => {
  const { bottom: bottomSafeInset } = useSafeAreaInsets();
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly qrCodeContainer: ViewStyle;
  }>({
    container: {
      alignItems: "stretch",
      backgroundColor: theme.colors.background.white,
      flex: 1,
      paddingBottom: Math.max(bottomSafeInset, theme.spacing.l),
    },
    qrCodeContainer: {
      flex: 1,
      paddingHorizontal: theme.spacing.xxl,
    },
  });

  const qrCodeRef = useRef<PersonalQrCodeHandle>(null);
  const { goBackOrGoHome } = useNavigation();
  const getTestProps = useGetTestProps();

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementName: "PersonalQRCode",
      })}
    >
      <PersonalQrCodeHeader
        onPressClose={() => {
          goBackOrGoHome();
        }}
        onPressRegenerate={() => {
          qrCodeRef.current?.regenerate();
        }}
      />
      <PersonalQrCodeComponent ref={qrCodeRef} style={styles.qrCodeContainer} />
    </View>
  );
};
