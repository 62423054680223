import { Footer } from "@src/components";
import { Image, StyleSheet, View, useWindowDimensions } from "react-native";
import { Login } from "./Login";
import { MOBILE_BREAKPOINT_FOR_LOGIN_SCREEN_ONLY } from "./constants";
import { useLayoutType } from "@src/hooks";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useTheme } from "@merit/frontend-components";
import GetStartedCards from "@src/assets/images/get-started-cards.png";
import type { ImageStyle, ViewStyle } from "react-native";

// These "magic numbers" are directly from the figma designs
const getPanelMaxWidthByScreenSize = (screenSize: number) => {
  if (screenSize >= 1440) {
    return 720;
  } else if (screenSize >= 1024) {
    return 512;
  }

  return 384;
};

export const LoginScreen = () => {
  const { bottom, top } = useSafeAreaInsets();
  const { theme } = useTheme();
  const { width } = useWindowDimensions();
  const desktopStyles = StyleSheet.create<{
    readonly cardsContainer: ViewStyle;
    readonly cardsImage: ImageStyle;
    readonly container: ViewStyle;
    readonly panelLeft: ViewStyle;
    readonly panelRight: ViewStyle;
  }>({
    cardsContainer: {
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
    cardsImage: {
      height: "100%",
      width: "100%",
    },
    container: {
      flex: 1,
      flexDirection: "row",
    },
    panelLeft: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      flex: 1,
      justifyContent: "space-between",
      maxWidth: getPanelMaxWidthByScreenSize(width),
      paddingBottom: bottom + theme.spacing.xl,
      paddingHorizontal: 48,
      paddingTop: top + 48,
    },
    panelRight: {
      backgroundColor: theme.colors.brand.midnight,
      flex: 1,
    },
  });
  const mobileStyles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly content: ViewStyle;
    readonly cardsImage: ImageStyle;
    readonly cardsContainer: ViewStyle;
  }>({
    cardsContainer: {
      flex: 1,
    },
    cardsImage: {
      height: "100%",
      maxWidth: "100%",
    },
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      paddingBottom: bottom + theme.spacing.xs,
      paddingTop: top + theme.spacing.xxl,
    },
    content: {
      marginBottom: theme.spacing.m,
      paddingHorizontal: theme.spacing.xxl,
    },
  });

  const layoutType = useLayoutType(MOBILE_BREAKPOINT_FOR_LOGIN_SCREEN_ONLY);

  if (layoutType === "mobile") {
    return (
      <View style={mobileStyles.container}>
        <View style={mobileStyles.content}>
          <Login />
        </View>
        <View style={mobileStyles.cardsContainer}>
          <Image source={GetStartedCards} style={mobileStyles.cardsImage} />
        </View>
        <Footer />
      </View>
    );
  }

  return (
    <View style={desktopStyles.container}>
      <View style={desktopStyles.panelLeft}>
        <Login />
        <Footer />
      </View>
      <View style={desktopStyles.panelRight}>
        <View style={desktopStyles.cardsContainer}>
          <Image source={GetStartedCards} style={desktopStyles.cardsImage} />
        </View>
      </View>
    </View>
  );
};
