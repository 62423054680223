import { Log, unloadAbortController } from "@src/utils";
import { PolicySchema } from "./schemas";
import { msg } from "@lingui/macro";
import { useAlerts, useLogoutOnError } from "@src/hooks";
import { useEffect } from "react";
import { useLingui } from "@lingui/react";
import { usePoliciesApi } from "./usePoliciesApi";
import { useQuery } from "@tanstack/react-query";
import type { Policy } from "./types";
import type { UseQueryResult } from "@tanstack/react-query";

type QueryKey = readonly ["policy", { readonly policyId: Policy["id"] | undefined }];

type UsePolicyQueryKeyArgs = {
  readonly policyId: Policy["id"] | undefined;
};

type UsePolicyQueryKey = (args: UsePolicyQueryKeyArgs) => QueryKey;

export const usePolicyQueryKey: UsePolicyQueryKey = ({ policyId }) => {
  const { isMock } = usePoliciesApi();

  return ["policy", { isMock, policyId }];
};

type UsePolicyArgs = {
  readonly policyId: Policy["id"] | undefined;
};

type UsePolicy = (args: UsePolicyArgs) => UseQueryResult<Policy>;

export const usePolicy: UsePolicy = ({ policyId }) => {
  const { api, isReady } = usePoliciesApi();
  const queryKey = usePolicyQueryKey({ policyId });
  const { sendAlert } = useAlerts();
  const { _ } = useLingui();
  const { onError } = useLogoutOnError();

  const query = useQuery({
    enabled: isReady && policyId !== undefined,
    queryFn: async ctx => {
      const { policyId: policyID } = ctx.queryKey[1];

      const response = await api.getPolicy(
        // Query is disabled when policyId is undefined
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { policyID: policyID! },
        { signal: unloadAbortController.signal }
      );

      const result = PolicySchema.safeParse(response);
      if (!result.success) {
        Log.error("Failed to parse policy", { error: result.error.errors, policyId: response.id });

        throw new Error("Failed to parse policy");
      }

      return result.data;
    },
    queryKey,
  });

  useEffect(() => {
    if (query.isError) {
      sendAlert({
        id: "usePolicy-Failed",
        text1: _(msg`Something went wrong while fetching your verification requests`),
        type: "error",
      });

      onError(query.error);
    }
  }, [query.isError, _, sendAlert, query.error, onError]);

  return query;
};
