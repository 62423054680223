import { Image, StyleSheet } from "react-native";
import { Link } from "@merit/frontend-components";
import { Log, byAdminMerit, hasState } from "@src/utils";
import { MERIT_LOGOTYPE } from "@src/utils/constants/sizes";
import { OnboardingMeritsBody } from "./OnboardingMeritsBody";
import { OnboardingMeritsBodyComplete } from "./OnboardingMeritsBody/OnboardingMeritsBodyComplete";
import { OnboardingMeritsFolioBody } from "./OnboardingMeritsFolioBody";
import { OnboardingMeritsFolioFooter } from "./OnboardingMeritsFolioFooter";
import { OnboardingMeritsFolioHeading } from "./OnboardingMeritsFolioHeading";
import { OnboardingMeritsFolioLoading } from "./OnboardingMeritsFolioLoading";
import { OnboardingMeritsFooter } from "./OnboardingMeritsFooter";
import { OnboardingMeritsHeading } from "./OnboardingMeritsHeading";
import { OnboardingMeritsLayout } from "./OnboardingMeritsLayout/";
import { SkipOnboardingConfirmationModal } from "./SkipOnboardingConfirmationModal";
import { Trans } from "@lingui/macro";
import { useAcceptMerit, useMerits } from "@src/api/issuance";
import { useCallback, useMemo, useState } from "react";
import { useConfig } from "@src/hooks";
import { useOnboardingStore } from "@src/stores";
import MeritLogotype from "@src/assets/images/merit-logotype.png";
import type { ExtendedContainer, Merit } from "@src/api/issuance";
import type { OnboardingMeritsFolioLoadingProps } from "./OnboardingMeritsFolioLoading/OnboardingMeritsFolioLoading";

const OnboardingMerits = () => {
  const { data: merits } = useMerits();
  const pendingMerits = useMemo(
    () => merits?.filter(hasState(["pending"])).sort(byAdminMerit),
    [merits]
  );

  const [currentAdminMeritIdToAccept, setCurrentAdminMeritIdToAccept] = useState<
    ExtendedContainer["id"] | undefined
  >();
  const [currentAccountFolioIdToAccept, setCurrentAccountFolioIdToAccept] = useState<
    ExtendedContainer["id"] | undefined
  >();
  const [isSkipConfirmationModalVisible, setIsSkipConfirmationModalVisible] =
    useState<boolean>(false);
  const setOnboardingStep = useOnboardingStore(state => state.setOnboardingStep);

  const { mutate: acceptMerit } = useAcceptMerit();
  const config = useConfig();

  const acceptAdminMeritAndMoveOn = useCallback(
    (meritId: Merit["id"]) => {
      acceptMerit(meritId);
      setCurrentAdminMeritIdToAccept(undefined);
    },
    [acceptMerit]
  );

  const handleGetOrgAccountFolio = useCallback<
    OnboardingMeritsFolioLoadingProps["onGetOrgAccountFolio"]
  >(
    (folioId, state, adminMeritId) => {
      if (state?.name === "pending") {
        setCurrentAccountFolioIdToAccept(folioId);
      } else {
        acceptAdminMeritAndMoveOn(adminMeritId);
      }
    },
    [acceptAdminMeritAndMoveOn]
  );

  const handleError = useCallback<OnboardingMeritsFolioLoadingProps["onError"]>(
    (err, adminMeritId) => {
      // log the error, accept the admin merit, and move on
      Log.error(`Error loading account folio for admin merit id ${adminMeritId}: ${String(err)}`);
      acceptAdminMeritAndMoveOn(adminMeritId);
    },
    [acceptAdminMeritAndMoveOn]
  );

  const styles = StyleSheet.create({
    meritLogotype: {
      ...MERIT_LOGOTYPE.m,
    },
  });

  if (pendingMerits === undefined) {
    // Probably treat this better, but we definitely shouldn't get to
    // onboarding if we have no merits at all
    return null;
  }

  if (pendingMerits.length === 0) {
    return (
      <OnboardingMeritsLayout
        body={<OnboardingMeritsBodyComplete />}
        heading={null}
        pageHeaderLeft={<Image source={MeritLogotype} style={styles.meritLogotype} />}
        pageHeaderRight={null}
      />
    );
  }

  if (currentAdminMeritIdToAccept !== undefined) {
    if (currentAccountFolioIdToAccept === undefined) {
      return (
        <OnboardingMeritsLayout
          body={
            <OnboardingMeritsFolioLoading
              adminMeritId={currentAdminMeritIdToAccept}
              onError={handleError}
              onGetOrgAccountFolio={handleGetOrgAccountFolio}
            />
          }
          footer={null}
          heading={null}
          pageHeaderLeft={<Image source={MeritLogotype} style={styles.meritLogotype} />}
          pageHeaderRight={null}
        />
      );
    }

    return (
      <OnboardingMeritsLayout
        body={<OnboardingMeritsFolioBody adminMeritId={currentAdminMeritIdToAccept} />}
        footer={
          <OnboardingMeritsFolioFooter
            adminMeritId={currentAdminMeritIdToAccept}
            onAcceptSuccess={() => {
              setCurrentAdminMeritIdToAccept(undefined);
              setCurrentAccountFolioIdToAccept(undefined);
            }}
            // TODO (PE-846): use onAcceptFailure prop and gracefully handle error
          />
        }
        heading={<OnboardingMeritsFolioHeading />}
        pageHeaderLeft={<Image source={MeritLogotype} style={styles.meritLogotype} />}
        pageHeaderRight={null}
      />
    );
  }

  // We return above if pendingMerits has length 0, so we can be assured here that currentMerit is
  // not undefined
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentMerit = pendingMerits[0]!;

  return (
    <>
      <OnboardingMeritsLayout
        body={<OnboardingMeritsBody meritId={currentMerit.id} />}
        footer={
          <OnboardingMeritsFooter
            meritId={currentMerit.id}
            onPressAcceptAdminMerit={meritId => {
              setCurrentAdminMeritIdToAccept(meritId);
            }}
          />
        }
        heading={<OnboardingMeritsHeading />}
        pageHeaderLeft={<Image source={MeritLogotype} style={styles.meritLogotype} />}
        pageHeaderRight={
          currentMerit.templateId === config.remote?.adminMeritTemplateID ? null : (
            <Link
              onPress={() => {
                setIsSkipConfirmationModalVisible(true);
              }}
            >
              <Trans context="action">Go to app</Trans>
            </Link>
          )
        }
      />
      {isSkipConfirmationModalVisible ? (
        <SkipOnboardingConfirmationModal
          onCancel={() => {
            setIsSkipConfirmationModalVisible(false);
          }}
          onConfirm={() => {
            setOnboardingStep("COMPLETE");
            setIsSkipConfirmationModalVisible(false);
          }}
        />
      ) : null}
    </>
  );
};

export { OnboardingMerits };
