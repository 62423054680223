import { Card as FECard, useTheme } from "@merit/frontend-components";
import { StyleSheet } from "react-native";
import { useGetTestProps } from "@src/hooks";
import type { CommonProps } from "../types";
import type { FC } from "react";
import type { ViewProps } from "react-native";

export type CardProps = CommonProps &
  ViewProps & {
    readonly elevation?: 1 | 2 | 3 | 4 | 5;
  };

// TODO (PE-783): deprecate this component and used the shared FE-component version instead
export const Card: FC<CardProps> = ({ children, elementId, elevation, style, ...rest }) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    card: {
      backgroundColor: theme.colors.background.white,
      borderRadius: theme.borderRadii.s,
      ...(elevation === undefined ? {} : theme.elevations[`depth${elevation}` as const]),
    },
  });
  const getTestProps = useGetTestProps();

  return (
    <FECard
      style={[styles.card, style]}
      {...rest}
      {...getTestProps({ elementId, elementName: "Card" })}
    >
      {children}
    </FECard>
  );
};
