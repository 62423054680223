import { HELP, PRIVACY, TOS } from "@src/utils/constants/urls";
import { Link } from "@merit/frontend-components";
import { Spacer } from "./Spacer";
import { StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { openExternalLink } from "@src/utils";
import { useRoute } from "@react-navigation/native";
import type { StyleProp, ViewStyle } from "react-native";

type LinksProps = {
  readonly style?: StyleProp<ViewStyle>;
};

export const Links = ({ style }: LinksProps) => {
  const route = useRoute();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
  }>({
    container: {
      alignItems: "center",
      flexDirection: "row",
    },
  });

  return (
    <View style={[styles.container, style]}>
      <Link
        onPress={() => {
          openExternalLink(HELP);
        }}
        testProps={{ elementId: "help", elementName: "Links", screenName: route.name }}
      >
        <Trans comment="link to self-help site" context="link">
          Help
        </Trans>
      </Link>
      <Spacer />
      <Link
        onPress={() => {
          openExternalLink(PRIVACY);
        }}
        testProps={{ elementId: "privacy", elementName: "Links", screenName: route.name }}
      >
        <Trans comment="link to privacy policy" context="link">
          Privacy
        </Trans>
      </Link>
      <Spacer />
      <Link
        onPress={() => {
          openExternalLink(TOS);
        }}
        testProps={{ elementId: "terms", elementName: "Links", screenName: route.name }}
      >
        <Trans comment="link to terms of service" context="link">
          Terms
        </Trans>
      </Link>
    </View>
  );
};
