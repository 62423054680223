/* eslint-disable sort-keys-fix/sort-keys-fix */

export const HEADER_HEIGHT = 60;
export const MODAL = 520;

export const ORG_LOGO_SIZE = 64;

export const MERIT_LOGOTYPE = {
  m: {
    height: 24,
    width: 76,
  },
  l: {
    height: 32,
    width: 102,
  },
  xl: {
    height: 37,
    width: 120,
  },
} as const;
