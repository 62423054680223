import { Body, Icon } from "@merit/frontend-components";
import { MeritListItemLayout } from "@src/layouts";
import { Pressable, StyleSheet } from "react-native";
import { TruncatedBody, TruncatedHeading } from "@src/components";
import { useCreatePersonaFormDataStore } from "@src/stores";
import { useGetTestProps, useNavigation } from "@src/hooks";
import type { Merit } from "@src/api/issuance";
import type { VFC } from "react";

export type MeritListItemProps = {
  readonly merit: Merit;
};

export const MeritListItem: VFC<MeritListItemProps> = ({ merit }) => {
  const getTestProps = useGetTestProps();
  const navigation = useNavigation();
  const { meritIds } = useCreatePersonaFormDataStore();

  const styles = StyleSheet.create({
    leftIcon: {
      height: 40,
      width: 40,
    },
  });

  const handlePress = () => {
    navigation.navigate("CreatePersonaMeritDetails", { meritId: merit.id });
  };

  return (
    <Pressable
      onPress={handlePress}
      {...getTestProps({
        elementId: merit.id,
        elementName: "MeritListItem",
      })}
    >
      <MeritListItemLayout
        leftIcon={
          <Icon alt="Default merit organization icon" name="defaultOrg" style={styles.leftIcon} />
        }
        leftSubtext={<TruncatedBody>{merit.fieldMap["Issuing Org Name"]}</TruncatedBody>}
        rightIcon={
          meritIds.has(merit.id) ? (
            <Icon alt="Merit is part of new Persona" name="checkmarkSmallSuccess" />
          ) : (
            <Icon alt="Merit is not part of new Persona" name="chevronRightMediumAction" />
          )
        }
        rightSubtext={
          merit.createdAt === undefined ? null : (
            <Body>{Intl.DateTimeFormat().format(new Date(merit.createdAt))}</Body>
          )
        }
        title={<TruncatedHeading level="3">{merit.name}</TruncatedHeading>}
      />
    </Pressable>
  );
};
