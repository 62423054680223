import { LineSeparator, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { useSkeleton } from "@src/hooks";
import Animated from "react-native-reanimated";
import type { ViewStyle } from "react-native";

type SkeletonProps = {
  readonly showTopSeparator?: boolean;
};

export const Skeleton = ({ showTopSeparator = false }: SkeletonProps) => {
  const skeletonStyles = useSkeleton();
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly skeletonRowContainer: ViewStyle;
    readonly skeletonRowImage: ViewStyle;
    readonly skeletonRowText1: ViewStyle;
    readonly skeletonRowText2: ViewStyle;
    readonly skeletonTextsContainer: ViewStyle;
  }>({
    skeletonRowContainer: {
      flexDirection: "row",
      padding: theme.spacing.m,
    },
    skeletonRowImage: {
      height: 40,
      marginRight: theme.spacing.m,
      width: 40,
    },
    skeletonRowText1: {
      borderRadius: theme.fontSizes.l.lineHeight / 2,
      height: theme.fontSizes.l.lineHeight,
      marginBottom: theme.spacing.s,
    },
    skeletonRowText2: {
      borderRadius: theme.fontSizes.s.lineHeight / 2,
      height: theme.fontSizes.s.lineHeight,
    },
    skeletonTextsContainer: {
      flex: 1,
    },
  });

  return (
    <View>
      {showTopSeparator ? <LineSeparator /> : null}
      <View style={styles.skeletonRowContainer}>
        <Animated.View style={[styles.skeletonRowImage, skeletonStyles]} />
        <View style={styles.skeletonTextsContainer}>
          <Animated.View style={[styles.skeletonRowText1, skeletonStyles]} />
          <Animated.View style={[styles.skeletonRowText2, skeletonStyles]} />
        </View>
      </View>
    </View>
  );
};
