import { Badge, Body, Heading, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useRoute } from "@react-navigation/native";
import type { EmailListProps } from "./types";
import type { TextStyle, ViewStyle } from "react-native";

const EmailList = ({ emails }: EmailListProps) => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const route = useRoute();
  const { _ } = useLingui();

  const styles = StyleSheet.create<{
    readonly emailContainer: ViewStyle;
    readonly emailHeadingContainer: ViewStyle;
    readonly primaryEmailHeading: TextStyle;
    readonly emailListItem: ViewStyle;
    readonly emailListItemText: TextStyle;
  }>({
    emailContainer: {
      marginTop: theme.spacing.xxl,
    },
    emailHeadingContainer: {
      marginBottom: theme.spacing.xl,
    },
    emailListItem: {
      alignItems: "center",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingVertical: theme.spacing.xxl,
    },
    emailListItemText: {
      marginRight: theme.spacing.xxl,
    },
    primaryEmailHeading: {
      ...theme.fontSizes.l,
    },
  });

  return (
    <View
      style={styles.emailContainer}
      {...getTestProps({
        elementName: "EmailList",
      })}
    >
      <View style={styles.emailHeadingContainer}>
        <Heading
          level="1"
          style={styles.primaryEmailHeading}
          testProps={{
            elementName: "ChoosePrimaryEmailText",
            screenName: route.name,
          }}
        >
          <Trans context="title">Account email</Trans>
        </Heading>
        <Body
          testProps={{
            elementName: "NotificationText",
            screenName: route.name,
          }}
        >
          <Trans>The primary email will be used for all email notifications</Trans>
        </Body>
      </View>
      {emails.map(email => (
        <View key={email.email} style={styles.emailListItem}>
          <Body style={styles.emailListItemText}>{email.email}</Body>
          {email.isPrimary ? (
            <Badge text={_(msg({ context: "importance", message: "Primary" }))} />
          ) : null}
        </View>
      ))}
    </View>
  );
};

export { EmailList };
