// Cancel requests before the browser does
//
// If the browser cancels fetch requests on a load event, that becomes a fetch error which gets
// reported in DataDog. If we cancel our own requests before the load event, then an AbortError is
// thrown which is easier to filter for.
//
// # Usage
//
// `unloadAbortController.signal` should be passed to any fetch functions that should be cancelled
// on unload
//
// # Example
//
// ```
// fetch('http://example.com/some.json', {signal: unloadAbortController.signal})
// ```

export const unloadAbortController = new AbortController();

export const initUnloadAbortController = () => {
  window.addEventListener("beforeunload", () => {
    unloadAbortController.abort();
  });
};
