// This is a type guard that useful for filtering out undefined items in an array. It tells TS that
// the return value is not undefined.
//
// Usage:
//
// ```
// const a = [1, 2, undefined, 3, 4].filter(isNotUndefined) // readonly number[]
// ```

export function isNotUndefined<T>(item: T | undefined): item is T {
  return item !== undefined;
}
