import { Log } from "@src/utils";
import { msg } from "@lingui/macro";
import { useAccountFolioFromAdminMerit } from "./useAccountFolioFromAdminMerit";
import { useAlerts, useMeritAuth0 } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMemberApi } from "./useMemberApi";
import { useMutation } from "@tanstack/react-query";
import type { AdminMerit } from "../issuance/types";

export const useAcceptFolio = (adminMeritId: AdminMerit["id"]) => {
  const { api: memberApi } = useMemberApi();
  const { sendAlert } = useAlerts();
  const { refetch } = useAccountFolioFromAdminMerit(adminMeritId);
  const { idToken } = useMeritAuth0();
  const { _ } = useLingui();

  return useMutation({
    mutationFn: () =>
      memberApi.acceptAccountFolioFromAdminMerit({ adminMeritId, xIDToken: idToken }),
    onError: err => {
      Log.error(`Error accepting folio from admin merit ${adminMeritId} ${String(err)}`);
      sendAlert({
        id: "useAcceptFolio-Error",
        text1: _(msg`Error accepting your folio`),
        text2: String(err),
        type: "error",
      });
    },
    onMutate: () => {
      sendAlert({
        id: "useAcceptFolio-Accepting",
        text1: _(msg`Accepting…`),
        type: "info",
      });
    },
    onSettled: () => {
      refetch();
    },
    onSuccess: () => {
      sendAlert({
        id: "useAcceptFolio-Success",
        text1: _(msg`Your folio has been accepted`),
        type: "success",
      });
    },
  });
};
