import {
  AccessibilityWidget,
  AlertContainer,
  TextContextProvider,
  useTheme,
} from "@merit/frontend-components";
import { AutoLogout } from "@src/components";
import { DdScreenReplayProvider } from "./components/DdScreenReplayProvider";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { I18nProvider } from "@lingui/react";
import { Log, addGlobalContext } from "@src/utils";
import { Platform, StatusBar, StyleSheet } from "react-native";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Router } from "./Router";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  channel,
  createdAt,
  isEmbeddedLaunch,
  isEmergencyLaunch,
  isUsingEmbeddedAssets,
  releaseChannel,
  runtimeVersion,
  updateId,
} from "expo-updates";
import { i18n } from "@lingui/core";
import { useAlertStore } from "./stores/alert";
import { useEffect, useState } from "react";
import { useInitializeLaunchDarkly, useInitializeLocale, usePersonExperienceFonts } from "./hooks";
import ErrorBoundary from "react-native-error-boundary";
import type { ViewStyle } from "react-native";

const App = () => {
  const { theme } = useTheme();
  const [fontsLoaded, fontsError] = usePersonExperienceFonts();
  const alertList = useAlertStore(state => state.alertList);

  const [hasLogged, setHasLogged] = useState(false);
  useEffect(() => {
    if (hasLogged) {
      return;
    }
    addGlobalContext("expoUpdatesInfo", {
      channel,
      createdAt,
      isEmbeddedLaunch,
      isEmergencyLaunch,
      isUsingEmbeddedAssets,
      releaseChannel,
      runtimeVersion,
      updateId,
    });
    Log.info("app cold start");
    setHasLogged(true);
  }, [hasLogged]);

  useInitializeLaunchDarkly();
  useInitializeLocale();

  // If the fonts fail to load, we still want the app to load, but we will log that something is wrong
  if (fontsError !== null) {
    Log.warn("Failed to load fonts");
  } else if (fontsLoaded !== true) {
    return null;
  }

  const styles = StyleSheet.create<{
    readonly gestureHandler: ViewStyle;
  }>({
    gestureHandler: {
      flex: 1,
    },
  });

  return (
    <ErrorBoundary>
      {Platform.OS === "web" ? <AccessibilityWidget /> : null}
      <TextContextProvider maxFontSizeMultiplier={1.5}>
        <DdScreenReplayProvider>
          <I18nProvider i18n={i18n}>
            <AutoLogout>
              <SafeAreaProvider>
                <GestureHandlerRootView style={styles.gestureHandler}>
                  <StatusBar
                    backgroundColor={theme.colors.background.default}
                    barStyle="dark-content"
                  />
                  <Router />
                  <AlertContainer alertList={alertList} />
                  {Platform.OS === "web" ? <ReactQueryDevtools initialIsOpen={false} /> : null}
                </GestureHandlerRootView>
              </SafeAreaProvider>
            </AutoLogout>
          </I18nProvider>
        </DdScreenReplayProvider>
      </TextContextProvider>
    </ErrorBoundary>
  );
};

export { App };
