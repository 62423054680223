import { StyleSheet, View } from "react-native";
import { useLayoutType } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";

export const Banner = () => {
  const { theme } = useTheme();
  const layoutType = useLayoutType();

  const styles = StyleSheet.create({
    banner: {
      backgroundColor:
        layoutType === "desktop" ? theme.colors.background.default : theme.colors.brand.turquiose,
      height: 200,
      left: 0,
      position: "absolute",
      right: 0,
    },
  });

  return <View style={styles.banner} />;
};
