import { Body, LineSeparator, useTheme } from "@merit/frontend-components";
import { FlatList, StyleSheet, View } from "react-native";
import { MeritListItem, MeritListItemSkeleton } from "@src/components";
import { Trans } from "@lingui/macro";
import { range } from "lodash";
import { useGetTestProps, useNavigation } from "@src/hooks";
import type { FC } from "react";
import type { Merit } from "@src/api/issuance";
import type { ViewStyle } from "react-native";

export type MeritsListProps = {
  readonly merits?: readonly Merit[];
};

export const MeritsList: FC<MeritsListProps> = ({ merits }) => {
  const getTestProps = useGetTestProps();
  const navigation = useNavigation();
  const { theme } = useTheme();

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly headerTextContainer: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    headerTextContainer: {
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
  });

  if (merits === undefined) {
    return (
      <View
        style={styles.container}
        {...getTestProps({
          elementName: "MeritsList",
        })}
      >
        <FlatList
          ItemSeparatorComponent={() => <LineSeparator />}
          ListHeaderComponent={() => (
            <>
              <LineSeparator />
              <View style={styles.headerTextContainer}>
                <Body>
                  <Trans context="sort">A-Z</Trans>
                </Body>
              </View>
              <LineSeparator />
            </>
          )}
          data={range(5)}
          renderItem={({ item }) => <MeritListItemSkeleton key={item} />}
          {...getTestProps({
            elementId: "merits",
            elementName: "MeritsList",
          })}
        />
      </View>
    );
  }

  return (
    <View
      style={styles.container}
      {...getTestProps({
        elementName: "MeritsList",
      })}
    >
      <FlatList
        ItemSeparatorComponent={() => <LineSeparator />}
        ListHeaderComponent={() => (
          <>
            <LineSeparator />
            <View style={styles.headerTextContainer}>
              <Body>
                <Trans context="sort">A-Z</Trans>
              </Body>
            </View>
            <LineSeparator />
          </>
        )}
        data={merits}
        renderItem={({ item }) => (
          <MeritListItem
            merit={item}
            onPress={() => {
              navigation.navigate("MeritDetails", { meritId: item.id });
            }}
          />
        )}
        {...getTestProps({
          elementId: "merits",
          elementName: "MeritsList",
        })}
      />
    </View>
  );
};
