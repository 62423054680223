import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Trans } from "@lingui/macro";
import { WhatIsShared } from "./WhatIsShared";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useAccountMerit, usePersonalSignedToken } from "@src/api/issuance";
import { useGetTestProps, useRoute } from "@src/hooks";
import QRCode from "react-native-qrcode-svg";
import type { StyleProp, TextStyle, ViewStyle } from "react-native";

const BORDER_WIDTH = 1;

export type PersonalQrCodeHandle = {
  readonly regenerate: () => void;
};

type PersonalQrCodeProps = { readonly style: StyleProp<ViewStyle> };

export const PersonalQrCode = forwardRef<PersonalQrCodeHandle, PersonalQrCodeProps>(
  ({ style }, ref) => {
    const { theme } = useTheme();
    const styles = StyleSheet.create<{
      readonly heading: TextStyle;
      readonly infoText: TextStyle;
      readonly qrCodeContainer: ViewStyle;
      readonly qrCodeLabel: TextStyle;
      readonly qrCodeWrapper: ViewStyle;
      readonly spacer: ViewStyle;
    }>({
      heading: {
        marginBottom: 48,
        textAlign: "center",
      },
      infoText: {
        textAlign: "center",
      },
      qrCodeContainer: {
        alignItems: "center",
        borderColor: theme.colors.border.action.hovered,
        borderRadius: theme.borderRadii.s,
        borderWidth: BORDER_WIDTH,
        marginBottom: theme.spacing.l,
      },
      qrCodeLabel: {
        fontWeight: theme.fontWeights.semiBold,
        marginBottom: theme.spacing.s,
      },
      qrCodeWrapper: {
        marginVertical: theme.spacing.m,
      },
      spacer: {
        flex: 1,
        minHeight: theme.spacing.xl,
      },
    });

    const { data: accountMerit } = useAccountMerit();

    const [qrCodeContainerSize, setQrCodeContainerSize] = useState<number | undefined>();
    const { data, refetch } = usePersonalSignedToken();

    const getTestProps = useGetTestProps();
    const route = useRoute();

    useImperativeHandle(
      ref,
      () => ({
        regenerate: () => {
          refetch();
        },
      }),
      [refetch]
    );

    useEffect(() => {
      const intervalId = setInterval(() => {
        refetch();
      }, 45000);

      return () => {
        clearInterval(intervalId);
      };
    }, [refetch]);

    return (
      <View
        style={style}
        {...getTestProps({
          elementName: "PersonalQrCode",
        })}
      >
        <Heading
          level="3"
          style={styles.heading}
          testProps={{
            elementId: "heading",
            elementName: "PersonalQrCode",
            screenName: route.name,
          }}
        >
          <Trans>Scan your digital credentials to check&nbsp;in and check&nbsp;out</Trans>
        </Heading>
        <View
          onLayout={evt => {
            setQrCodeContainerSize(evt.nativeEvent.layout.width);
          }}
          style={styles.qrCodeContainer}
        >
          <View
            style={styles.qrCodeWrapper}
            {...getTestProps({
              elementId: "qrCodeContent",
              elementName: "PersonalQrCode",
            })}
          >
            {qrCodeContainerSize === undefined || data?.token === undefined ? (
              <ActivityIndicator
                size="large"
                {...getTestProps({
                  elementId: "activityIndicator",
                  elementName: "PersonalQrCode",
                })}
              />
            ) : (
              <QRCode
                backgroundColor="transparent"
                quietZone={theme.spacing.s}
                size={qrCodeContainerSize - BORDER_WIDTH * 2}
                value={data.token}
                {...getTestProps({
                  elementId: "qrCode",
                  elementName: "PersonalQrCode",
                })}
              />
            )}
          </View>
          <Body
            size="l"
            style={styles.qrCodeLabel}
            testProps={{
              elementId: "qrCodeLabel",
              elementName: "PersonalQrCode",
              screenName: route.name,
            }}
          >
            {accountMerit?.transformedFields.recipientFullName}
          </Body>
        </View>
        <Body
          style={styles.infoText}
          testProps={{
            elementId: "refreshQrCodeMessage",
            elementName: "PersonalQrCode",
            screenName: route.name,
          }}
        >
          <Trans context="qrcode">
            This code will refresh every 45 seconds to safeguard your&nbsp;information
          </Trans>
        </Body>
        <View style={styles.spacer} />
        <WhatIsShared />
      </View>
    );
  }
);
