import { Log, unloadAbortController } from "@src/utils";
import { PolicyRequestSchema } from "./schemas";
import { msg } from "@lingui/macro";
import { useAlerts, useLogoutOnError, useMeritAuth0 } from "@src/hooks";
import { useEffect } from "react";
import { useLingui } from "@lingui/react";
import { usePolicyRequestsApi } from "./usePolicyRequestsApi";
import { useQuery } from "@tanstack/react-query";
import type { MeritUserInfo } from "@src/types/user";
import type { PolicyRequest as PlatformPolicyRequest } from "@merit/issuance-client";
import type { PolicyRequest } from "./types";
import type { UseQueryResult } from "@tanstack/react-query";

const isPolicyRequest = (
  policyRequest: RecursivePartial<PlatformPolicyRequest>
): policyRequest is PolicyRequest => {
  const result = PolicyRequestSchema.safeParse(policyRequest);
  if (!result.success) {
    Log.error("Failed to parse PolicyRequest", {
      errors: result.error.errors,
      policyId: policyRequest.policy,
    });

    return false;
  }

  return true;
};

type QueryKey = readonly [
  "RequestedPolicyRequests",
  { readonly userEntityId?: MeritUserInfo["entityID"]; readonly isMock: boolean }
];

export const useRequestedPolicyRequestsQueryKey = (): QueryKey => {
  const { user } = useMeritAuth0();
  const { isMock } = usePolicyRequestsApi();

  // eslint-disable-next-line lingui/no-unlocalized-strings
  return ["RequestedPolicyRequests", { isMock, userEntityId: user?.entityID }];
};

type UseRequestedPolicyRequests = () => UseQueryResult<readonly PolicyRequest[]>;

export const useRequestedPolicyRequests: UseRequestedPolicyRequests = () => {
  const { sendAlert } = useAlerts();
  const { _ } = useLingui();
  const { api, isReady } = usePolicyRequestsApi();
  const queryKey = useRequestedPolicyRequestsQueryKey();
  const { onError } = useLogoutOnError();

  const query = useQuery({
    enabled: isReady,
    queryFn: async ctx => {
      const { userEntityId } = ctx.queryKey[1];

      if (userEntityId === undefined) {
        Log.error("User entityId is undefined");

        throw new Error("User entityId is undefined");
      }

      const response = await api.getPolicyRequests(
        // eslint-disable-next-line lingui/no-unlocalized-strings
        { status: "Requested", targetID: userEntityId },
        { signal: unloadAbortController.signal }
      );
      const policyRequests = (response.policyRequests?.filter(isPolicyRequest) ??
        []) as readonly PolicyRequest[];

      return policyRequests;
    },
    queryKey,
  });

  useEffect(() => {
    if (query.isError) {
      Log.error("Failed to fetch requested PolicyRequests", { error: query.error });

      sendAlert({
        id: "useRequestedPolicyRequests-FailedToFetch",
        text1: _(msg`Something went wrong while fetching your verification requests`),
        type: "error",
      });

      onError(query.error);
    }
  }, [_, query.isError, sendAlert, query.error, onError]);

  return query;
};
