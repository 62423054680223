import { MeritFieldMapSchema } from "./MeritFieldMapSchema";
import { z } from "zod";

export const AdminMeritFieldMapSchema = MeritFieldMapSchema.extend({
  "Admin Phone Number": z.string().optional(),
  "Org Description": z.string().optional(),
  "Org Governing Country": z.string().optional(),
  "Org Governing State": z.string().optional(),
  "Org Legal Name": z.string(),
  "Org Name": z.string(),
});
