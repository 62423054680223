import { Icon, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import type { FC, ReactNode } from "react";
import type { ViewStyle } from "react-native";

type FilesAndMediaListItemLayoutProps = {
  readonly name: ReactNode;
  readonly icon: ReactNode;
  readonly hideArrow?: boolean;
};

export const FilesAndMediaListItemLayout: FC<FilesAndMediaListItemLayoutProps> = ({
  hideArrow = false,
  icon,
  name,
}) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly iconContainer: ViewStyle;
    readonly nameContainer: ViewStyle;
  }>({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      flexDirection: "row",
      padding: theme.spacing.l,
    },
    iconContainer: {
      marginRight: theme.spacing.s,
    },
    nameContainer: {
      flex: 1,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>{icon}</View>
      <View style={styles.nameContainer}>{name}</View>
      {hideArrow ? <View /> : <Icon name="arrowForwardMediumAction" />}
    </View>
  );
};
