import type { MeritSorter } from "./types";

const ISSUING_ORG_NAME_KEY = "Issuing Org Name";
export const byNameThenOrg: MeritSorter = (meritA, meritB) => {
  const nameCompare = meritA.name?.localeCompare(meritB.name ?? "") ?? 0;

  if (nameCompare === 0) {
    return meritA.fieldMap[ISSUING_ORG_NAME_KEY].localeCompare(
      meritB.fieldMap[ISSUING_ORG_NAME_KEY]
    );
  }

  return nameCompare;
};
