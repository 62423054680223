import { z } from "zod";

// see also: https://claimyourmerit.atlassian.net/wiki/spaces/RNE/pages/2284388372/RN-246+Select+a+merit+template+to+populate+check-in+out#Schema%3A%5BhardBreak%5D
// for date/time/duration formats, see also: https://json-schema.org/understanding-json-schema/reference/string#dates-and-times

export const RequiredFieldsSchema = z.object({
  activityName: z.string(),
  checkinId: z.string().uuid(),
  checkinTime: z.string().datetime({ offset: true }), // ISO8601 date string
  timezone: z.string(), // IANA timezone, e.g. Asia/Taipei, US/Eastern
});

export const OptionalFieldsSchema = z
  .object({
    checkinLocation: z.string().nullable(),
    checkoutLocation: z.string().nullable(),
    checkoutTime: z.string().datetime({ offset: true }).nullable(), // ISO8601 date string
    duration: z.string().nullable(), // RFC3339/ISO8601 duration
  })
  .partial();

export const ActivitySchema = RequiredFieldsSchema.merge(OptionalFieldsSchema);

export const ActivityTemplateFieldValueSchema = z.preprocess(
  val => (typeof val === "string" ? JSON.parse(val) : val),
  z.object({ checkInDetails: z.array(ActivitySchema) })
);

export type RequiredFields = z.infer<typeof RequiredFieldsSchema>;
export type OptionalFields = z.infer<typeof OptionalFieldsSchema>;
export type Activity = z.infer<typeof ActivitySchema>;
export type ActivityTemplateFieldValue = z.infer<typeof ActivityTemplateFieldValueSchema>;
