import { MeritDetails } from "./MeritDetails";
import { MeritDetailsDesktop } from "./MeritDetailsDesktop";
import { useLayoutType } from "@src/hooks";
import type { MeritDetailsProps } from "./types";

const LayoutMeritDetails = ({ ...props }: MeritDetailsProps) => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <MeritDetailsDesktop {...props} />;
  }

  return <MeritDetails {...props} />;
};

export { LayoutMeritDetails as MeritDetails };
