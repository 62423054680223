import { getLocalConfig } from "@src/utils";
import { merge } from "lodash";
import { useAppConfiguration } from "@src/api/person-experience-backend";
import { useMemo } from "react";
import type { Configuration } from "@src/configuration";

type UseConfig = () => Configuration;

export const useConfig: UseConfig = () => {
  const { data } = useAppConfiguration();

  const merged = useMemo(() => {
    const local = getLocalConfig();
    const remote = { remote: data };

    return merge({}, local, remote);
  }, [data]);

  return merged;
};
