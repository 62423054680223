import { MeritListItemLayout as DesktopMeritListItemLayout } from "./MeritListItemLayout.desktop";
import { MeritListItemLayout as MobileMeritListItemLayout } from "./MeritListItemLayout.mobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";
import type { MeritListItemLayoutProps } from "./types";

export const MeritListItemLayout: FC<MeritListItemLayoutProps> = props => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopMeritListItemLayout {...props} />;
  }

  return <MobileMeritListItemLayout {...props} />;
};
