import type { MeritSorter } from "./types";

export const byAdminMerit: MeritSorter = (meritA, meritB) => {
  const isAdminMeritA = meritA.kind === "adminMerit";
  const isAdminMeritB = meritB.kind === "adminMerit";

  if (isAdminMeritA === isAdminMeritB) {
    return 0;
  }

  return isAdminMeritA ? -1 : 1;
};
