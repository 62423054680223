import { NotificationPolicyRequest as DesktopNotification } from "./NotificationPolicyRequest.desktop";
import { NotificationPolicyRequest as MobileNotification } from "./NotificationPolicyRequest.mobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";
import type { NotificationPolicyRequestProps } from "./types";

export const NotificationPolicyRequest: FC<NotificationPolicyRequestProps> = props => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopNotification {...props} />;
  }

  return <MobileNotification {...props} />;
};
