import { AppApi, Configuration as ConfigurationCls } from "@merit/person-experience-backend-client";
import { getLocalConfig } from "@src/utils";

const config = getLocalConfig();

type UseAppAPI = () => {
  readonly api: AppApi;
  readonly isReady: true;
};

const useAppApi: UseAppAPI = () => ({
  api: new AppApi(
    new ConfigurationCls({
      ...config.api.personExperienceBackend,
      headers: {
        ...config.api.personExperienceBackend.headers,
      },
    })
  ),
  isReady: true,
});

export { useAppApi };
