import { Error } from "@src/components";
import { LineSeparator, useTheme } from "@merit/frontend-components";
import { Pressable, StyleSheet, View } from "react-native";
import { TruncatedBody } from "@src/components/TruncatedBody";
import { byAuthorizedAt, hasState } from "@src/utils";
import { useGetTestProps, useNavigation, useRoute, useSkeleton } from "@src/hooks";
import { useMemo } from "react";
import { useMerits } from "@src/api/issuance";
import Animated from "react-native-reanimated";
import type { Container } from "@merit/issuance-client";
import type { FC } from "react";
import type { StyleProp, ViewStyle } from "react-native";

const NUM_ROWS = 5;

type MeritsListProps = {
  readonly style?: StyleProp<ViewStyle>;
};

export const MeritsList: FC<MeritsListProps> = ({ style }) => {
  const route = useRoute();
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    row: {
      padding: theme.spacing.m,
    },
    textSkeleton: {
      borderRadius: theme.fontSizes.s.lineHeight / 2,
      height: theme.fontSizes.s.lineHeight,
    },
  });

  const skeletonStyles = useSkeleton();
  const { data: merits, isError } = useMerits();
  const navigation = useNavigation();
  const getTestProps = useGetTestProps();

  const displayedMerits = useMemo(
    () =>
      merits
        ?.filter(hasState(["accepted"]))
        .sort(byAuthorizedAt)
        .slice(0, NUM_ROWS),
    [merits]
  );

  const handleMeritPress = (merit: Container) => {
    navigation.navigate("MeritDetails", { meritId: merit.id });
  };

  if (isError) {
    return <Error />;
  }

  if (displayedMerits === undefined) {
    return (
      <View style={style}>
        {[...new Array(NUM_ROWS).keys()].map(item => (
          <View key={item}>
            <View style={styles.row}>
              <Animated.View style={[styles.textSkeleton, skeletonStyles]} />
            </View>
            <LineSeparator />
          </View>
        ))}
      </View>
    );
  }

  return (
    <View
      style={style}
      {...getTestProps({
        elementName: "MeritsList",
      })}
    >
      {/* TODO: the rendered items really should be their own component, or reuse `MeritsListItem` */}
      {displayedMerits.map(merit => (
        <View
          key={merit.id}
          {...getTestProps(
            {
              elementId: merit.id,
              elementName: "MeritsListItem",
            },
            {
              componentName: "Container",
            }
          )}
        >
          <View style={styles.row}>
            <Pressable
              onPress={() => {
                handleMeritPress(merit);
              }}
            >
              <TruncatedBody
                testProps={{
                  elementId: "meritName",
                  elementName: "MeritsListItem",
                  screenName: route.name,
                }}
              >
                {merit.name}
              </TruncatedBody>
            </Pressable>
          </View>
          <LineSeparator />
        </View>
      ))}
    </View>
  );
};
