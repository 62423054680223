import { Body, Heading, useTheme } from "@merit/frontend-components";
import { StyleSheet } from "react-native";
import { Trans } from "@lingui/macro";
import { useAccountMerit } from "@src/api/issuance";
import { useGetTestProps } from "@src/hooks";

const OnboardingMeritsFolioHeading = () => {
  const { theme } = useTheme();
  const getTestProps = useGetTestProps();
  const { data: accountMerit } = useAccountMerit();
  const styles = StyleSheet.create({
    headingText: {
      marginBottom: theme.spacing.xl,
      textAlign: "center",
    },
  });

  // lingui eslint plugin doesn't work gracefully for dereferencing objects with string literals
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const name = accountMerit?.fieldMap["Chosen Name"] ?? accountMerit?.fieldMap["First Name"] ?? "";

  return (
    <>
      <Heading
        bold
        level="1"
        style={styles.headingText}
        {...getTestProps({ elementId: "welcomeText", elementName: "OnboardingMeritsFolioHeading" })}
      >
        <Trans>Welcome, {name}!</Trans>
      </Heading>
      <Body
        size="l"
        style={styles.headingText}
        {...getTestProps({ elementId: "bodyText", elementName: "OnboardingMeritsFolioHeading" })}
      >
        <Trans>Accept your folio to get started.</Trans>
      </Body>
    </>
  );
};

export { OnboardingMeritsFolioHeading };
