/* eslint-disable lingui/no-unlocalized-strings */

import { Body, Button, Icon, useTheme } from "@merit/frontend-components";
import { FlatList, ScrollView, StyleSheet, View } from "react-native";
import { Header } from "@src/components";
import {
  checkForUpdateAsync,
  fetchUpdateAsync,
  readLogEntriesAsync,
  reloadAsync,
  useUpdates,
} from "expo-updates";
import { nativeApplicationVersion, nativeBuildVersion } from "expo-application";
import { useNavigation, useRoute } from "@src/hooks";
import { useState } from "react";
import Constants from "expo-constants";
import startCase from "lodash.startcase";
import type { TextStyle, ViewStyle } from "react-native";
import type { UpdatesLogEntry } from "expo-updates";

export const OTAInfo = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly contentContainer: ViewStyle;
    readonly horizontalLineSpacer: ViewStyle;
    readonly listItemContainer: ViewStyle;
    readonly listLabel: TextStyle;
    readonly listValue: TextStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    contentContainer: {
      padding: theme.spacing.xl,
    },
    horizontalLineSpacer: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      marginVertical: theme.spacing.l,
    },
    listItemContainer: {
      marginBottom: theme.spacing.xxl,
    },
    listLabel: {
      ...theme.fontSizes.l,
      fontWeight: theme.fontWeights.semiBold,
      marginBottom: theme.spacing.s,
    },
    listValue: {
      ...theme.fontSizes.m,
      paddingLeft: theme.spacing.s,
    },
  });

  const [logEntries, setLogEntries] = useState<
    Error | string | readonly UpdatesLogEntry[] | undefined
  >();
  const [isReadingLogs, setIsReadingLogs] = useState(false);

  const route = useRoute();
  const { goBack } = useNavigation();
  const {
    checkError,
    currentlyRunning,
    downloadError,
    initializationError,
    isChecking,
    isDownloading,
    isUpdateAvailable,
    isUpdatePending,
    lastCheckForUpdateTimeSinceRestart,
  } = useUpdates();

  const appVersion = nativeApplicationVersion ?? Constants.expoConfig?.version ?? "";
  const { channel, createdAt, isEmbeddedLaunch, isEmergencyLaunch, runtimeVersion, updateId } =
    currentlyRunning;

  const aboutInfo = {
    appVersion,
    build: nativeBuildVersion,
    channel,
    checkError,
    createdAt,
    downloadError,
    initializationError,
    isEmbeddedLaunch,
    isEmergencyLaunch,
    isUpdateAvailable,
    isUpdatePending,
    lastCheckForUpdateTimeSinceRestart,
    runtimeVersion,
    updateId,
  } as const;

  return (
    <View style={styles.container}>
      <Header
        leftElement={
          <Icon
            name="closeLargeAction"
            testProps={{ elementName: "closeModal", screenName: route.name }}
          />
        }
        leftOnPress={() => {
          goBack();
        }}
        title="About Merit"
        titleBold
        titleLevel="3"
      />
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <FlatList<readonly [string, unknown]>
          data={Object.entries(aboutInfo)}
          keyExtractor={([k]) => k}
          renderItem={({ item: [k, v] }) => (
            <View style={styles.listItemContainer}>
              <Body style={styles.listLabel}>{startCase(k)}</Body>
              <Body style={styles.listValue}>{String(v)}</Body>
            </View>
          )}
          scrollEnabled={false}
        />
        <View style={styles.horizontalLineSpacer} />
        <Body>Manually Check For Updates (only for real devices)</Body>
        <Button
          disabled={isChecking}
          onPress={() => {
            checkForUpdateAsync();
          }}
          text="Check For OTA Update Manually"
        />
        <Body>Last known check error: {String(checkError)}</Body>
        <Button
          disabled={!isUpdateAvailable || isDownloading}
          onPress={() => {
            fetchUpdateAsync();
          }}
          text="Download and Apply Update"
        />
        <Body>Last known download error: {String(downloadError)}</Body>
        <Button
          disabled={!isUpdatePending}
          onPress={() => {
            reloadAsync();
          }}
          text="Apply Pending Update"
        />
        <View style={styles.horizontalLineSpacer} />
        <Button
          disabled={isReadingLogs}
          onPress={async () => {
            setIsReadingLogs(true);
            setLogEntries(undefined);
            try {
              setLogEntries(await readLogEntriesAsync());
            } catch (err) {
              if (err instanceof Error) {
                setLogEntries(err);
              } else {
                setLogEntries("Error getting logs");
              }
            } finally {
              setIsReadingLogs(false);
            }
          }}
          text="Get Log Entries"
        />
        <FlatList<UpdatesLogEntry>
          ListEmptyComponent={
            <Body>
              Log entries will show up here. Last known error getting logs:{" "}
              {logEntries instanceof Array ? "none" : String(logEntries)}
            </Body>
          }
          data={logEntries instanceof Array ? logEntries : undefined}
          keyExtractor={item => String(item.timestamp)}
          renderItem={({ item }) => (
            <View style={styles.listItemContainer}>
              <Body style={styles.listLabel}>{new Date(item.timestamp).toISOString()}</Body>
              <Body style={styles.listValue}>{JSON.stringify(item, null, 2)}</Body>
            </View>
          )}
          scrollEnabled={false}
        />
      </ScrollView>
    </View>
  );
};
