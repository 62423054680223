import { Header, Icon as PEIcon } from "@src/components";
import { useNavigation } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";
import type { FC } from "react";

export type MainHeaderProps = {
  readonly title?: string;
  readonly showSearch?: boolean;
};

export const MainHeader: FC<MainHeaderProps> = ({ showSearch = false, title = "" }) => {
  const navigation = useNavigation();
  const { theme } = useTheme();

  const handleLeftOnPress = () => {
    navigation.toggleDrawer();
  };

  const handleRightOnPress = () => {
    navigation.navigate("Merits");
  };

  return (
    <Header
      leftElement={<PEIcon name="menu_left" size={theme.fontSizes.xl.fontSize} />}
      leftOnPress={handleLeftOnPress}
      rightElement={
        showSearch ? <PEIcon name="search" size={theme.fontSizes.xl.fontSize} /> : undefined
      }
      rightOnPress={handleRightOnPress}
      title={title}
    />
  );
};
