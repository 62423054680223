import { Notifications as DesktopNotifications } from "./Notifications.desktop";
import { Notifications as MobileNotifications } from "./Notifications.mobile";
import { useLayoutType } from "@src/hooks";

export const Notifications = () => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopNotifications />;
  }

  return <MobileNotifications />;
};
