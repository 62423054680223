import { Body, LineSeparator, useTheme } from "@merit/frontend-components";
import { CheckInDetail } from "./CheckInDetail";
import { SectionList, StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { getSectionsFromActivityTemplateFieldValue } from "@src/utils";
import { useGetTestProps } from "@src/hooks";
import type { ActivityTemplateFieldValue } from "@src/api/issuance/types";
import type { TextStyle, ViewStyle } from "react-native";

type MeritTabActivityProps = {
  readonly activityTemplateFieldValue: ActivityTemplateFieldValue;
};

export const MeritTabActivity = ({ activityTemplateFieldValue }: MeritTabActivityProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly emptyStateContainer: ViewStyle;
    readonly sectionFooterContainer: ViewStyle;
    readonly sectionHeaderContainer: ViewStyle;
    readonly sectionHeaderTitle: TextStyle;
  }>({
    emptyStateContainer: {
      backgroundColor: theme.colors.background.white,
      padding: theme.spacing.l,
    },
    sectionFooterContainer: {
      height: theme.spacing.s,
    },
    sectionHeaderContainer: {
      backgroundColor: theme.colors.background.white,
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.m,
    },
    sectionHeaderTitle: {
      ...theme.fontSizes.m,
      fontWeight: theme.fontWeights.semiBold,
    },
  });

  const getTestProps = useGetTestProps();

  return (
    <SectionList
      ItemSeparatorComponent={LineSeparator}
      ListEmptyComponent={() => (
        <View style={styles.emptyStateContainer}>
          <Body>
            <Trans>No activity to show</Trans>
          </Body>
        </View>
      )}
      keyExtractor={item => String(new Date(item.checkinTime).getTime())}
      renderItem={({ item }) => <CheckInDetail checkInDetail={item} />}
      renderSectionFooter={() => <View style={styles.sectionFooterContainer} />}
      renderSectionHeader={({ section }) => (
        <View
          style={styles.sectionHeaderContainer}
          {...getTestProps({
            elementId: section.title,
            elementName: "MeritTabActivitySectionHeader",
          })}
        >
          <Body style={styles.sectionHeaderTitle}>{section.title}</Body>
        </View>
      )}
      sections={getSectionsFromActivityTemplateFieldValue(activityTemplateFieldValue)}
    />
  );
};
