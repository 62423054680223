import { Home as DesktopHome } from "./Home.desktop";
import { Home as MobileHome } from "./Home.mobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";

export const Home: FC = () => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopHome />;
  }

  return <MobileHome />;
};
