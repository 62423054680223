import { Button, Heading, useTheme } from "@merit/frontend-components";
import { Icon as PEIcon } from "@src/components";
import { StyleSheet, View } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { useGetTestProps, useNavigation } from "@src/hooks";
import { useLingui } from "@lingui/react";
import type { ViewStyle } from "react-native";

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "PolicyRequestDeniedModal";

export const PolicyRequestDeniedModal = () => {
  const { theme } = useTheme();
  const navigator = useNavigation();
  const { _ } = useLingui();
  const getTestProps = useGetTestProps();

  const styles = StyleSheet.create<{
    readonly buttonContainer: ViewStyle;
    readonly container: ViewStyle;
    readonly contentContainer: ViewStyle;
  }>({
    buttonContainer: {
      alignSelf: "stretch",
    },
    container: {
      alignItems: "center",
      flex: 1,
      justifyContent: "space-between",
      padding: theme.spacing.xl,
    },
    contentContainer: {
      alignItems: "center",
      flex: 1,
      gap: theme.spacing.m,
    },
  });

  const handlePressClose = () => {
    navigator.navigate("Home");
  };

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <PEIcon color={theme.colors.icon.alert.critical} name="report_outlined" size={200} />
        <Heading level="3" {...getTestProps({ elementName }, { componentName: "heading" })}>
          <Trans>You denied the verification request</Trans>
        </Heading>
      </View>
      <View style={styles.buttonContainer}>
        <Button
          onPress={handlePressClose}
          text={_(msg`Close`)}
          type="primary"
          {...getTestProps({ elementName }, { componentName: "closeButton" })}
        />
      </View>
    </View>
  );
};
