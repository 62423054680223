import { Body, Icon, useTheme } from "@merit/frontend-components";
import { Pressable, StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import type { CommonProps } from "../types";
import type { IconName } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

type LoggedInDrawerItemProps = CommonProps & {
  readonly iconName: IconName;
  readonly onPress: () => void;
  readonly text: string;
};

const LoggedInDrawerItem = ({ elementId, iconName, onPress, text }: LoggedInDrawerItemProps) => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly iconWrapper: ViewStyle;
  }>({
    container: {
      alignItems: "center",
      flexDirection: "row",
      paddingVertical: theme.spacing.l,
    },
    iconWrapper: {
      marginRight: theme.spacing.l,
    },
  });

  return (
    <Pressable
      onPress={onPress}
      style={styles.container}
      {...getTestProps({
        elementId,
        elementName: "LoggedInDrawerItem",
      })}
    >
      <View style={styles.iconWrapper}>
        <Icon name={iconName} />
      </View>
      <Body size="l">{text}</Body>
    </Pressable>
  );
};

export { LoggedInDrawerItem };
