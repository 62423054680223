import { Body, Heading, useTheme } from "@merit/frontend-components";
import { MFAEnroll } from "./MFAEnroll";
import { MFAReset } from "./MFAReset";
import { StyleSheet, View } from "react-native";
import { TextSkeleton } from "@src/components";
import { Trans } from "@lingui/macro";
import { useGetMFAEnrollments } from "@src/api/person-experience-backend";
import { useGetTestProps } from "@src/hooks";
import type { TextStyle } from "react-native";

export const MultiFactorAuthentication = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly heading: TextStyle;
  }>({
    heading: {
      ...theme.fontSizes.l,
    },
  });

  const getTestProps = useGetTestProps();
  const { data, isError, isFetched, isRefetching, refetch } = useGetMFAEnrollments();

  const content = (() => {
    if (!isFetched || isRefetching) {
      return <TextSkeleton />;
    }

    if (isError) {
      return (
        <Body>
          <Trans>Error loading Multi-factor Authentication settings</Trans>
        </Body>
      );
    }

    return data?.enrollments.length === 0 ? (
      <MFAEnroll
        onPressRefreshStatus={() => {
          refetch();
        }}
      />
    ) : (
      <MFAReset
        onSuccessMFAReset={() => {
          refetch();
        }}
      />
    );
  })();

  return (
    <View
      {...getTestProps({
        elementName: "MultiFactorAuthentication",
      })}
    >
      <Heading level="1" style={styles.heading}>
        <Trans>Multi-factor Authentication</Trans>
      </Heading>
      {content}
    </View>
  );
};
