import { Body } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import type { ViewStyle } from "react-native";

export const EmptyState = () => {
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
  }>({
    container: {
      alignItems: "center",
      flex: 1,
      justifyContent: "center",
    },
  });

  return (
    <View style={styles.container}>
      <Body>
        <Trans>Uh-oh, we were unable to get details about your Merit.</Trans>
      </Body>
    </View>
  );
};
