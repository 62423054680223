import { ConnectedAppButton } from "./ConnectedAppButton";
import { Heading, LineSeparator, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { useConnectedApps } from "./useConnectedApps";
import { useGetTestProps } from "@src/hooks";

export const ConnectedAppsDesktop = () => {
  const { theme } = useTheme();
  const { apps } = useConnectedApps();
  const getTestProps = useGetTestProps();

  const styles = StyleSheet.create({
    buttonsContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: theme.spacing.xl,
      justifyContent: "flex-start",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
    },
    heading: {
      paddingVertical: theme.spacing.xxl,
    },
  });

  if (apps.length === 0) {
    return null;
  }

  return (
    <View>
      <View style={styles.heading}>
        <Heading
          bold
          level="3"
          {...getTestProps({
            elementId: "heading",
            elementName: "ConnectedApps",
          })}
        >
          <Trans context="title">Connected Apps</Trans>
        </Heading>
      </View>
      <LineSeparator />
      <View style={styles.buttonsContainer}>
        {apps.map(app => (
          <ConnectedAppButton key={app.id} {...app} />
        ))}
      </View>
    </View>
  );
};
