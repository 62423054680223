import { Configuration as ConfigurationCls, SignedEntityApi } from "@merit/issuance-client";
import { getLocalConfig } from "@src/utils";
import { useMeritAuth0 } from "@src/hooks";

const config = getLocalConfig();

type UseSignedEntityApiReturn = {
  readonly api: SignedEntityApi;
  readonly isReady: boolean;
};

const useSignedEntityApi = (): UseSignedEntityApiReturn => {
  const { accessToken } = useMeritAuth0();

  return {
    api: new SignedEntityApi(
      new ConfigurationCls({
        ...config.api.issuance,
        headers: {
          ...config.api.issuance.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ),
    isReady: accessToken !== "",
  };
};

export { useSignedEntityApi };
