import { Body, Icon, LineSeparator, useTheme } from "@merit/frontend-components";
import { DestructiveActionConfirmation } from "../DestructiveActionConfirmation";
import { Pressable, StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useState } from "react";
import Modal from "react-native-modal";
import type { IconName } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

enum MenuOptionType {
  "EditMerits" = "edit-merits",
  "EditPersonaName" = "edit-persona-name",
  "Delete" = "delete",
}

type MenuOption = {
  readonly label: string;
  readonly icon: IconName;
  readonly type: MenuOptionType;
};

const MENU_OPTIONS: readonly MenuOption[] = [
  {
    icon: "verifiedMeritMediumDefault1",
    label: "Edit Merits",
    type: MenuOptionType.EditMerits,
  },
  {
    icon: "editMediumLink",
    label: "Edit Persona Name",
    type: MenuOptionType.EditPersonaName,
  },
  {
    icon: "trash",
    label: "Delete",
    type: MenuOptionType.Delete,
  },
];

type PersonaDetailsEditMenuProps = {
  readonly handleCancel: () => void;
  readonly isVisible: boolean;
};

const PersonaDetailsEditMenu = ({ handleCancel, isVisible }: PersonaDetailsEditMenuProps) => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const { bottom: bottomSafeInset } = useSafeAreaInsets();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const styles = StyleSheet.create<{
    readonly menuOptionsContainer: ViewStyle;
    readonly menuOption: ViewStyle;
    readonly contentContainer: ViewStyle;
    readonly modalContainer: ViewStyle;
    readonly menuOptionIcon: ViewStyle;
    readonly headerContainer: ViewStyle;
  }>({
    contentContainer: {
      backgroundColor: theme.colors.background.white,
      padding: theme.spacing.l,
      paddingBottom: Math.max(theme.spacing.xxl, bottomSafeInset),
    },
    headerContainer: {
      display: "flex",
      padding: theme.spacing.l,
    },
    menuOption: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      padding: theme.spacing.l,
    },
    menuOptionIcon: {
      paddingRight: theme.spacing.l,
    },
    menuOptionsContainer: {
      display: "flex",
    },
    modalContainer: {
      justifyContent: "flex-end",
      margin: 0,
    },
  });

  return (
    <>
      <Modal
        isVisible={isVisible}
        onBackButtonPress={() => {
          handleCancel();
        }}
        onSwipeComplete={() => {
          handleCancel();
        }}
        style={styles.modalContainer}
        swipeDirection="down"
      >
        <View
          style={styles.contentContainer}
          {...getTestProps({
            elementName: "PersonaDetailsEditMenu",
          })}
        >
          <View style={styles.headerContainer}>
            <Pressable
              onPress={() => {
                handleCancel();
              }}
            >
              <Icon name="closeLargeAction" />
            </Pressable>
          </View>
          <View style={styles.menuOptionsContainer}>
            {MENU_OPTIONS.map(menuOption => (
              <View key={menuOption.label + menuOption.icon}>
                <Pressable
                  onPress={() => {
                    if (menuOption.type === MenuOptionType.Delete) {
                      setShowDeleteModal(true);
                    } else {
                      // TODO: Actually handle these
                      handleCancel();
                    }
                  }}
                >
                  <View style={styles.menuOption}>
                    <View style={styles.menuOptionIcon}>
                      <Icon name={menuOption.icon} />
                    </View>
                    <Body>{menuOption.label}</Body>
                  </View>
                </Pressable>
                <LineSeparator />
              </View>
            ))}
          </View>
        </View>
      </Modal>
      <DestructiveActionConfirmation
        isVisible={showDeleteModal}
        onPressCancel={() => {
          setShowDeleteModal(false);
        }}
        onPressRemove={() => {
          // TODO: Actually handle these
        }}
        textSet={{
          body: "Deleting this persona will remove it from all organizations it is currently shared with.",
          button: "Delete",
          heading: "Are you sure?",
          verbPastTense: "deleted",
          verbProgressiveTense: "deleting",
        }}
      />
    </>
  );
};

export { PersonaDetailsEditMenu };
