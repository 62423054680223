import { Helpers } from "@merit/frontend-utils";
import { Log, findAndMerge } from "@src/utils";
import { PatchContainerStateEnum } from "@merit/issuance-client";
import { msg } from "@lingui/macro";
import { useAlerts } from "@src/hooks";
import { useContainersApi } from "./useContainersApi";
import { useLingui } from "@lingui/react";
import { useMerits, useMeritsQueryKey } from "./useMerits";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotificationsController } from "@src/screens/Home/components/Notifications";
import type { Merit } from "./types";
import type { ResponseError } from "@merit/issuance-client";

// TODO: extract into a common MutationOptions type for other hooks
type Options = {
  readonly showErrorToast: boolean;
  readonly showMutatingToast: boolean;
  readonly showSuccessToast: boolean;
};

const defaultOptions: Options = {
  showErrorToast: true,
  showMutatingToast: true,
  showSuccessToast: true,
};

export const useAcceptMerit = (options: Partial<Options> = defaultOptions) => {
  const { api: containersApi } = useContainersApi();
  const { sendAlert } = useAlerts();
  const { refetch } = useMerits();
  const queryKey = useMeritsQueryKey();
  const queryClient = useQueryClient();
  const { refreshNotifications } = useNotificationsController();
  const { _ } = useLingui();

  return useMutation({
    mutationFn: (meritId: Merit["id"]) =>
      containersApi.patchContainer({
        containerID: meritId,
        properties: {
          state: PatchContainerStateEnum.Accepted,
        },
      }),
    onError: async (err, meritId) => {
      const rawError = await (err as Partial<ResponseError> | undefined)?.response?.json();
      Log.error("Error accepting merit", { error: rawError ?? err, meritId });
      if (options.showErrorToast === true) {
        if (Helpers.Some(rawError)) {
          sendAlert({
            id: "useAcceptMerit-Error",
            text1: _(msg`Error accepting your merit`),
            text2: String(rawError.errors[0]).includes("revoked")
              ? _(msg`The merit you are trying to accept has been revoked`)
              : undefined,
            type: "error",
          });
        } else {
          sendAlert({
            id: "useAcceptMerit-Error",
            text1: _(msg`Error accepting your merit`),
            text2: String(err),
            type: "error",
          });
        }
      }
    },
    onMutate: async meritId => {
      if (options.showMutatingToast === true) {
        sendAlert({
          id: "useAcceptMerit-Accepting",
          text1: _(msg`Accepting…`),
          type: "info",
        });
      }

      // Cancel any outgoing refetches, so they don't overwrite our optimistic update
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const merits: readonly Merit[] | undefined = queryClient.getQueryData(queryKey);

      if (merits === undefined) {
        return undefined;
      }

      const newMerits = findAndMerge(merits, m => m.id === meritId, {
        state: { name: PatchContainerStateEnum.Accepted, occurredAt: new Date().toISOString() },
      });

      if (newMerits === undefined) {
        return undefined;
      }

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, newMerits);

      // Return a context object with the snapshotted value
      return { merits };
    },
    onSettled: () => {
      refetch();
    },
    onSuccess: () => {
      if (options.showSuccessToast === true) {
        sendAlert({
          id: `useAcceptMerit-Success`,
          text1: _(msg`Your merit has been accepted`),
          type: "success",
        });
      }
      refreshNotifications();
    },
  });
};
