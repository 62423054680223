import { Button, Modal, useTheme } from "@merit/frontend-components";
import { Constants } from "@src/utils";
import { StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import { useLingui } from "@lingui/react";
import type { ViewStyle } from "react-native";

type SkipOnboardingConfirmationProps = {
  readonly onConfirm: () => void;
  readonly onCancel: () => void;
};

export const SkipOnboardingConfirmationModal = ({
  onCancel,
  onConfirm,
}: SkipOnboardingConfirmationProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly actionsContainer: ViewStyle;
    readonly cancelButtonContainer: ViewStyle;
  }>({
    actionsContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    cancelButtonContainer: {
      marginRight: theme.spacing.s,
    },
  });

  const getTestProps = useGetTestProps();
  const { _ } = useLingui();

  return (
    <Modal
      maxWidth={Constants.Sizes.MODAL}
      onClose={onCancel}
      title={_(msg`Are you sure you want to skip and go to app?`)}
      width="100%"
    >
      <View style={styles.actionsContainer}>
        <View style={styles.cancelButtonContainer}>
          <Button
            onPress={onCancel}
            text={_(msg({ context: "action", message: "Cancel" }))}
            type="secondary"
            {...getTestProps({
              elementId: "cancelButton",
              elementName: "SkipOnboardingConfirmationModal",
            })}
          />
        </View>
        <Button
          onPress={onConfirm}
          text={_(msg({ context: "action", message: "Skip" }))}
          type="destructive"
          {...getTestProps({
            elementId: "skipButton",
            elementName: "SkipOnboardingConfirmationModal",
          })}
        />
      </View>
    </Modal>
  );
};
