import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Icon as PEIcon } from "@src/components";
import { Pressable, StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { useAcceptMerit } from "@src/api/issuance";
import { useGetTestProps, useNavigation, useRoute } from "@src/hooks";
import type { FC } from "react";
import type { NotificationMeritProps } from "./types";
import type { TextStyle, ViewStyle } from "react-native";

const COUNTER_SIZE = 24;
const ICON_SIZE = 20; // this is the same size as the FE components <Icon />

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "NotificationMerit";

export const NotificationMerit: FC<NotificationMeritProps> = ({ counterValue, merit }) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly actionContainer: ViewStyle;
    readonly actionText: TextStyle;
    readonly bold: TextStyle;
    readonly container: ViewStyle;
    readonly counterContainer: ViewStyle;
    readonly counterText: TextStyle;
    readonly marginBottom: ViewStyle;
    readonly marginRight: ViewStyle;
    readonly row: ViewStyle;
  }>({
    actionContainer: {
      alignItems: "center",
      flexDirection: "row",
      paddingLeft: theme.spacing.s,
    },
    actionText: {
      color: theme.colors.brand.oceanBlue,
      fontWeight: theme.fontWeights.semiBold,
    },
    bold: {
      fontWeight: theme.fontWeights.semiBold,
    },
    container: {
      flex: 1,
      justifyContent: "space-between",
      padding: theme.spacing.m,
    },
    counterContainer: {
      alignItems: "center",
      backgroundColor: theme.colors.interactive.critical.default,
      borderRadius: COUNTER_SIZE / 2,
      height: COUNTER_SIZE,
      justifyContent: "center",
      width: COUNTER_SIZE,
    },
    counterText: {
      color: theme.colors.background.white,
      fontWeight: theme.fontWeights.semiBold,
    },
    marginBottom: {
      marginBottom: theme.spacing.m,
    },
    marginRight: {
      marginRight: theme.spacing.m,
    },
    row: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  });

  const getTestProps = useGetTestProps();
  const navigation = useNavigation();
  const route = useRoute();
  const acceptMerit = useAcceptMerit();

  const onPressNotification = () => {
    navigation.navigate("MeritDetails", { meritId: merit.id });
  };

  const onPressAccept = () => {
    acceptMerit.mutateAsync(merit.id);
  };

  const meritName = merit.name ?? "";
  const orgName = merit.transformedFields.orgName;

  return (
    <Pressable
      onPress={onPressNotification}
      style={styles.container}
      {...getTestProps({ elementId: merit.id, elementName }, { componentName: "Container" })}
    >
      <Heading
        level="2"
        testProps={{
          elementId: "notificationHeading",
          elementName,
          screenName: route.name,
        }}
      >
        <Trans>You received a merit</Trans>
      </Heading>
      <Body
        numberOfLines={3}
        style={styles.marginBottom}
        testProps={{
          elementId: "notificationDescription",
          elementName,
          screenName: route.name,
        }}
      >
        <Trans>
          <Body style={styles.bold}>{orgName}</Body> sent you a merit:{" "}
          <Body style={styles.bold}>{meritName}</Body>
        </Trans>
      </Body>
      <View style={styles.row}>
        <Pressable
          hitSlop={theme.spacing.l}
          onPress={onPressAccept}
          style={styles.actionContainer}
          {...getTestProps({ elementId: merit.id, elementName }, { componentName: "AcceptButton" })}
        >
          <View style={styles.marginRight}>
            <PEIcon color={theme.colors.brand.oceanBlue} name="notifications" size={ICON_SIZE} />
          </View>
          <Body style={[styles.actionText, styles.marginRight]} uppercase>
            <Trans context="action">tap to accept</Trans>
          </Body>
          <PEIcon color={theme.colors.brand.oceanBlue} name="chevron_right" size={ICON_SIZE} />
        </Pressable>
        <View style={styles.counterContainer}>
          <Body
            style={styles.counterText}
            testProps={{
              elementId: "notificationsCountLeft",
              elementName,
              screenName: route.name,
            }}
          >
            {counterValue}
          </Body>
        </View>
      </View>
    </Pressable>
  );
};
