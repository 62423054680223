import type { MeritFilter } from "./types";

type ContainsText = (text: string) => MeritFilter;

const ISSUING_ORG_NAME_KEY = "Issuing Org Name";
export const containsText: ContainsText = searchText => merit => {
  // eslint-disable-next-line no-underscore-dangle
  const _searchText = searchText.toLocaleLowerCase().trim();

  if (merit.name?.toLocaleLowerCase().includes(_searchText) ?? false) {
    return true;
  }

  if (merit.fieldMap[ISSUING_ORG_NAME_KEY].toLocaleLowerCase().includes(_searchText)) {
    return true;
  }

  if (merit.transformedFields.orgName.toLocaleLowerCase().includes(_searchText)) {
    return true;
  }

  return false;
};
