import { ConsoleLogger } from "./ConsoleLogger";
import { DdLogs } from "expo-datadog";
import { getLocalConfig } from "../getLocalConfig/getLocalConfig";
import type { Logger } from "./types";

const config = getLocalConfig();
const getLogger = (): PublicInterface<typeof DdLogs> => {
  if (config.datadog.enabled) {
    return DdLogs;
  }

  return ConsoleLogger;
};

const InternalLog = getLogger();
export const Log: Logger = InternalLog;

type ContextValue =
  | Date
  | boolean
  | number
  | string
  | readonly ContextValue[]
  | { readonly [key: string]: ContextValue }
  | null
  | undefined;
const globalContext: Record<string, ContextValue> = {};

const setGlobalContext = () => {
  InternalLog.registerLogEventMapper(logEvent => {
    // eslint-disable-next-line functional/immutable-data, no-param-reassign
    logEvent.context = {
      ...globalContext,
      ...logEvent.context,
    };

    return logEvent;
  });
};

export const addGlobalContext = (key: string, value: ContextValue) => {
  // eslint-disable-next-line functional/immutable-data
  globalContext[key] = value;
  setGlobalContext();
};

export const removeGlobalContext = (key: string) => {
  // eslint-disable-next-line functional/immutable-data
  globalContext[key] = undefined;
  setGlobalContext();
};
