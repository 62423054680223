/* eslint-disable functional/no-this-expression, functional/no-class */
import { Body, Link } from "@merit/frontend-components";
import { Renderer } from "react-native-marked";
import { openExternalLink } from "@src/utils";
import type { ReactNode } from "react";
import type { RendererInterface } from "react-native-marked";
import type { TextStyle } from "react-native";

class MeritMarkdownRenderer extends Renderer implements RendererInterface {
  public link(
    children: string | readonly ReactNode[],
    href: string,
    _?: TextStyle | undefined
  ): ReactNode {
    return (
      <Link key={this.getKey()} onPress={() => openExternalLink(href)}>
        {children}
      </Link>
    );
  }

  public text(text: string | readonly ReactNode[], styles?: TextStyle | undefined): ReactNode {
    return (
      <Body key={this.getKey()} style={styles}>
        {text}
      </Body>
    );
  }
}

export { MeritMarkdownRenderer };
