import { deduplicate, hasState, isNotUndefined } from "@src/utils";
import { getAppDetails } from "./utils";
import { useConfig } from "@src/hooks";
import { useMemo } from "react";
import { useMerits } from "@src/api/issuance";
import type { AppDetails } from "./types";

type UseConnectedApps = () => {
  readonly apps: readonly AppDetails[];
};

export const useConnectedApps: UseConnectedApps = () => {
  const { data: merits } = useMerits();
  const config = useConfig();

  const apps = useMemo(() => {
    if (merits === undefined) {
      return [];
    }

    return merits
      .filter(hasState(["accepted"]))
      .map(merit => getAppDetails(merit, config))
      .filter(isNotUndefined)
      .filter(deduplicate(app => app.id));
  }, [merits, config]);

  return {
    apps,
  };
};
