import { MeritListItemSkeleton as DesktopMeritListItemSkeleton } from "./MeritListItemSkeleton.desktop";
import { MeritListItemSkeleton as MobileMeritListItemSkeleton } from "./MeritListItemSkeleton.mobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";

export const MeritListItemSkeleton: FC = props => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopMeritListItemSkeleton {...props} />;
  }

  return <MobileMeritListItemSkeleton {...props} />;
};
