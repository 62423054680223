import { fromString } from "durational";
import type { Duration } from "durational";

export const getFormattedString = (datetime: Date, timeZone?: string) => {
  const time = Intl.DateTimeFormat("default", {
    hour: "2-digit",
    hour12: false,
    minute: "2-digit",
    timeZone,
  }).format(datetime);
  const date = Intl.DateTimeFormat("default", {
    day: "2-digit",
    month: "2-digit",
    timeZone,
    year: "2-digit",
  }).format(datetime);

  return `${time} ${date}`;
};

export const getDurationStringFromDates = (start: Date, end: Date) => {
  if (start > end) {
    return undefined;
  }

  const durationSeconds = Math.floor((end.getTime() - start.getTime()) / 1000);
  const hours = Math.floor(durationSeconds / 60 / 60);
  const remainingMinutes = Math.floor((durationSeconds - hours * 60 * 60) / 60);

  const hoursPart = `${hours}${hours === 1 ? "hr" : "hrs"}`;
  const minutesPart = `${remainingMinutes}${remainingMinutes === 1 ? "min" : "mins"}`;

  return `${hoursPart} ${minutesPart}`;
};

type DurationPart = {
  readonly prettyString: string;
  readonly value: number;
};

// see Durations section of https://datatracker.ietf.org/doc/html/rfc3339#appendix-A
export const getDurationStringFromRFC3339Duration = (
  duration: string,
  precision: keyof Duration = "minutes"
): string | undefined => {
  const parsed = (() => {
    try {
      return fromString(duration);
    } catch (err) {
      return undefined;
    }
  })();
  if (parsed === undefined) {
    return undefined;
  }
  const { hours, minutes, seconds } = parsed;
  const hoursPart: DurationPart = {
    prettyString: `${hours}hr${hours === 1 ? "" : "s"}`,
    value: hours,
  };
  const minutesPart: DurationPart = {
    prettyString: `${minutes}min${minutes === 1 ? "" : "s"}`,
    value: minutes,
  };
  const secondsPart: DurationPart = {
    prettyString: `${seconds}sec${seconds === 1 ? "" : "s"}`,
    value: seconds,
  };
  const arranged = (() => {
    switch (precision) {
      case "hours":
        return [hoursPart];
      case "minutes":
        return [hoursPart, minutesPart];
      case "seconds":
      default:
        return [hoursPart, minutesPart, secondsPart];
    }
  })();
  // omit any "leading zeroes", up to first significant digit
  // example for seconds precision: PT0H1M0S => 1min 0secs; PT0H0M1S => 1sec, PT0H0M0S => 0secs
  // analogous to math, where "0001" => 1, "0000" => 0, "00010" => 10
  const { arr: leadingZeroesOmitted } = arranged.reduce(
    ({ arr, hasFoundNonZero }, part, index, theArray) =>
      hasFoundNonZero || index === theArray.length - 1 || part.value > 0
        ? {
            arr: [...arr, part],
            hasFoundNonZero: true,
          }
        : {
            arr,
            hasFoundNonZero,
          },
    { arr: [] as readonly DurationPart[], hasFoundNonZero: false }
  );
  const stringified = leadingZeroesOmitted.map(part => part.prettyString).join(" ");

  return stringified;
};
