import { Settings } from "./Settings";
import { SettingsDesktop } from "./SettingsDesktop";
import { useLayoutType } from "@src/hooks";
import type { SettingsProps } from "./types";

const LayoutSettings = ({ ...props }: SettingsProps) => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <SettingsDesktop {...props} />;
  }

  return <Settings {...props} />;
};

export { LayoutSettings as Settings };
