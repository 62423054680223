/* eslint-disable lingui/no-unlocalized-strings */

import { hasState } from "@src/utils";
import { useCallback, useEffect } from "react";
import { useFeatureFlags } from "@src/hooks";
import { useMerits, useRequestedPolicyRequests } from "@src/api/issuance";
import { useNotificationsStore } from "./notificationsStore";
import type { Merit, PolicyRequest } from "@src/api/issuance";
import type { MeritNotification, Notification, PolicyRequestNotification } from "./types";

type UseNotificationsController = () => {
  readonly notifications: readonly Notification[];
  readonly refreshNotifications: () => Promise<void>;
  readonly isFetching: boolean;
  readonly dismissNotification: (notificationId: Notification["id"]) => void;
};

export const useNotificationsController: UseNotificationsController = () => {
  const meritsQuery = useMerits();
  const requestedPolicyRequestsQuery = useRequestedPolicyRequests();
  const featureFlags = useFeatureFlags();

  const initialize = useNotificationsStore(store => store.initialize);
  const refresh = useNotificationsStore(store => store.refresh);
  const notifications = useNotificationsStore(store => store.notifications);
  const dismissNotification = useNotificationsStore(store => store.dismissNotification);

  const getMeritNotifications = useCallback(
    (merits?: readonly Merit[]): readonly MeritNotification[] =>
      merits?.filter(hasState(["pending"])).map(merit => ({
        data: merit,
        id: merit.id,
        kind: "MeritNotification",
      })) ?? [],
    []
  );

  const getPolicyRequestNotifications = useCallback(
    (policyRequests?: readonly PolicyRequest[]): readonly PolicyRequestNotification[] =>
      policyRequests?.map(policyRequest => ({
        data: policyRequest,
        id: policyRequest.policy,
        kind: "PolicyRequestNotification",
      })) ?? [],
    []
  );

  // Initialize the store
  useEffect(() => {
    if (!meritsQuery.isFetched || !requestedPolicyRequestsQuery.isFetched) {
      return;
    }

    const meritNotifications = getMeritNotifications(meritsQuery.data);
    const policyRequestNotifications = getPolicyRequestNotifications(
      requestedPolicyRequestsQuery.data
    );

    initialize([...policyRequestNotifications, ...meritNotifications]);
  }, [
    initialize,
    getMeritNotifications,
    getPolicyRequestNotifications,
    meritsQuery.isFetched,
    meritsQuery.data,
    requestedPolicyRequestsQuery.isFetched,
    requestedPolicyRequestsQuery.data,
    featureFlags.showMemberAppVerifications,
  ]);

  const refreshNotifications = useCallback(async () => {
    const [meritsQueryResult, requestedPolicyRequestsQueryResult] = await Promise.all([
      meritsQuery.refetch(),
      requestedPolicyRequestsQuery.refetch(),
    ]);

    const meritNotifications = getMeritNotifications(meritsQueryResult.data);
    const policyRequestNotifications = getPolicyRequestNotifications(
      requestedPolicyRequestsQueryResult.data
    );

    refresh([...policyRequestNotifications, ...meritNotifications]);
  }, [
    getMeritNotifications,
    getPolicyRequestNotifications,
    meritsQuery,
    requestedPolicyRequestsQuery,
    refresh,
  ]);

  return {
    dismissNotification,
    isFetching: meritsQuery.isFetching || requestedPolicyRequestsQuery.isFetching,
    notifications,
    refreshNotifications,
  };
};
