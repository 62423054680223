import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { useMeritAuth0 } from "@src/hooks";
import type { FCWithChildren } from "@src/types/component";

export const DdScreenReplayProvider: FCWithChildren = ({ children }) => {
  const { isAuthenticated, user } = useMeritAuth0();

  useEffect(() => {
    datadogRum.startSessionReplayRecording();

    return () => {
      datadogRum.stopSessionReplayRecording();
    };
  }, []);

  // TODO: is there an equivalent of this for the native lib?
  useEffect(() => {
    if (isAuthenticated && user !== null) {
      datadogRum.setUser({
        id: user.entityID,
      });
    }

    return () => {
      datadogRum.clearUser();
    };
  }, [isAuthenticated, user]);

  return <>{children}</>;
};
