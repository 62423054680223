import { Button, useTheme } from "@merit/frontend-components";
import { PersonalQrCode } from "../PersonalQrCode";
import { PersonalQrCodeHeader } from "../PersonalQrCodeHeader";
import { Modal as RNModal, StyleSheet, View } from "react-native";
import { useGetTestProps, useRoute } from "@src/hooks";
import { useRef, useState } from "react";
import type { PersonalQrCodeHandle } from "../PersonalQrCode";
import type { StyleProp, ViewStyle } from "react-native";

const MODAL_MAX_WIDTH = 390;

type PersonalQrCodeButtonProps = {
  readonly wrapperStyle?: StyleProp<ViewStyle>;
};

export const PersonalQrCodeButton = ({ wrapperStyle }: PersonalQrCodeButtonProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly dimmer: ViewStyle;
    readonly modalContentContainer: ViewStyle;
    readonly qrCodeContainer: ViewStyle;
  }>({
    dimmer: {
      alignItems: "center",
      backgroundColor: "rgba(59, 71, 96, 0.7)",
      // TODO: neutral800, but transparency isn't themed yet
      flex: 1,
      justifyContent: "center",
    },
    modalContentContainer: {
      ...theme.elevations.depth5,
      backgroundColor: theme.colors.background.white,
      borderRadius: theme.borderRadii.xl,
      maxWidth: MODAL_MAX_WIDTH,
      paddingVertical: theme.spacing.xxl,
    },
    qrCodeContainer: {
      flex: 1,
      paddingHorizontal: theme.spacing.xxl,
    },
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const qrCodeRef = useRef<PersonalQrCodeHandle>(null);
  const route = useRoute();
  const getTestProps = useGetTestProps();

  return (
    <View
      style={wrapperStyle}
      {...getTestProps({
        elementId: "wrapper",
        elementName: "PersonalQrCodeButton",
      })}
    >
      <Button
        iconLeft="qrCodeLargeAction"
        onPress={() => {
          setIsModalVisible(true);
        }}
        shape="square"
        testProps={{
          elementId: "button",
          elementName: "PersonalQrCodeButton",
          screenName: route.name,
        }}
        type="secondary"
      />
      {isModalVisible ? (
        // TODO (PE-791): improve existing FE-components Modal to either have better header or allow custom headers (optionally omit built-in header)
        <RNModal
          transparent
          {...getTestProps({
            elementId: "modal",
            elementName: "PersonalQrCodeButton",
          })}
        >
          <View style={styles.dimmer}>
            <View
              style={styles.modalContentContainer}
              {...getTestProps({
                elementId: "modalContent",
                elementName: "PersonalQrCodeButton",
              })}
            >
              <PersonalQrCodeHeader
                onPressClose={() => {
                  setIsModalVisible(false);
                }}
                onPressRegenerate={() => {
                  qrCodeRef.current?.regenerate();
                }}
              />
              <PersonalQrCode ref={qrCodeRef} style={styles.qrCodeContainer} />
            </View>
          </View>
        </RNModal>
      ) : null}
    </View>
  );
};
