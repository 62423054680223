/* eslint-disable lingui/no-unlocalized-strings */

import { BULLET_POINT } from "@src/utils/constants/unicode";
import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { Icon as PEIcon } from "@src/components";
import { ScrollView, StyleSheet, View } from "react-native";
import { TemplateFieldErrorsListItem } from "./TemplateFieldErrorsListItem";
import { Trans, msg } from "@lingui/macro";
import { getPolicyRequestErrors } from "../utils";
import { useGetTestProps, useNavigation } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useTemplateIdsByTemplateFieldIdController } from "../hooks";
import type { FC } from "react";
import type { PolicyRequest } from "@src/api/issuance";
import type { TextStyle, ViewStyle } from "react-native";

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "PolicyRequestApprovedModal";

type PartialPolicyRequest = Pick<PolicyRequest, "errorResults" | "result">;

export type PolicyRequestApprovedModalProps = {
  readonly policyRequest: PartialPolicyRequest;
};

export const PolicyRequestApprovedModal: FC<PolicyRequestApprovedModalProps> = ({
  policyRequest,
}) => {
  const { theme } = useTheme();
  const navigator = useNavigation();
  const { _ } = useLingui();
  const getTestProps = useGetTestProps();
  const { templateIdsByTemplateFieldId } = useTemplateIdsByTemplateFieldIdController();

  const styles = StyleSheet.create<{
    readonly actionText: TextStyle;
    readonly buttonContainer: ViewStyle;
    readonly container: ViewStyle;
    readonly contentContainer: ViewStyle;
    readonly errorsContainer: ViewStyle;
  }>({
    actionText: {
      alignSelf: "flex-start",
    },
    buttonContainer: {
      alignSelf: "stretch",
      marginTop: theme.spacing.m,
    },
    container: {
      alignItems: "center",
      flex: 1,
      justifyContent: "space-between",
      padding: theme.spacing.xl,
    },
    contentContainer: {
      alignItems: "center",
      flex: 1,
      gap: theme.spacing.m,
    },
    errorsContainer: {
      paddingLeft: theme.spacing.m,
    },
  });

  const handlePressClose = () => {
    navigator.navigate("Home");
  };

  if (policyRequest.result === false) {
    const policyRequestErrors = getPolicyRequestErrors({
      policyRequest,
      templateIdsByTemplateFieldId,
    });

    return (
      <View style={styles.container}>
        <ScrollView
          contentContainerStyle={styles.contentContainer}
          showsVerticalScrollIndicator={false}
        >
          <PEIcon color={theme.colors.brand.citrine} name="warning" size={200} />
          <Heading level="3" {...getTestProps({ elementName }, { componentName: "heading" })}>
            <Trans>You cannot be verified</Trans>
          </Heading>
          {policyRequestErrors !== undefined && (
            <View>
              <Heading
                level="5"
                {...getTestProps({ elementName }, { componentName: "reasonsHeading" })}
              >
                <Trans>Reasons</Trans>:
              </Heading>
              <View style={styles.errorsContainer}>
                {policyRequestErrors.isMissingRequiredMerits && (
                  <Body>
                    {BULLET_POINT} <Trans>One or more required merits are missing</Trans>
                  </Body>
                )}
                {Object.entries(policyRequestErrors.fieldFailuresByTemplateId).map(
                  ([templateId, templateFieldIds]) => (
                    <TemplateFieldErrorsListItem
                      key={templateId}
                      templateFieldIds={templateFieldIds}
                      templateId={templateId}
                    />
                  )
                )}
              </View>
            </View>
          )}
          <Body
            style={styles.actionText}
            {...getTestProps({ elementName }, { componentName: "actionBody" })}
          >
            <Trans>Please contact your administrator</Trans>
          </Body>
        </ScrollView>
        <View style={styles.buttonContainer}>
          <Button
            onPress={handlePressClose}
            text={_(msg`Close`)}
            type="primary"
            {...getTestProps({ elementName }, { componentName: "closeButton" })}
          />
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <PEIcon color={theme.colors.icon.alert.success} name="done" size={200} />
        <Heading level="3">
          <Trans>You have been verified</Trans>
        </Heading>
      </View>
      <View style={styles.buttonContainer}>
        <Button onPress={handlePressClose} text={_(msg`Close`)} type="primary" />
      </View>
    </View>
  );
};
