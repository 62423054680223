import { Button, useTheme } from "@merit/frontend-components";
import { ButtonListItem } from "./ButtonListItem";
import { Header } from "./Header";
import { ListItem } from "./ListItem";
import { Log } from "@src/utils";
import { Icon as PEIcon } from "../Icon";
import { StyleSheet, View } from "react-native";
import {
  TabActions,
  TabRouter,
  createNavigatorFactory,
  useNavigationBuilder,
  useRoute,
} from "@react-navigation/native";
import { useEffect, useMemo, useState } from "react";
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import type { ButtonConfig, Section } from "./types";
import type {
  DefaultNavigatorOptions,
  ParamListBase,
  TabNavigationState,
  TabRouterOptions,
} from "@react-navigation/native";
import type { FC, ReactNode } from "react";

// Props accepted by the view
type TabNavigationConfig = {
  readonly headerHeight?: number;
  readonly headerIcon: ReactNode;
  readonly headerText: string;
  readonly buttons?: readonly ButtonConfig[];
};

// Supported screen options
type TabNavigationOptions = {
  readonly icon: ReactNode;
  readonly section?: Section;
  readonly title?: string;
};

// Map of event name and the type of data (in event.data)
//
// canPreventDefault: true adds the defaultPrevented property to the
// emitted events.
type TabNavigationEventMap = {
  readonly tabPress: {
    readonly data: { readonly isAlreadyFocused: boolean };
    readonly canPreventDefault: true;
  };
};

// The props accepted by the component is a combination of 3 things
export type SideBarNavigatorProps = DefaultNavigatorOptions<
  ParamListBase,
  TabNavigationState<ParamListBase>,
  TabNavigationOptions,
  TabNavigationEventMap
> &
  TabNavigationConfig &
  TabRouterOptions;

const EXPANDED_MIN_WIDTH = 220;
const DEFAULT_HEADER_HEIGHT = 60;

const SideBarNavigator: FC<SideBarNavigatorProps> = ({
  buttons = [],
  children,
  headerHeight = DEFAULT_HEADER_HEIGHT,
  headerIcon,
  headerText,
  initialRouteName,
  screenOptions,
}) => {
  const { NavigationContent, descriptors, navigation, state } = useNavigationBuilder(TabRouter, {
    children,
    initialRouteName,
    screenOptions,
  });

  const routeFromHook = useRoute();
  const [isMinimized, setIsMinimized] = useState(false);
  const { theme } = useTheme();

  const minWidth = useSharedValue(EXPANDED_MIN_WIDTH);
  const animatedStyles = useAnimatedStyle(() => ({
    minWidth: withTiming(minWidth.value),
  }));

  useEffect(() => {
    if (isMinimized) {
      // eslint-disable-next-line functional/immutable-data
      minWidth.value = 0;

      return;
    }

    // eslint-disable-next-line functional/immutable-data
    minWidth.value = EXPANDED_MIN_WIDTH;
  }, [isMinimized, minWidth]);

  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: "row",
    },
    contentContainer: {
      flex: 4,
    },
    controlContainer: {
      padding: theme.spacing.m,
    },
    screensContainer: {
      flex: 1,
      justifyContent: "space-between",
    },
    sideBarContainer: {
      backgroundColor: theme.colors.background.white,
      borderRightColor: theme.colors.border.disabled,
      borderRightWidth: 1,
      maxWidth: EXPANDED_MIN_WIDTH,
    },
    sideBarContentContainer: {
      flex: 1,
    },
  });

  type Route = ArrayElement<typeof state.routes>;
  const handlePress = (routeFromPressEvent: Route) => {
    // Check if the pressed route is already the active route
    if (routeFromPressEvent.key === state.routes[state.index]?.key) {
      return;
    }
    const event = navigation.emit({
      canPreventDefault: true,
      target: routeFromPressEvent.key,
      type: "tabPress",
    });

    // TODO@tyler: Not sure what's going on here. `defaultPrevented` _should_ be defined if
    //             `canPreventDefault` is `true`
    // @ts-expect-error - see above
    if (Boolean(event.defaultPrevented)) {
      return;
    }

    navigation.dispatch({
      ...TabActions.jumpTo(routeFromPressEvent.name),
      target: state.key,
    });
  };

  const toggleMinimized = () => {
    setIsMinimized(prev => !prev);
  };

  const renderDescriptor = useMemo(() => {
    const activeRoute = state.routes[state.index];
    if (activeRoute === undefined) {
      return null;
    }
    const descriptor = descriptors[activeRoute.key];

    if (descriptor === undefined) {
      Log.error("Could not find descriptor for route", { routeKey: activeRoute.key });

      return null;
    }

    return <View style={StyleSheet.absoluteFill}>{descriptor.render()}</View>;
  }, [descriptors, state.index, state.routes]);

  return (
    <NavigationContent>
      <View style={styles.container}>
        <Animated.View style={[styles.sideBarContainer, animatedStyles]}>
          <View style={styles.sideBarContentContainer}>
            <Header
              height={headerHeight}
              icon={headerIcon}
              minimized={isMinimized}
              text={headerText}
            />
            <View style={styles.screensContainer}>
              <View>
                {buttons
                  .filter(button => button.section === "top" && button.order === "before")
                  .map(button => (
                    <ButtonListItem
                      button={button}
                      elementId={button.id}
                      key={button.id}
                      minimized={isMinimized}
                    />
                  ))}
                {state.routes.map((routeFromState, idx) => {
                  const descriptor = descriptors[routeFromState.key];
                  if (descriptor === undefined) {
                    Log.error("Could not find descriptor for route", {
                      routeKey: routeFromState.key,
                    });

                    return null;
                  }

                  const routeName = routeFromState.name;
                  const { section = "top", title } = descriptor.options;
                  const displayText = title ?? routeName;

                  if (section === "top") {
                    return (
                      <ListItem
                        active={state.index === idx}
                        elementId={(routeName[0] ?? "").toLowerCase() + routeName.slice(1)}
                        icon={descriptor.options.icon}
                        key={routeFromState.key}
                        minimized={isMinimized}
                        onPress={() => {
                          handlePress(routeFromState);
                        }}
                        text={displayText}
                      />
                    );
                  }

                  return null;
                })}
                {buttons
                  .filter(button => button.section === "top" && button.order === "after")
                  .map(button => (
                    <ButtonListItem
                      button={button}
                      elementId={button.id}
                      key={button.id}
                      minimized={isMinimized}
                    />
                  ))}
              </View>
              <View>
                {buttons
                  .filter(button => button.section === "bottom" && button.order === "before")
                  .map(button => (
                    <ButtonListItem
                      button={button}
                      elementId={button.id}
                      key={button.id}
                      minimized={isMinimized}
                    />
                  ))}
                {state.routes.map((routeFromState, idx) => {
                  const descriptor = descriptors[routeFromState.key];
                  if (descriptor === undefined) {
                    Log.error("Could not find descriptor for route", {
                      routeKey: routeFromState.key,
                    });

                    return null;
                  }

                  const routeName = routeFromState.name;
                  const { section = "top", title } = descriptor.options;
                  const displayText = title ?? routeName;

                  if (section === "bottom") {
                    return (
                      <ListItem
                        active={state.index === idx}
                        elementId={(routeName[0] ?? "").toLowerCase() + routeName.slice(1)}
                        icon={descriptor.options.icon}
                        key={routeFromState.key}
                        minimized={isMinimized}
                        onPress={() => {
                          handlePress(routeFromState);
                        }}
                        text={displayText}
                      />
                    );
                  }

                  return null;
                })}
                {buttons
                  .filter(button => button.section === "bottom" && button.order === "after")
                  .map(button => (
                    <ButtonListItem
                      button={button}
                      elementId={button.id}
                      key={button.id}
                      minimized={isMinimized}
                    />
                  ))}
              </View>
            </View>
            <View style={styles.controlContainer}>
              <Button
                customContent={
                  <PEIcon name={isMinimized ? "chevron_right" : "chevron_left"} size={20} />
                }
                onPress={toggleMinimized}
                shape="square"
                testProps={{
                  elementId: "toggleButton",
                  elementName: "SideBarNavigator",
                  screenName: routeFromHook.name,
                }}
                type="secondary"
              />
            </View>
          </View>
        </Animated.View>
        <View style={styles.contentContainer}>{renderDescriptor}</View>
      </View>
    </NavigationContent>
  );
};

export const createSideBarNavigator = createNavigatorFactory(SideBarNavigator);
