import { Log as DefaultLogger } from "@src/utils";

// TODO@tyler: It'd be nice to be able to get a new instance of a logger and set the log level
// instead of having to go through this
export const Log = {
  ...DefaultLogger,
  debug: () => undefined,
  info: () => undefined,
  warn: () => undefined,
};
