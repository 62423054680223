import { Body, useTheme } from "@merit/frontend-components";
import { Pressable, StyleSheet, View } from "react-native";
import { useGetTestProps } from "@src/hooks";
import type { CommonProps } from "../types";
import type { ReactNode, VFC } from "react";

export type ListItemProps = CommonProps & {
  readonly active?: boolean;
  readonly onPress: () => Promise<void> | void;
  readonly icon: ReactNode;
  readonly minimized?: boolean;
  readonly text: string;
};

export const ListItem: VFC<ListItemProps> = ({
  active = false,
  elementId,
  icon,
  minimized = false,
  onPress,
  text,
}) => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: active ? theme.colors.surface.pressed : undefined,
      borderRightColor: active ? theme.colors.brand.turquiose : undefined,
      borderRightWidth: active ? 2 : undefined,
      flex: 1,
      flexDirection: "row",
      justifyContent: minimized ? "center" : undefined,
      padding: theme.spacing.m,
    },
    iconContainer: {
      marginRight: minimized ? undefined : theme.spacing.m,
    },
    textContainer: {
      fontWeight: active ? theme.fontWeights.semiBold : undefined,
    },
  });

  const handlePress = () => {
    onPress();
  };

  return (
    <Pressable
      onPress={handlePress}
      {...getTestProps({ elementId, elementName: "SideBarListItem" })}
    >
      <View style={styles.container}>
        <View style={styles.iconContainer}>{icon}</View>
        {minimized ? null : <Body style={styles.textContainer}>{text}</Body>}
      </View>
    </Pressable>
  );
};
