import { Header } from "../Header";
import { Icon } from "@merit/frontend-components";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useNavigation } from "@src/hooks";
import type { HeaderProps } from "../Header";
import type { VFC } from "react";

export type HeaderGoBackProps = Omit<HeaderProps, "leftElement" | "leftOnPress">;

export const HeaderGoBack: VFC<HeaderGoBackProps> = props => {
  const navigation = useNavigation();
  const { _ } = useLingui();

  const handlePress = () => {
    navigation.goBackOrGoHome();
  };

  return (
    <Header
      leftElement={
        <Icon
          alt={_(msg({ context: "alt text", message: "Go back" }))}
          name="arrowBackwardMediumAction"
        />
      }
      leftOnPress={handlePress}
      {...props}
    />
  );
};
