import { Auth0Provider } from "@auth0/auth0-react";
import { getLocalConfig } from "@src/utils";
import type { FCWithChildren } from "@src/types/component";

const config = getLocalConfig();

const AuthProvider: FCWithChildren = ({ children }) => {
  const { auth0Domain, clientId } = config.auth;

  return (
    <Auth0Provider cacheLocation="localstorage" clientId={clientId} domain={auth0Domain}>
      {children}
    </Auth0Provider>
  );
};

export { AuthProvider };
