import { StyleSheet } from "react-native";
import { useGetTestProps, useSkeleton } from "@src/hooks";
import { useTheme } from "@merit/frontend-components";
import Animated from "react-native-reanimated";
import type { CommonProps } from "../types";
import type { VFC } from "react";
import type { ViewProps } from "react-native";

export type TextSkeletonProps = CommonProps & Pick<ViewProps, "style">;

export const TextSkeleton: VFC<TextSkeletonProps> = ({ elementId, style }) => {
  const getTestProps = useGetTestProps();
  const { theme } = useTheme();
  const skeletonStyles = useSkeleton();

  const styles = StyleSheet.create({
    textSkeleton: {
      borderRadius: theme.fontSizes.s.lineHeight / 2,
      height: theme.fontSizes.s.lineHeight,
      marginBottom: theme.spacing.s,
      width: 100,
    },
  });

  return (
    <Animated.View
      style={[styles.textSkeleton, skeletonStyles, style]}
      {...getTestProps({
        elementId,
        elementName: "TextSkeleton",
      })}
    />
  );
};
