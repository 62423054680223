import { Body, Button, Link, useTheme } from "@merit/frontend-components";
import { HELP } from "@src/utils/constants/urls";
import { StyleSheet, View } from "react-native";
import { Trans, msg } from "@lingui/macro";
import { openExternalLink } from "@src/utils";
import { useAcceptFolio } from "@src/api/person-experience-backend";
import { useAcceptMerit } from "@src/api/issuance";
import { useLingui } from "@lingui/react";
import { useRoute } from "@src/hooks";
import type { ExtendedContainer } from "@src/api/issuance";

const helpOnPress = () => {
  openExternalLink(HELP);
};

type OnboardingMeritsFolioFooterProps = {
  readonly adminMeritId: ExtendedContainer["id"];
  readonly onAcceptFailure?: () => void;
  readonly onAcceptSuccess?: () => void;
};

const OnboardingMeritsFolioFooter = ({
  adminMeritId,
  onAcceptFailure,
  onAcceptSuccess,
}: OnboardingMeritsFolioFooterProps) => {
  const { name: routeName } = useRoute();
  const { theme } = useTheme();
  const acceptFolio = useAcceptFolio(adminMeritId);
  const acceptMerit = useAcceptMerit();
  const { _ } = useLingui();

  const styles = StyleSheet.create({
    acceptButtonWrapper: {
      marginHorizontal: "auto",
      width: 200,
    },
    container: {
      ...theme.elevations.depth3,
      alignItems: "center",
      backgroundColor: theme.colors.background.white,
      gap: theme.spacing.xl,
      paddingVertical: theme.spacing.xl,
    },
  });

  const onAcceptPress = async () => {
    // TODO@tyler: I wonder if this whole thing should be wrapped in a mutation
    // order matters here! accept folio before admin merit in case the latter fails, to ensure the former doesn't go into limbo
    await acceptFolio.mutateAsync();
    if (acceptFolio.isError) {
      onAcceptFailure?.();

      return;
    }

    await acceptMerit.mutateAsync(adminMeritId);
    if (acceptMerit.isError) {
      onAcceptFailure?.();

      return;
    }

    onAcceptSuccess?.();
  };

  return (
    <View style={styles.container}>
      <View style={styles.acceptButtonWrapper}>
        <Button
          onPress={onAcceptPress}
          text={_(msg({ context: "action", message: "Accept" }))}
          type="primary"
        />
      </View>
      <Body
        size="s"
        testProps={{
          elementId: "helpText",
          elementName: "OnboardingMeritsFolioFooter",
          screenName: routeName,
        }}
      >
        <Trans>
          <Link
            onPress={helpOnPress}
            testProps={{
              elementId: "helpTextLink",
              elementName: "OnboardingMeritsFolioFooter",
              screenName: routeName,
            }}
          >
            Learn more
          </Link>{" "}
          about Merit and why you received this
        </Trans>
      </Body>
    </View>
  );
};

export { OnboardingMeritsFolioFooter };
