import { Log } from "@src/utils";
import { MeritFieldMapSchema } from "../schemas";
import type { NormalMerit } from "../types/NormalMerit";

export const toNormalMerit = (
  merit: PickPartial<NormalMerit, "transformedFields">
): PickPartial<NormalMerit, "transformedFields"> => {
  const { fieldMap, ...rest } = merit;
  const result = MeritFieldMapSchema.safeParse(fieldMap);

  if (!result.success) {
    Log.error("Merit fields are missing or invalid", {
      containerId: merit.id,
      error: result.error.toString(),
    });

    throw new Error("Merit fields missing or invalid");
  }

  return {
    ...rest,
    fieldMap: result.data,
  };
};
