// A container component for adding swipeability

/* eslint-disable functional/immutable-data */

// The RN Gesture Handler API requires modifying objects
//
// Example:
//
//    `x.value = startingValue + event.translationX`
//
// https://docs.swmansion.com/react-native-gesture-handler/docs/quickstart/quickstart

import { Gesture, GestureDetector } from "react-native-gesture-handler";
import { useGetTestProps } from "@src/hooks";
import { useMemo } from "react";
import { useWindowDimensions } from "react-native";
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from "react-native-reanimated";
import type { FC } from "react";
import type { ViewProps } from "react-native";

export type SwipeableProps = ViewProps & {
  readonly addSkewAlongXAxis?: boolean;
  readonly onSwipe: () => void;
  readonly threshold?: number;
};

export const Swipeable: FC<SwipeableProps> = ({
  addSkewAlongXAxis = false,
  children,
  onSwipe,
  style,
  threshold = 50,
  ...rest
}) => {
  const getTestProps = useGetTestProps();

  // For now we assume that the contentWidth is the same as the window width. This is close to
  // correct for the notifications stack on a mobile device. We use a margin here to push the
  // content past the edge, not just to it.
  const { width } = useWindowDimensions();
  const contentWidth = width;
  const margin = 100;
  const exitRight = width + margin;
  const exitLeft = 0 - contentWidth - margin;

  const startingValue = 0;
  const x = useSharedValue(startingValue);
  const y = useSharedValue(startingValue);

  const gesture = useMemo(
    () =>
      Gesture.Pan()
        .onUpdate(event => {
          x.value = startingValue + event.translationX;
          y.value = startingValue + event.translationY;
        })
        .onEnd(event => {
          if (event.translationX > threshold) {
            x.value = withSpring(exitRight, { overshootClamping: true }, runOnJS(onSwipe));
            y.value = withSpring(startingValue);

            return;
          }

          if (event.translationX < -1 * threshold) {
            x.value = withSpring(exitLeft, { overshootClamping: true }, runOnJS(onSwipe));
            y.value = withSpring(startingValue);

            return;
          }

          x.value = withSpring(startingValue);
          y.value = withSpring(startingValue);
        }),
    [exitLeft, exitRight, onSwipe, threshold, x, y]
  );

  const animatedStyle = useAnimatedStyle(() => {
    const baseTransforms = [{ translateX: x.value }, { translateY: y.value }];
    const skewTransforms = addSkewAlongXAxis ? [{ rotateZ: `${(x.value * 2) / width}deg` }] : [];

    return {
      transform: [...baseTransforms, ...skewTransforms],
    };
  });

  return (
    <GestureDetector gesture={gesture}>
      <Animated.View
        style={[style, animatedStyle]}
        {...getTestProps({
          elementName: "Swipeable",
        })}
        {...rest}
      >
        {children}
      </Animated.View>
    </GestureDetector>
  );
};
