// Browser proxy for expo-datadog, see also webpack.config.js

/* eslint-disable functional/no-class */
/* eslint-disable @typescript-eslint/parameter-properties */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable require-await */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable lingui/no-unlocalized-strings */

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import type {
  DdLogsType,
  LogEvent,
  LogEventMapper,
} from "@datadog/mobile-react-native/lib/typescript/logs/types";
import type { DdRum as ExpoDdRum } from "expo-datadog";

// The site is essentially an identifier for the URL you use to access your datadog portal. It's not
// configurable via arguments in the React Native SDK.
//
// https://docs.datadoghq.com/getting_started/site/
const DATADOG_SITE = "datadoghq.com";

export const DdSdkReactNative = {
  initialize: (config: DdSdkReactNativeConfiguration) => {
    datadogLogs.init({
      // eslint-disable-next-line consistent-return
      beforeSend: event => {
        if (event.error?.stack?.includes("AbortError") ?? false) {
          return false;
        }
      },
      clientToken: config.clientToken,
      env: config.env,
      forwardErrorsToLogs: config.trackErrors,
      service: config.serviceName,
      site: DATADOG_SITE,
    });

    datadogRum.init({
      allowedTracingUrls: config.firstPartyHosts?.map(host => url => url.includes(host)),
      applicationId: config.applicationId,
      clientToken: config.clientToken,
      defaultPrivacyLevel: "mask",
      enableExperimentalFeatures: ["feature_flags"],
      env: config.env,
      service: config.serviceName,
      site: DATADOG_SITE,
      traceSampleRate: config.resourceTracingSamplingRate,
      trackResources: config.trackResources,
      trackUserInteractions: config.trackInteractions,
    });
  },
};

export class DdSdkReactNativeConfiguration {
  // eslint-disable-next-line functional/prefer-readonly-type
  public serviceName: string | undefined;

  // eslint-disable-next-line functional/prefer-readonly-type
  public firstPartyHosts: string[] | undefined;

  // eslint-disable-next-line functional/prefer-readonly-type
  public resourceTracingSamplingRate: number | undefined;

  public constructor(
    public readonly clientToken: string,
    public readonly env: string,
    public readonly applicationId: string,
    public readonly trackInteractions: boolean = false,
    public readonly trackResources: boolean = false,
    public readonly trackErrors: boolean = false
  ) {}
}

type GetContext = (...args: Parameters<DdLogsWrapper["debug"]>) => object | undefined;

const getContext: GetContext = (...args) => {
  const context = args.at(-1);

  if (typeof context !== "object") {
    if (args.length > 1) {
      // eslint-disable-next-line no-console
      console.warn("The last argument to Log[level] must be the context object");
    }

    return undefined;
  }

  return context;
};

declare class DdLogsWrapper implements DdLogsType {
  public readonly debug: DdLogsType["debug"];

  public readonly info: DdLogsType["info"];

  public readonly warn: DdLogsType["warn"];

  public readonly error: DdLogsType["error"];

  public readonly registerLogEventMapper: (logEventMapper: LogEventMapper) => void;

  public readonly unregisterLogEventMapper: () => void;
}

export const DdLogs: DdLogsWrapper = {
  /* eslint-disable sort-keys-fix/sort-keys-fix */
  debug: async (...args) => {
    const message = args[0];
    const context = getContext(...args);

    datadogLogs.logger.debug(message, context);
  },
  info: async (...args) => {
    const message = args[0];
    const context = getContext(...args);

    datadogLogs.logger.info(message, context);
  },
  warn: async (...args) => {
    const message = args[0];
    const context = getContext(...args);

    datadogLogs.logger.warn(message, context);
  },
  error: async (...args) => {
    const message = args[0];
    const context = getContext(...args);

    datadogLogs.logger.error(message, context);
  },
  /* eslint-enable sort-keys-fix/sort-keys-fix */
  // see also https://github.com/DataDog/dd-sdk-reactnative/blob/develop/packages/core/src/logs/DdLogs.ts#L208-L210
  registerLogEventMapper: (logEventMapper: LogEventMapper) => {
    const fakeEvent: LogEvent = {
      context: {},
      message:
        "This should not log out anything. This is just so we can call the logEventMapper and get the context for merging",
      status: "info",
      userInfo: {},
    };
    const mappedEvent = logEventMapper(fakeEvent);
    Object.entries(mappedEvent?.context ?? {}).forEach(([key, value]) => {
      datadogLogs.setGlobalContextProperty(key, value);
    });
  },
  // see also https://github.com/DataDog/dd-sdk-reactnative/blob/develop/packages/core/src/logs/DdLogs.ts#L212-L214
  unregisterLogEventMapper: () => {
    datadogLogs.clearGlobalContext();
  },
};

type DdRumType = Pick<
  typeof ExpoDdRum,
  | "addAction"
  | "addError"
  | "addFeatureFlagEvaluation"
  | "addTiming"
  | "startAction"
  | "startResource"
  | "startView"
  | "stopAction"
  | "stopResource"
  | "stopView"
>;

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const DdRum: DdRumType = {
  startView: async (_, name) => {
    datadogRum.startView(name);
  },

  stopView: async () => {},

  startAction: async (_, name, context) => {
    datadogRum.addAction(name, context);
  },

  stopAction: async () => {},

  addAction: async (_, name, context) => {
    datadogRum.addAction(name, context);
  },

  startResource: async () => {},

  stopResource: async () => {},

  addError: async (message, _, stacktrace, context) => {
    const error = new Error();

    /* eslint-disable functional/immutable-data */
    error.message = message;
    error.stack = stacktrace;
    /* eslint-enable functional/immutable-data */
    datadogRum.addError(error, context);
  },

  addTiming: async name => {
    datadogRum.addTiming(name);
  },

  addFeatureFlagEvaluation: async (key, value) => {
    datadogRum.addFeatureFlagEvaluation(key.replace("-", "_"), value);
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export enum SdkVerbosity {
  DEBUG = "debug",
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}
