import { useMerits } from "./useMerits";
import type { Merit } from "./types";

export type UseMerit = (meritId: Merit["id"]) => Merit | undefined;

export const useMerit: UseMerit = meritId => {
  const { data: merits } = useMerits();

  return merits?.find(merit => merit.id === meritId);
};
