import { PendingStateIndicator } from "./PendingStateIndicator";
import { PendingStateIndicatorDesktop } from "./PendingStateIndicatorDesktop";
import { useLayoutType } from "@src/hooks";

const LayoutPendingStateIndicator = ({ ...props }) => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <PendingStateIndicatorDesktop {...props} />;
  }

  return <PendingStateIndicator {...props} />;
};

export { LayoutPendingStateIndicator as PendingStateIndicator };
