/* eslint-disable functional/no-loop-statement, functional/immutable-data, no-underscore-dangle, no-continue */

import { DdRum } from "expo-datadog";
import { Log } from "./logger";
import { emptyFn } from "@src/utils";
import { initialize } from "launchdarkly-js-client-sdk";
import { useConfig, useMeritAuth0 } from "@src/hooks";
import { useEffect, useState } from "react";
import { useFeatureFlagsStore } from "./featureFlagsStore";
import type { LDClient, LDEvaluationDetail } from "launchdarkly-js-client-sdk";
import type { UseInitializeLaunchDarkly } from "./types";

export const useInitializeLaunchDarkly: UseInitializeLaunchDarkly = () => {
  const [client, setClient] = useState<LDClient>();
  const updateFeatureFlags = useFeatureFlagsStore(store => store.updateFeatureFlags);

  const config = useConfig();
  const { user } = useMeritAuth0();

  // Initialize the client and register event handlers
  useEffect(() => {
    if (config.remote?.launchDarklyClientID === undefined) {
      return emptyFn;
    }

    const _client = initialize(
      config.remote.launchDarklyClientID,
      { anonymous: true, kind: "user" },
      {
        inspectors: [
          {
            method: (key: string, detail: LDEvaluationDetail) => {
              DdRum.addFeatureFlagEvaluation(key, detail.value);
            },
            name: "dd-inspector",
            type: "flag-used",
          },
        ],
        logger: Log,
        streaming: true,
      }
    );

    setClient(_client);

    _client.on("initialized", () => {
      updateFeatureFlags(_client.allFlags());
    });

    _client.on("failure", (...args) => {
      Log.error("Failed to connect to LaunchDarkly", { args });
    });

    _client.on("error", (...args) => {
      Log.error("The LaunchDarkly client encountered an error", { args });
    });

    _client.on("change", () => {
      updateFeatureFlags(_client.allFlags());
    });

    return () => {
      _client.close();
      setClient(undefined);
    };
  }, [config.remote?.launchDarklyClientID, updateFeatureFlags]);

  // Identify on user change and populate the store
  useEffect(() => {
    (async () => {
      if (client === undefined) {
        return;
      }

      if (user?.entityID === undefined) {
        return;
      }

      await client.identify(
        {
          key: user.entityID,
          kind: "user",
        },
        undefined,
        (error, _) => {
          if (error !== null) {
            Log.error("Failed to identify with LaunchDarkly", { error });
          }
        }
      );

      updateFeatureFlags(client.allFlags());
    })();
  }, [client, user?.entityID, updateFeatureFlags]);
};
