import type { ElementEdges, InternalState, ListState } from "./types";

const getContainer = (state: InternalState): ElementEdges | undefined => {
  if (state.scrollViewEdges === undefined) {
    return undefined;
  }

  const container = {
    ...state.scrollViewEdges,
    left: state.position + state.scrollViewEdges.left,
    right: state.position + state.scrollViewEdges.right,
  };

  return container;
};

// Use the current position to see if item is unobscured
export const itemIsUnobscured = (state: InternalState, idx: number): boolean => {
  const item = state.itemEdges.at(idx);
  const container = getContainer(state);

  if (item === undefined || container === undefined) {
    return true;
  }

  return container.left <= item.left && container.right >= item.right;
};

// Use expected position to see if item will be unobscured
//
// Expected position is the left edge of the item at the current index
//
// Animation is slow, so `state.position` lags, and can cause the user to scroll beyond the end of
// the list which causes extra clicks at best and errors at worst
export const itemWillBeUnobscured = (state: InternalState, idx: number): boolean => {
  const targetItem = state.itemEdges.at(idx);
  const currentItem = state.itemEdges.at(state.index);

  if (
    targetItem === undefined ||
    state.scrollViewEdges === undefined ||
    currentItem === undefined
  ) {
    return true;
  }

  const container = {
    ...state.scrollViewEdges,
    left: currentItem.left,
    right: currentItem.left + (state.scrollViewEdges.right - state.scrollViewEdges.left),
  };

  return container.left <= targetItem.left && container.right >= targetItem.right;
};

export const getExternalState = (state: InternalState): ListState => {
  const atStart = itemWillBeUnobscured(state, 0);
  const atEnd = itemWillBeUnobscured(state, -1);

  return {
    hasNext: !atEnd,
    hasPrevious: !atStart,
    index: state.index,
  };
};
