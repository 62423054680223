import { Body, Heading, Icon, useTheme } from "@merit/frontend-components";
import { Card } from "@src/components";
import { Pressable, StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { useGetTestProps, useNavigation } from "@src/hooks";
import type { ViewStyle } from "react-native";

export const PersonalQRCodeCard = () => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly titleContainer: ViewStyle;
  }>({
    container: {
      padding: theme.spacing.l,
    },
    titleContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: theme.spacing.s,
    },
  });

  const navigation = useNavigation();
  const getTestProps = useGetTestProps();

  return (
    <Pressable
      onPress={() => {
        navigation.push("PersonalQRCode");
      }}
      {...getTestProps({
        elementName: "PersonalQrCodeCard",
      })}
    >
      <Card elementId="personalQRCode" elevation={4} style={styles.container}>
        <View style={styles.titleContainer}>
          <Heading bold level="2">
            <Trans context="title">My QR Code</Trans>
          </Heading>
          {/* TODO (PE-792): use theme.sizes.icons.l */}
          <Icon name="qrCodeLargeAction" />
        </View>
        <Body>
          <Trans>Scan your digital credentials</Trans>
        </Body>
      </Card>
    </Pressable>
  );
};
