import { IconSkeleton, TextSkeleton } from "@src/components";
import { MeritListItemLayout } from "../MeritListItemLayout";
import { StyleSheet } from "react-native";
import type { FC } from "react";

export const MeritListItemSkeleton: FC = () => {
  const styles = StyleSheet.create({
    meritName: {
      width: 250,
    },
    orgImage: {
      height: 56,
      width: 56,
    },
    textSkeleton: {
      marginBottom: 0,
    },
  });

  return (
    <MeritListItemLayout
      issuedAt={<TextSkeleton style={styles.textSkeleton} />}
      issuerOrgName={<TextSkeleton style={styles.textSkeleton} />}
      meritName={<TextSkeleton style={[styles.textSkeleton, styles.meritName]} />}
      orgImage={<IconSkeleton style={styles.orgImage} />}
    />
  );
};
