import { HeaderGoBack, MeritDetails } from "@src/components";
import { StyleSheet, View } from "react-native";
import { msg } from "@lingui/macro";
import { useLayoutType, useRoute } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useTheme } from "@merit/frontend-components";
import type { ViewStyle } from "react-native";

export const MeritDetailsScreen = () => {
  const route = useRoute<"MeritDetails">();
  const { theme } = useTheme();
  const layoutType = useLayoutType();
  const { _ } = useLingui();

  if (layoutType === "mobile") {
    return <MeritDetails meritId={route.params.meritId} />;
  }

  const styles = StyleSheet.create<{
    readonly wrapper: ViewStyle;
    readonly headerStyle: ViewStyle;
  }>({
    headerStyle: {
      backgroundColor: theme.colors.background.white,
    },
    wrapper: {
      backgroundColor: theme.colors.background.default,
      flex: 1,
    },
  });

  return (
    <View style={styles.wrapper}>
      <HeaderGoBack
        containerStyle={styles.headerStyle}
        title={_(msg({ context: "title", message: "Merit" }))}
      />
      <MeritDetails meritId={route.params.meritId} />
    </View>
  );
};
