/* eslint-disable functional/prefer-readonly-type */
/* eslint-disable functional/no-let */
/* eslint-disable functional/no-loop-statement */
/* eslint-disable functional/immutable-data */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-continue */

import {
  FieldLevelCompoundAtomSchema,
  TemplateLevelCompoundAtomSchema,
  TemplateLevelCompoundAtomWithFieldSchema,
} from "@src/api/issuance/schemas/PolicySchema";
import { Log } from "@src/utils";
import type { PolicyRequest } from "@src/api/issuance";
import type { TemplateIdsByTemplateFieldId } from "../types";

type Args = {
  readonly policyRequest: Pick<PolicyRequest, "errorResults">;
  readonly templateIdsByTemplateFieldId: TemplateIdsByTemplateFieldId;
};

type GetPolicyRequestErrors = (args: Args) =>
  | {
      readonly fieldFailuresByTemplateId: Record<string, readonly string[]>;
      readonly isMissingRequiredMerits: boolean;
    }
  | undefined;

export const getPolicyRequestErrors: GetPolicyRequestErrors = ({
  policyRequest,
  templateIdsByTemplateFieldId,
}) => {
  const fieldFailuresByTemplateId: Record<string, string[]> = {};
  let isMissingRequiredMerits = false;

  if (policyRequest.errorResults === undefined) {
    return undefined;
  }

  for (const errorResult of policyRequest.errorResults) {
    try {
      // Ignore these errors. Field-level errors will have their own item
      TemplateLevelCompoundAtomWithFieldSchema.parse(errorResult.formula);
      continue;
    } catch {}

    try {
      TemplateLevelCompoundAtomSchema.parse(errorResult.formula);

      isMissingRequiredMerits = true;

      continue;
    } catch {}

    try {
      const atom = FieldLevelCompoundAtomSchema.parse(errorResult.formula);

      const templateFieldId = atom.target;
      const templateId = templateIdsByTemplateFieldId[templateFieldId];

      // member doesn't have the merit that contains this field
      if (templateId === undefined) {
        continue;
      }

      if (fieldFailuresByTemplateId[templateId] === undefined) {
        fieldFailuresByTemplateId[templateId] = [];
      }

      fieldFailuresByTemplateId[templateId]!.push(templateFieldId);

      continue;
    } catch {}

    Log.error("Failed to parse error result", { errorResult });
  }

  return {
    fieldFailuresByTemplateId,
    isMissingRequiredMerits,
  };
};
