import { ActivityIndicator, StyleSheet, View } from "react-native";
import { Constants, Log } from "@src/utils";
import {
  DropdownMenuButton,
  Header,
  Icon as PEIcon,
  RejectMeritForm,
  RemoveMeritForm,
} from "@src/components";
import { EmptyState } from "../EmptyState";
import { Icon, Modal, useTheme } from "@merit/frontend-components";
import { MeritActions } from "../MeritActions";
import { MeritSummary } from "../MeritSummary";
import { MeritTabs } from "../MeritTabs";
import { ScrollView } from "react-native-gesture-handler";
import { behavior } from "../../KeyboardAvoidingView";
import { msg } from "@lingui/macro";
import { useAcceptMerit } from "@src/api/issuance";
import { useConfig, useGetTestProps, useNavigation, useRoute } from "@src/hooks";
import { useLingui } from "@lingui/react";
import { useMeritDetails } from "./hooks";
import { useState } from "react";
import type { MeritAction } from "../MeritActions";
import type { MeritDetailsProps } from "./types";
import type { ViewStyle } from "react-native";

export const MeritDetails = ({
  hideActions = false,
  hideHeader = false,
  meritId,
}: MeritDetailsProps) => {
  const config = useConfig();
  const getTestProps = useGetTestProps();
  const route = useRoute();
  const { theme } = useTheme();
  const { matchingMerit, showEmptyState, showLoadingState } = useMeritDetails({ meritId });
  const acceptMerit = useAcceptMerit();
  const { goBackOrGoHome } = useNavigation();
  const { _ } = useLingui();

  const [isRejectMeritModalOpen, setIsRejectMeritModalOpen] = useState(false);
  const [isRemoveMeritModalOpen, setIsRemoveMeritModalOpen] = useState(false);

  const styles = StyleSheet.create<{
    readonly container: ViewStyle;
    readonly tabsSectionContainer: ViewStyle;
    readonly topSectionContainer: ViewStyle;
    readonly hiddenHeading: ViewStyle;
  }>({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },

    hiddenHeading: {
      paddingTop: theme.spacing.l,
    },
    tabsSectionContainer: {
      flex: 1,
    },
    topSectionContainer: {
      marginHorizontal: theme.spacing.l,
    },
  });

  const openRemoveMeritModal = () => {
    setIsRemoveMeritModalOpen(true);
  };

  const closeRemoveMeritModal = () => {
    setIsRemoveMeritModalOpen(false);
  };

  const handleRemove = () => {
    closeRemoveMeritModal();
    goBackOrGoHome();
  };

  const openRejectMeritModal = () => {
    setIsRejectMeritModalOpen(true);
  };

  const closeRejectMeritModal = () => {
    setIsRejectMeritModalOpen(false);
  };

  const handleReject = () => {
    closeRejectMeritModal();
    goBackOrGoHome();
  };

  if (showLoadingState) {
    return (
      <View style={styles.container}>
        <Header
          rightElement={<Icon name="closeLargeAction" />}
          rightOnPress={() => {
            goBackOrGoHome();
          }}
          title={_(msg({ context: "title", message: "Merit" }))}
        />

        <ActivityIndicator />
      </View>
    );
  }

  if (showEmptyState || matchingMerit === undefined) {
    Log.error(`Failed to fetch merits or find merit with ID: ${meritId}`);

    return (
      <View style={styles.container}>
        <Header
          rightElement={<Icon name="closeLargeAction" />}
          rightOnPress={() => {
            goBackOrGoHome();
          }}
          title={_(msg({ context: "title", message: "Merit" }))}
        />

        <EmptyState />
      </View>
    );
  }

  const isPending = matchingMerit.state?.name === "pending";
  const isAccountMerit = matchingMerit.templateId === config.remote?.accountMeritTemplateID;

  const actions: readonly MeritAction[] = (() => {
    const acceptAction: MeritAction = {
      id: "accept",
      onPress: () => {
        acceptMerit.mutateAsync(meritId);
      },
      testProps: {
        elementId: "acceptMerit",
        elementName: "MeritDetails",
        screenName: route.name,
      },
      text: _(msg({ context: "action", message: "Accept" })),
      type: "primary",
    };
    const rejectAction: MeritAction = {
      disabled: isAccountMerit,
      id: "reject",
      onPress: openRejectMeritModal,
      testProps: {
        elementId: "rejectMerit",
        elementName: "MeritDetails",
        screenName: route.name,
      },
      text: _(msg({ context: "action", message: "Reject" })),
      type: "destructive",
    };

    if (isPending) {
      return [acceptAction, rejectAction];
    }

    // all accepted merits' actions are in the top left dropdown menu, regardless of active status
    return [];
  })();

  return (
    <View
      style={styles.container}
      {...getTestProps({ elementId: meritId, elementName: "MeritDetails" })}
    >
      {hideHeader ? (
        <View style={styles.hiddenHeading} />
      ) : (
        <Header
          leftElement={
            isPending ? null : (
              <DropdownMenuButton
                elementId="removeMerit"
                menuItems={[
                  {
                    disabled: isAccountMerit,
                    elementId: "removeMerit",
                    icon: isAccountMerit ? (
                      <PEIcon
                        color={theme.colors.text.disabled}
                        name="remove_circle_outlined"
                        size={20}
                      />
                    ) : (
                      <Icon name="removeCircleMediumCritical" />
                    ),
                    label: _(msg({ context: "action", message: "Remove Merit" })),
                    onPress: openRemoveMeritModal,
                    type: "destructive",
                  },
                ]}
                width={148}
              />
            )
          }
          rightElement={<Icon name="closeLargeAction" />}
          rightOnPress={() => {
            goBackOrGoHome();
          }}
          title={
            isPending
              ? _(msg({ context: "title", message: "Pending" }))
              : _(msg({ context: "title", message: "Merit" }))
          }
        />
      )}

      <ScrollView>
        <View style={styles.topSectionContainer}>
          <MeritSummary merit={matchingMerit} />
        </View>
        <View style={styles.tabsSectionContainer}>
          <MeritTabs meritId={meritId} />
        </View>
      </ScrollView>
      {hideActions ? null : (
        <>
          <MeritActions actions={actions} />
          {isRejectMeritModalOpen ? (
            <Modal
              maxWidth={Constants.Sizes.MODAL}
              onClose={closeRejectMeritModal}
              title={_(msg({ context: "title", message: "Reject this merit" }))}
              titleIconName="warningMediumCritical"
              width="100%"
            >
              <RejectMeritForm
                merit={matchingMerit}
                onCancel={closeRejectMeritModal}
                onReject={handleReject}
              />
            </Modal>
          ) : null}
          {isRemoveMeritModalOpen && (
            <Modal
              contentContainerOptions={{ isKeyboardAvoiding: true, props: { behavior } }}
              maxWidth={Constants.Sizes.MODAL}
              onClose={closeRemoveMeritModal}
              title={_(msg({ context: "title", message: "Remove this merit" }))}
              titleIconName="warningMediumCritical"
              width="100%"
            >
              <RemoveMeritForm
                merit={matchingMerit}
                onCancel={closeRemoveMeritModal}
                onRemove={handleRemove}
              />
            </Modal>
          )}
        </>
      )}
    </View>
  );
};
