import { BULLET_POINT } from "@src/utils/constants/unicode";
import { Body, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { Trans } from "@lingui/macro";
import { useGetTestProps } from "@src/hooks";
import { useMerits } from "@src/api/issuance";
import type { FC } from "react";
import type { Merit } from "@src/api/issuance";
import type { TextStyle } from "react-native";

// eslint-disable-next-line lingui/no-unlocalized-strings
const elementName = "TemplateExistenceListItem";

type TemplateExistenceListItemProps = {
  readonly templateId: Merit["templateId"];
};

export const TemplateExistenceListItem: FC<TemplateExistenceListItemProps> = ({ templateId }) => {
  const meritsQuery = useMerits();
  const { theme } = useTheme();
  const getTestProps = useGetTestProps();

  const styles = StyleSheet.create<{
    readonly bold: TextStyle;
  }>({
    bold: {
      fontWeight: theme.fontWeights.semiBold,
    },
  });

  const merit = meritsQuery.data?.find(m => m.templateId === templateId);
  if (merit === undefined) {
    return null;
  }

  const templateName = merit.name;

  return (
    <View>
      <Body {...getTestProps({ elementId: templateId, elementName })}>
        <Trans>
          {BULLET_POINT} View the <Body style={styles.bold}>{templateName}</Body> merit
        </Trans>
      </Body>
    </View>
  );
};
