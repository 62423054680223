import { Header } from "../Header";
import { Icon } from "@merit/frontend-components";
import { msg } from "@lingui/macro";
import { throttle } from "lodash";
import { useLingui } from "@lingui/react";
import { useMemo } from "react";
import { useRoute } from "@src/hooks";

type HeaderProps = {
  readonly onPressClose: () => void;
  readonly onPressRegenerate: () => void;
};

export const PersonalQrCodeHeader = ({ onPressClose, onPressRegenerate }: HeaderProps) => {
  const route = useRoute();
  const { _ } = useLingui();

  // we want memo, not callback here because throttle() returns another func
  const throttledRegen = useMemo(
    () => throttle(onPressRegenerate, 5000, { leading: true, trailing: false }),
    [onPressRegenerate]
  );

  return (
    <Header
      leftElement={
        <Icon
          name="closeLargeAction"
          testProps={{ elementName: "closeModal", screenName: route.name }}
        />
      }
      leftOnPress={() => {
        onPressClose();
      }}
      rightElement={
        <Icon
          name="refreshMediumAction"
          testProps={{ elementName: "refreshQrCode", screenName: route.name }}
        />
      }
      rightOnPress={() => {
        throttledRegen();
      }}
      title={_(msg({ context: "title", message: "My QR Code" }))}
      titleBold
      titleLevel="3"
    />
  );
};
