import { MeritFieldMapSchema } from "./MeritFieldMapSchema";
import { z } from "zod";

const BaseAccountMeritFieldMapSchema = MeritFieldMapSchema.extend({
  "Chosen Name": z.string().optional(),
  Email: z.string(),
  "Phone Number": z.string().optional(),
  coppa: z.coerce.boolean(),
});

export const AlternateEmailsFieldValueSchema = z.object({
  alternates: z.array(z.string()).optional(),
  primary: z.string(),
});

export const AccountMeritFieldMapSchema = BaseAccountMeritFieldMapSchema.extend({
  "Alternate Emails": z
    .preprocess(val => JSON.parse(String(val)), AlternateEmailsFieldValueSchema)
    .optional(),
});
