import { Configuration as ConfigurationCls, TOSApi } from "@merit/person-experience-backend-client";
import { getLocalConfig } from "@src/utils";

const config = getLocalConfig();

type UseTOSApi = () => {
  readonly api: TOSApi;
  readonly isReady: true;
};

const useTOSApi: UseTOSApi = () => ({
  api: new TOSApi(
    new ConfigurationCls({
      ...config.api.personExperienceBackend,
    })
  ),
  isReady: true,
});

export { useTOSApi };
