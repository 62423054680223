/* eslint-disable functional/immutable-data */
/* eslint-disable no-param-reassign */

import { List, Icon as PEIcon } from "@src/components";
import { MAX_NOTIFICATIONS_BEFORE_HIDING } from "./constants";
import { NotificationEmpty } from "../NotificationEmpty";
import { NotificationMerit } from "../NotificationMerit";
import { NotificationPolicyRequest } from "../NotificationPolicyRequest";
import { NotificationSkeleton } from "../NotificationSkeleton";
import { Pressable, StyleSheet, View } from "react-native";
import { range } from "lodash";
import { useCallback, useRef, useState } from "react";
import { useNotificationsController } from "./useNotificationsController";
import { useTheme } from "@merit/frontend-components";
import type { ListElement, ListState } from "@src/components/List";
import type { Notification as NotificationType } from "./types";

export const Notifications = () => {
  const { theme } = useTheme();
  const listRef = useRef<ListElement>(null);
  const { dismissNotification, isFetching, notifications } = useNotificationsController();

  const [listState, setListState] = useState<ListState>({
    hasNext: false,
    hasPrevious: false,
    index: 0,
  });

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      backgroundColor: theme.colors.background.default,
      flexDirection: "row",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.xl,
    },
    itemContainer: {
      flex: 1,
      flexDirection: "row",
    },
    itemSeparator: {
      width: theme.spacing.m,
    },
    nextPressable: {
      opacity: listState.hasNext ? 1 : 0,
      padding: theme.spacing.m,
    },
    previousPressable: {
      opacity: listState.hasPrevious ? 1 : 0,
      padding: theme.spacing.m,
    },
  });

  const handleNext = () => {
    listRef.current?.next();
  };

  const handlePrevious = () => {
    listRef.current?.previous();
  };

  const handleClose = (notification: NotificationType) => {
    dismissNotification(notification.id);
  };

  const handleStateChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (state: ListState) => {
      setListState(state);
    },
    [setListState]
  );

  if (isFetching && notifications.length === 0) {
    return (
      <View style={styles.container}>
        <List
          data={range(2)}
          keyExtractor={item => String(item)}
          renderItem={() => (
            <View style={styles.itemContainer}>
              <NotificationSkeleton />
              <View style={styles.itemSeparator} />
            </View>
          )}
        />
      </View>
    );
  }

  if (notifications.length > MAX_NOTIFICATIONS_BEFORE_HIDING) {
    return null;
  }

  if (notifications.length === 0) {
    return (
      <View style={styles.container}>
        <NotificationEmpty />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Pressable
        disabled={!listState.hasPrevious}
        onPress={handlePrevious}
        style={styles.previousPressable}
      >
        <PEIcon name="chevron_left" size={20} />
      </Pressable>
      <List
        data={notifications}
        keyExtractor={item => item.id}
        onStateChange={handleStateChange}
        ref={listRef}
        renderItem={item => {
          if (item.kind === "MeritNotification") {
            return (
              <View style={styles.itemContainer}>
                <NotificationMerit
                  merit={item.data}
                  onClose={() => {
                    handleClose(item);
                  }}
                />
                <View style={styles.itemSeparator} />
              </View>
            );
          }

          return (
            <View style={styles.itemContainer}>
              <NotificationPolicyRequest
                onClose={() => {
                  handleClose(item);
                }}
                policyRequest={item.data}
              />
              <View style={styles.itemSeparator} />
            </View>
          );
        }}
      />
      <Pressable disabled={!listState.hasNext} onPress={handleNext} style={styles.nextPressable}>
        <PEIcon name="chevron_right" size={20} />
      </Pressable>
    </View>
  );
};
