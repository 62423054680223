import { MyMerits as DesktopMyMerits } from "./MyMerits.desktop";
import { MyMerits as MobileMyMerits } from "./MyMerits.mobile";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";

export const MyMerits: FC = () => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopMyMerits />;
  }

  return <MobileMyMerits />;
};
