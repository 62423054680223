import { NotificationLayout as DesktopNotificationLayout } from "./NotificationLayout.desktop";
import { useLayoutType } from "@src/hooks";
import type { FC } from "react";
import type { NotificationLayoutProps } from "./types";

export const NotificationLayout: FC<NotificationLayoutProps> = props => {
  const layoutType = useLayoutType();

  if (layoutType === "desktop") {
    return <DesktopNotificationLayout {...props} />;
  }

  return null;
};
